/* eslint-disable no-case-declarations */
import { CampaignReport } from "../../constants/action-constants/CampaignReportActionConstants";

const initialState = {
  //Campaign summary -------------------------------
  campaignSummary: {},
  campaignSummaryLoading: false,
  error: "",
  campaignSummaryReport: {},

  //GenerateReport ---------------------------------
  generateReportLoading: false,

  //Report downloader XLS ------------------------------
  downloadXlsReportLoader: false,
  xlsReportErr: "",

  //Report downloader PDF ------------------------------
  downloadCampaignPdfReportLoader: false,
  campaignPdfReportErr: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    //Campaign overview
    //-------------------------------------------------------------------------------------
    case CampaignReport.CAMPAIGN_OVERVIEW:
      return {
        ...state,
        campaignSummaryLoading: true,
      };
    case CampaignReport.CAMPAIGN_OVERVIEW_SUCCESS:
      const data = action.data;
      const cityKeys = Object.keys(data.summary.summaryByCity);
      cityKeys.forEach((name) => {
        data.campaign.infoByCity[name] = {
          ...data.campaign.infoByCity[name],
          ...data.summary.summaryByCity[name],
        };
      });
      return {
        ...state,
        campaignSummary: data,
        campaignSummaryLoading: false,
      };
    case CampaignReport.CAMPAIGN_OVERVIEW_FAILURE:
      return {
        ...state,
        campaignSummaryLoading: false,
        error: action.payload,
      };

    // Generate mavin report
    //-------------------------------------------------------------------------------------
    case CampaignReport.GENERATE_MAVIN_REPORT:
      return {
        ...state,
        generateReportLoading: true,
      };

    case CampaignReport.GENERATE_MAVIN_REPORT_SUCCESS:
      return {
        ...state,
        generateReportLoading: false,
      };

    case CampaignReport.GENERATE_MAVIN_REPORT_FAILURE:
      return {
        ...state,
        err: action.payload,
      };

    //Download Report XLS
    //-------------------------------------------------------------------------------------
    case CampaignReport.DOWNLOAD_XLS_REPORT:
      return {
        ...state,
        downloadXlsReportLoader: true,
      };

    case CampaignReport.DOWNLOAD_XLS_REPORT_SUCCESS:
      return {
        ...state,
        downloadXlsReportLoader: false,
      };

    case CampaignReport.DOWNLOAD_XLS_REPORT_FAILURE:
      return {
        ...state,
        downloadXlsReportLoader: false,
        xlsReportErr: action.payload,
      };

    //Download Report XLS
    //-------------------------------------------------------------------------------------
    case CampaignReport.DOWNLOAD_CAMPAIGN_PDF_REPORT:
      return {
        ...state,
        downloadCampaignPdfReportLoader: true,
      };

    case CampaignReport.DOWNLOAD_CAMPAIGN_PDF_REPORT_SUCCESS:
      return {
        ...state,
        downloadCampaignPdfReportLoader: false,
      };

    case CampaignReport.DOWNLOAD_CAMPAIGN_PDF_REPORT_FAILURE:
      return {
        ...state,
        downloadCampaignPdfReportLoader: false,
        campaignPdfReportErr: action.payload,
      };

    default:
      return state;
  }
};
