import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// Actions
import { getCampaignPlanOverview } from "../../../actions/campaign-plan/CampaignPlanActions";
import { getBasicCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";

// Utils
import { constructRedirectPath } from "../../../utils/redirect-utils/RediectUtil";
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";

// Components
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import Spinner from "../../../components/spinner/Spinner";
import { isAgencySpecific } from "../../../components/hoc/MediaAgencySpecific";
import {
  AddCityInCampaign,
  CampaignDetails,
  CancelCampaign,
} from "../components/HeaderNav";
import CityDetailsTable from "../components/CityDetailsTable";
import { addCityAction } from "../components/CampaignRedirect";
import CampaignViewCards from "../components/CampaignViewCards";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Page-Component
function HeaderSection({ campaign, campaignPlan }) {
  const history = useHistory();

  const updateStateLoading = useSelector(
    (state) => state.c_campaignBasicInfo.updateStateLoading
  );

  if (!campaign) {
    return null;
  }

  const { infoByCity = {}, id } = campaign;
  const { summary } = campaignPlan;

  const EnableCancelCampaign = isAgencySpecific(CancelCampaign);
  const EnableAddCityInCampaign = isAgencySpecific(AddCityInCampaign);

  const pageTitle = <CampaignDetails campaign={campaign} />;
  const pageActions = (
    <div className="d-flex mt-2">
      <div>
        {EnableCancelCampaign && (
          <EnableCancelCampaign campaignId={campaign.id} />
        )}
        {EnableAddCityInCampaign && (
          <EnableAddCityInCampaign
            onClickAction={() =>
              addCityAction(
                id,
                Object.keys(infoByCity),
                summary.targetGroupId,
                history
              )
            }
          />
        )}
      </div>
      {updateStateLoading && <Spinner className="mx-3" />}
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} shadow={true} />;
}

/**
 * Page
 */
function CampaignDraftOverviewPage() {
  // State
  const { campaignId } = useParams();

  // Get campaignPlan
  const campaignPlanOverview = useSelector(
    (state) => state.campaignPlan.campaignPlanOverview
  );

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  // Tg name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  const { summary = {}, planByCity = {} } = campaignPlanOverview;

  // TODO:: once the api is returning target group id ,
  // Get "campaign plan overview" api
  // target group id
  const targetGroupId = Object.values(planByCity)[0]?.targetGroupId;

  /// Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCampaignPlanOverview(campaignId));
    dispatch(getBasicCampaignDetails(campaignId));
  }, [dispatch, campaignId]);

  // dispatching target group action
  useGetTgInfo(targetGroupId);

  if (!campaignPlanOverview || !campaign) {
    return null;
  }

  const redirectLinkObj = {
    campaignOverviewLink: constructRedirectPath(
      `/campaign/${campaignId}/draft`
    ),
    citySummaryLink: constructRedirectPath(
      `/campaign/${campaignId}/city/:cityId/draft`
    ),
  };

  return (
    <>
      {/* SideBar */}
      <CampaignSidebar campaign={campaign} redirectLinkObj={redirectLinkObj} />

      {/* Content-Section */}
      <div className="content-wrapper">
        {/* Page Header Section */}
        <HeaderSection
          campaign={campaign}
          campaignPlan={campaignPlanOverview}
        />

        {/* Page-Content */}
        <div className="camp-confirm m-0 px-5">
          {/* campaign view Cards Section */}
          <div className="mt-5">
            <CampaignViewCards
              tgName={tgName}
              campaign={campaign}
              campaignPlanSummary={summary}
              mediaCount={campaign.mediaCount}
            />
          </div>

          {/* Draft-Campaign city Highlight Table */}
          <CityDetailsTable
            campaignPlanOverview={campaignPlanOverview.planByCity}
            campaign={campaign}
          />
        </div>
      </div>
    </>
  );
}

export default CampaignDraftOverviewPage;
