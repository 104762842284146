import { CampaignCityReport } from "../../constants/action-constants/CampaignCityReportActionConstants";

export const getCityOverview = (campaignId, cityId) => ({
  type: CampaignCityReport.GET_CITY_OVERVIEW,
  payload: { campaignId, cityId },
});

export const approxOts = (
  campaignId,
  cityId,
  previouscanBeApproximatedCount,
  previousCannotBeApproximatedCount
) => ({
  type: CampaignCityReport.APPROX_OTS_FOR_CITY,
  payload: {
    campaignId,
    cityId,
    previouscanBeApproximatedCount,
    previousCannotBeApproximatedCount,
  },
});

export const downloadCityPdfReport = (campaignId, cityId) => ({
  type: CampaignCityReport.DOWNLOAD_CITY_PDF_REPORT,
  payload: { campaignId, cityId },
});
