import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  getRegionDataByCity,
  getRegionNames,
} from "../../../actions/regions/RegionActions";

// Constants and Utils
import { India, MapZoom } from "../../../constants/GeneralConstants";
import { RedirectTo } from "../../../urls/PageLinksURL";
import { MapInfoBox } from "../../map-view/boundaries/BoundariesUtil";
import { useResetMapState } from "../../../mavin/utils/hooks/HooksUtil";
import { constructRedirectPath } from "../../../utils/redirect-utils/RediectUtil";

// Components
import LLMap from "../../../components/map/leaflet-map/LLMap";
import TargetGroupHeatmap from "../../../components/map/target-group-heatmap/TargetGroupHeatmap";
import SingleCitySelectionForm from "../../../mavin/components/single-city-selection-form/SingleCitySelectionForm";
import Spinner from "../../../components/spinner/Spinner";

// Pages and Sections
import SiteMarkers from "../../map-view/media-sites/SiteMarkers";
import DataLayerMarkers from "../../map-view/data-layers/DataLayerMarkers";
import BoundaryMarkers from "../../map-view/boundaries/BoundaryMarkers";
import SecLayer from "../../map-view/sec/SecLayer";
import PoiMarkers from "../../../components/map/markers/PoiMarkers";
import RoadStretchMarkers from "../../map-view/road-stretches/RoadStretchMarkers";
import MapControlsSection from "../../map-view/MapControlsSection";
import CityAnalyserMapTabsSection from "./CityAnalyserMapTabsSection";
import LocationOfIntrestForm from "../../../mantaray/components/map-view/location-of-intrest/LocationOfIntrestForm";
import LocationOfIntrestMarker from "../../../mantaray/components/map-view/location-of-intrest/LocationOfIntrestMarker";

// CSS
import "../../map-view/MapView.scss";

/**
 * Tool :: City-Analyser
 */
function CityAnalyserToolPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { regionId: cityId } = useParams();

  // State
  const [selectedTab, setSelectedTab] = useState(0);
  const [hoveredBoundaryInfo, setHoveredBoundaryInfo] = useState({});
  const [locationStr, setLocationStr] = useState("");
  const [coordinate, setCoordinate] = useState([]);

  // Selector State
  // for "SINGLE City-Selection-form"
  const openSingleCitySelectionForm = useSelector(
    (state) => state.singleCitySelectionModal.openModal
  );
  const openLocationOfIntrestForm = useSelector(
    (state) => state.locationOfIntrest.openModal
  );

  // "regions-name" list and "region-data"
  const regionsName = useSelector((state) => state.region.regionsName) || [];
  const namesLoading = useSelector((state) => state.region.namesLoading);
  const regionsData = useSelector((state) => state.region.regionData);
  const { center = {}, bbox = "" } = regionsData;
  const { latitude, longitude } = center;
  // Map Center and Zoom
  const mapCenter =
    Object.keys(center).length > 0 ? [latitude, longitude] : India.mapCenter;
  const mapZoom =
    Object.keys(center).length > 0 ? MapZoom.zoomLevel11 : India.mapZoomLevel5;

  // all "poiTypeLayer" points combined array to Show on Map after selecting any TG
  const allPoiPointsArr = useSelector((state) => state.geoData.allPoiPointsArr);
  // selected tgId (as key) to pass in "LLHeatLayer" component to re-render it
  const selectedTgId = useSelector(
    (state) => state.orgTargetGroup.selectedTgId
  );

  // Reset all the Map States..
  useResetMapState();

  useEffect(() => {
    dispatch(getRegionNames());
  }, [dispatch]);

  // once any City is selected, we will call "RegionAPI"
  useEffect(() => {
    if (cityId && cityId !== "IN") {
      dispatch(getRegionDataByCity(cityId));
    }
  }, [dispatch, cityId]);

  // Functions
  function tabOnClick(index) {
    if (selectedTab === index) {
      setSelectedTab(0);
      return;
    }
    setSelectedTab(index);
  }

  function onSubmitCitySelectionForm(selectedCityId) {
    // replacing the Url to new-city
    history.replace(
      constructRedirectPath(
        RedirectTo.toolsCityAnalyserPageUrl.replace(":regionId", selectedCityId)
      )
    );
    // reloading the page to clear all the "reducer-states"
    window.location.reload(true);
  }

  // Page Loading
  if (namesLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <div className="content-wrapper map-content city-analyser-tool">
      {/* Tabs Section */}
      <CityAnalyserMapTabsSection
        selectedTab={selectedTab}
        tabOnClick={tabOnClick}
        regionsData={regionsData}
      />

      {/* Map Container */}
      <div className="map-cont mt-0 h-100">
        <LLMap center={mapCenter} zoom={mapZoom}>
          {/* LocationOfIntrestMarker */}
          {coordinate.length > 0 && (
            <LocationOfIntrestMarker position={coordinate} />
          )}

          {/* CampaignMedia and InventoryMedia Markers */}
          <SiteMarkers />

          <PoiMarkers />
          <DataLayerMarkers cityId={cityId} />

          {/* This component covers pinCodes and Wards Layers */}
          <BoundaryMarkers setHoveredBoundaryInfo={setHoveredBoundaryInfo} />

          <SecLayer />

          {/* Target-Group-HeatMap */}
          <TargetGroupHeatmap
            hmkey={selectedTgId}
            allPoiPointsArr={allPoiPointsArr}
          />
          <RoadStretchMarkers />

          {/* Map Info Box */}
          <MapInfoBox hoveredBoundaryInfo={hoveredBoundaryInfo} />
        </LLMap>
      </div>

      {/* Legends for mapView */}
      <MapControlsSection />

      {/* MODALS */}
      {/* CitySelection form */}
      {openSingleCitySelectionForm && (
        <SingleCitySelectionForm
          regionsName={regionsName}
          onSubmit={onSubmitCitySelectionForm}
          selectedCityId={cityId}
        />
      )}

      {openLocationOfIntrestForm && (
        <LocationOfIntrestForm
          locationStr={locationStr}
          setLocationStr={setLocationStr}
          setCoordinate={setCoordinate}
          center={[latitude, longitude]}
        />
      )}
    </div>
  );
}

export default CityAnalyserToolPage;
