import { useHistory } from "react-router-dom";

// Components
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import { continueToPlanning } from "../components/CampaignRedirect";
import { ContinueEditing, Review, SaveAsDraft } from "../components/HeaderNav";

// Page Section
export function HeaderSection({ campaign = {}, cityId }) {
  const history = useHistory();
  const { id } = campaign;

  const pageActions = (
    <div className="d-flex ">
      <ContinueEditing
        onClickAction={() => continueToPlanning(id, cityId, history)}
      />
      <SaveAsDraft />
      <Review campaignId={id} cityId={cityId} />
    </div>
  );

  return (
    <PageHeader
      title={"Campaign Planning"}
      actions={pageActions}
      shadow={true}
    />
  );
}
