import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

//Api
import {
  approxMavinOts,
  downloadCityPdfReportFn,
  getCitySummaryData,
} from "../../apis/CampaignCityReportAPI";
import { saveFile } from "../../common-utils/file-utils/FileUtils";

//Constants
import { CampaignCityReport } from "../../constants/action-constants/CampaignCityReportActionConstants";
import { CampaignMedia } from "../../constants/action-constants/CampaignMediaActionConstants";
import { PageNumber, ReportConstants } from "../../constants/GeneralConstants";

//Utils
import { getErrorMessage } from "../../utils/util";

// success message for approximation
const getSuccessMessage = (approximatedCount, totalCount) => {
  return `${approximatedCount}/${totalCount} sites were successfully approximated.`;
};

// City- Overview
export function* getCityOverview(action) {
  try {
    const { campaignId, cityId } = action.payload;
    const data = yield getCitySummaryData(campaignId, cityId);

    //dispatching action
    yield put({
      type: CampaignCityReport.GET_CITY_OVERVIEW_SUCCESS,
      data: data,
    });
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put({
      type: CampaignCityReport.GET_CITY_OVERVIEW_FAILURE,
      payload: err,
    });
    toast.error(errMsg);
  }
}

// to generate OTS based on nearest location for a site
export function* generateApproxOTS(action) {
  try {
    const {
      campaignId,
      cityId,
      previouscanBeApproximatedCount,
      previousCannotBeApproximatedCount,
    } = action.payload;

    // Approximate mavin OTS values..
    yield approxMavinOts(campaignId, cityId);

    // Refreshing the citySummary as we need to update the media count info..
    const data = yield getCitySummaryData(campaignId, cityId);
    const newCannotBeApproximatedCount = data.summary.cannotBeApproximatedCount;
    yield put({
      type: CampaignCityReport.GET_CITY_OVERVIEW_SUCCESS,
      data: data,
    });

    // Refresh Campaign Media to reflect the Approximated site details..
    yield put({
      type: CampaignMedia.CAMPAIGN_CITYVIEW,
      payload: {
        id: campaignId,
        cityId,
        pn: PageNumber.Page1,
        ps: data.summary.mediaCount,
      },
    });

    /**
     * ADDING "previous can be approximated" + "previous can-not be approximated"
     * and SUBSTRACTING "new can-not be approximated"
     * to get Final Output
     */
    const successMessage = getSuccessMessage(
      previouscanBeApproximatedCount +
        previousCannotBeApproximatedCount -
        newCannotBeApproximatedCount,
      previouscanBeApproximatedCount
    );
    // dispatch action for approx OTS success..
    yield put({
      type: CampaignCityReport.APPROX_OTS_FOR_CITY_SUCCESS,
    });
    toast.success(successMessage);
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: CampaignCityReport.APPROX_OTS_FOR_CITY_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
  }
}

export function* downloadCityPdfReport(action) {
  try {
    const { campaignId, cityId } = action.payload;
    const data = yield downloadCityPdfReportFn(campaignId, cityId);
    saveFile(ReportConstants.CITY_PDF_REPORT, data);
    yield put({
      type: CampaignCityReport.DOWNLOAD_CITY_PDF_REPORT_SUCCESS,
    });
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put({
      type: CampaignCityReport.DOWNLOAD_CITY_PDF_REPORT_FAILURE,
      payload: err,
    });
    toast.error(errMsg);
  }
}

export default function* root() {
  yield all([
    takeLatest(CampaignCityReport.GET_CITY_OVERVIEW, getCityOverview),
    takeLatest(CampaignCityReport.APPROX_OTS_FOR_CITY, generateApproxOTS),
    takeLatest(
      CampaignCityReport.DOWNLOAD_CITY_PDF_REPORT,
      downloadCityPdfReport
    ),
  ]);
}
