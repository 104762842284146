//Components
import moment from "moment";
import { useEffect, useState } from "react";
import { Marker } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

//Actions
import { getSellerSummaryByMediaId } from "../../../actions/seller/SellerAnalyticsActions";

//Utils & constants
import { DATE_FORMATS, MapZoom } from "../../../constants/GeneralConstants";
import {
  constructDateString,
  getDifferenceInDaysOfDateObject,
} from "../../../common-utils/date-utils/DateUtils";
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";

//Components
import MapIcon from "../../../components/map/map-icon/MapIcon";
import PerformanceCards from "../../../components/performance-card/PerformanceCard";
import Spinner from "../../../components/spinner/Spinner";
import TableHeaders from "../../../components/table/TableHeaders";
import LLMap from "../../../components/map/leaflet-map/LLMap";
import BootstrapDateRangePicker from "../../../components/bootstrap-date-range-picker/BootstrapDateRangePicker";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Campaign Name",
      className: "text-left col-2",
    },
    subTitle: { displayName: "Status", className: "sub-text" },
  },

  {
    title: {
      displayName: "Dates",
      className: "text-right col-2 align-top",
    },
  },

  {
    title: {
      displayName: "Duration",
      className: "text-right col-2 align-top",
    },
  },

  {
    title: {
      displayName: "General CPM (Rs)",
      className: "text-right col-2",
    },
    subTitle: { displayName: "General Impressions", className: "sub-text" },
  },

  {
    title: {
      displayName: "Target CPM (RS)",
      className: "text-right col-2",
    },
    subTitle: { displayName: "Target Impressions", className: "sub-text" },
  },

  {
    title: {
      displayName: "Total Earnings",
      className: "col-2 align-top text-right",
    },
  },
];

function HeaderSection({ media }) {
  const { title, type, height, width, litStatus, region } = media;
  return (
    <>
      <h3>{title}</h3>
      <p>
        {type} | {height} ft x {width} ft | {litStatus} | {region}
      </p>
      <hr />
    </>
  );
}

function EarningSummaryCardsSection({ campaigns, earningsTotal }) {
  // Total Campaigns
  const campaignsCount = Object.keys(campaigns).length;

  const cardInfo = [
    {
      title: "Campaigns",
      value: campaignsCount,
    },
    {
      title: "Earnings",
      value: `Rs. ${earningsTotal ? toLocaleString(earningsTotal) : 0}`,
    },
  ];
  return (
    <div className="d-flex mt-4">
      {cardInfo.map((info, i) => (
        <PerformanceCards info={info} key={i} className={"col-2"} />
      ))}
    </div>
  );
}

function CampaignNameAndStatusCell({ eachCampaign }) {
  const { title, startTimestamp, endTimestamp } = eachCampaign;

  function getCampaignStatus() {
    const todayDate = new Date();

    // if campaign start date is after  todays date - " Upcoming "
    if (moment(startTimestamp).isAfter(todayDate))
      return { status: "Upcoming", textColor: "text-warning" };

    // if todays date  is inbetween the campaign start and end date - " Live "
    if (moment(todayDate).isBefore(endTimestamp))
      return { status: "Live", textColor: "text-success" };

    // if todays date is after the campaign start date - " Completed "
    return { status: "Completed", textColor: "text-dark" };
  }

  const { status, textColor } = getCampaignStatus();

  return (
    <td className="text-left">
      <p className="m-0">{title}</p>
      <span className={`${textColor} m-0 text-left pr-2`}>
        <b>{status}</b>
      </span>
    </td>
  );
}

function DateCell({ eachCampaign }) {
  const { startTimestamp, endTimestamp } = eachCampaign;

  const format = DATE_FORMATS.full_month_with_date_year;
  const dateString = constructDateString(startTimestamp, endTimestamp, format);

  return <td className="text-right">{dateString}</td>;
}

function DurationCell({ eachCampaign }) {
  const { startTimestamp, endTimestamp } = eachCampaign;

  const dateObj = {
    startDate: new Date(startTimestamp),
    endDate: new Date(endTimestamp),
  };

  const duration = getDifferenceInDaysOfDateObject(dateObj);

  return <td className="text-right">{duration} Days</td>;
}

function CpmCell({ ots, startTimestamp }) {
  // if campaign start date is after todays date value will be - "Not started"
  const todayDate = new Date();
  let otsValue = ots;
  if (moment(startTimestamp).isAfter(todayDate)) {
    otsValue = "Not started";
  }

  return (
    <td className="text-right">
      <p className="m-0">--</p>
      <span>{otsValue || "--"}</span>
    </td>
  );
}

function TotalEarningCell({ price }) {
  return <td className="text-right">Rs. {toLocaleString(price) || 0}</td>;
}

function CampaignsInfoRow({ eachCampaign, eachMediaLog }) {
  const { otsServed, targetOtsServed, sellerPrice, startTimestamp } =
    eachMediaLog;
  return (
    <tr>
      <CampaignNameAndStatusCell eachCampaign={eachCampaign} />
      <DateCell eachCampaign={eachCampaign} />
      <DurationCell eachCampaign={eachCampaign} />
      <CpmCell ots={otsServed} startTimestamp={startTimestamp} />
      <CpmCell ots={targetOtsServed} startTimestamp={startTimestamp} />
      <TotalEarningCell price={sellerPrice} />
    </tr>
  );
}

function SiteCampaignsTable({ campaigns, mediaLog }) {
  return (
    <>
      <h4 className="mt-4">{"Campaigns"}</h4>
      {/* Site Campaigns Table */}
      <div className="table-responsive">
        <table className="table table-media">
          <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />
          <tbody>
            {/* Table row */}
            {mediaLog.map((eachMediaLog) => (
              <CampaignsInfoRow
                key={eachMediaLog.campaignId}
                eachCampaign={campaigns[eachMediaLog.campaignId]}
                eachMediaLog={eachMediaLog}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

function SiteImageAndMapView({ media }) {
  const fileItems = media?.fileItems || [];

  // Media image
  const mediaImage = fileItems[0]?.originalUrl;

  // Media map view
  const { latitude, longitude } = media;
  const center = [latitude, longitude];
  const zoom = MapZoom.zoomLevel13;

  // map-point-marker
  const marker = { type: "selected-media" };
  const icon = new MapIcon({ type: marker.type });
  return (
    <div className="d-flex border mt-5">
      {/* Site Image */}
      <div className="col-6 px-0">
        <img className="site-image" src={mediaImage} alt="site-image" />
      </div>

      {/* Map View */}
      <div className="bg-alt col-6 px-0">
        {latitude && longitude && (
          <LLMap center={center} zoom={zoom}>
            <Marker position={center} icon={icon} />
          </LLMap>
        )}
      </div>
    </div>
  );
}

function DateSelectionSection({ dateRange, setDateRange }) {
  // Functions
  function onSelectDateRange(selectedDate) {
    const startDate = selectedDate.startDate.toDate();
    const endDate = selectedDate.endDate.toDate();
    const newDateObj = { startDate, endDate };
    setDateRange(newDateObj);
  }

  return (
    <div className="d-flex align-items-center">
      <h4 className="pb-0 m-0">{"Earnings Summary"}</h4>
      <BootstrapDateRangePicker
        initialStartDate={dateRange.startDate}
        initialEndDate={dateRange.endDate}
        onApplyDates={onSelectDateRange}
        styleObject={{
          buttonClassName: "ml-5",
          border: true,
        }}
      />
    </div>
  );
}

function SellerSiteEarningsPage() {
  // Location
  const location = useLocation();

  // State
  const [dateRange, setDateRange] = useState({
    ...location.state,
  });

  // Selector
  const sellerSummary = useSelector(
    (state) => state.sellerAnalytics.sellerMediaSummary
  );

  const sellerSummaryLoading = useSelector(
    (state) => state.sellerAnalytics.sellerMediaSummaryLoading
  );

  const {
    media = {},
    campaigns = {},
    earningsTotal,
    mediaLog = [],
  } = sellerSummary;

  // Dispatch
  const dispatch = useDispatch();

  const { mediaId } = useParams();

  const { startDate, endDate } = dateRange;

  useEffect(() => {
    dispatch(getSellerSummaryByMediaId(mediaId, dateRange));
  }, [dispatch, mediaId, startDate, endDate]);

  if (sellerSummaryLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <div className="content-wrapper">
      <div className="page-content">
        {/* Page header */}
        <HeaderSection media={media} />

        <DateSelectionSection
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
        <EarningSummaryCardsSection
          campaigns={campaigns}
          earningsTotal={earningsTotal}
        />

        {/* Site Campaigns Table */}
        <SiteCampaignsTable campaigns={campaigns} mediaLog={mediaLog} />

        {/* Site image and map view  */}
        <SiteImageAndMapView media={media} />
      </div>
    </div>
  );
}

export default SellerSiteEarningsPage;
