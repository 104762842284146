import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getCampaignMedia } from "../../actions/media-selection/MediaSelectionActions";
import { getRoadStretchesByIds } from "../../../actions/org/OrgRoadStretchActions";
import { getSellerMediaByStretchIds } from "../../../actions/seller/SellerMediaActions";
import { openCreateMediaFormModal } from "../../actions/media-selection/CreateMediaModalAction";

// Constant and Utils
import { defaultPagination } from "../../../constants/UrlConstants";
import {
  getOtsAndCostDetails,
  getTableHeaders,
} from "../../utils/campaignPlanningUtil";
import { getDifferenceInDays } from "../../../common-utils/date-utils/DateUtils";
import { ProohFormDataTargets } from "../../constants/GeneralConstants";
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";
import { calculateAdjustedMop } from "../../utils/PriceFactorUtil";
import { useGetMediaPrice } from "../../utils/HooksUtil";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import Spinner from "../../../components/spinner/Spinner";
import MediaContainer from "./MediaContainer";

function RoadName({ roadInfo, roadMedia, setOpenMedia, openMedia }) {
  const roadType = roadInfo.roadSegmentIds ? "Stretch" : "Segment";
  return (
    <td className="align-middle">
      {roadMedia && roadMedia.length > 0 ? (
        <button
          type="button"
          className="btn shadow-none p-1"
          onClick={() => setOpenMedia(!openMedia)}
        >
          <i className={` fa fa-angle-${openMedia ? "down" : "right"}`}></i>
        </button>
      ) : (
        <span>&nbsp;&nbsp; &nbsp;</span>
      )}
      {`${roadType}: ${roadInfo.name}`}
    </td>
  );
}
function SitesSelected({ roadMedia }) {
  // TODO: Same logic is used in the CampaignSummary, FooterSection Section please refator
  // and reuse it..
  const mediaIdMap = roadMedia.reduce((acc, eachMedia) => {
    // We are just storing some constant for the map
    acc[eachMedia.mediaId] = true;
    return acc;
  }, {});

  const selectedMediaCount = useSelector((state) =>
    Object.keys(state.mediaSelection.selectedMedia).reduce(
      (acc, eachMediaId) => {
        if (
          mediaIdMap[eachMediaId] &&
          state.mediaSelection.selectedMedia[eachMediaId]
        ) {
          acc = acc + 1;
        }
        return acc;
      },
      0
    )
  );

  const selectedToTotalSites = `${selectedMediaCount}/${roadMedia.length}`;
  return <td className="text-center text-primary">{selectedToTotalSites}</td>;
}

function TgAndGenImpressions({ estTgSpecific, estGeneric }) {
  return (
    <td className="align-top">
      <div className="text-left">
        {estTgSpecific && <p className="mb-1">{`Tg: ${toLocaleString(estTgSpecific)}`}</p>}
        {estGeneric && <span>{`Gen: ${toLocaleString(estGeneric)}`}</span>}
      </div>
    </td>
  );
}

function TgAndGenCost({ estTgSpecific, estGeneric }) {
  return (
    <td className="align-top">
      <div className="text-left">
        {estTgSpecific && <p className="mb-1">{`Tg: ${toLocaleString(estTgSpecific)}`}</p>}
        {estGeneric && <span>{`Gen: ${toLocaleString(estGeneric)}`}</span>}
      </div>
    </td>
  );
}

function SelectionStatus({ roadStretchId }) {
  const selectedMediaCount = useSelector(
    (state) => state.mediaSelection.mediaStretchSelectedMap[roadStretchId]
  );

  return (
    <td className="text-center align-middle">
      <button className="btn rounded-lg px-2 py-1 border shadow-none ml-2">
        <i
          className={`fa ${selectedMediaCount
            ? "fa-check text-success"
            : "fa-minus text-secondary"
            }`}
        ></i>
      </button>
    </td>
  );
}

// Road Details Row
function RoadDetailsRow({ campaignPlan, roadInfo, roadMedia, duration }) {
  //State
  const [openMedia, setOpenMedia] = useState(true);

  if (!roadInfo) {
    return null;
  }

  const { targetGroupCPM, genericCPM } = campaignPlan;

  // Ots calculation
  const { estTgSpecific, estGeneric, estTotal } = getOtsAndCostDetails(
    roadInfo,
    campaignPlan,
    duration
  );

  const mediaCount = roadMedia ? roadMedia.length : 1;

  // Est Price Calculation
  const { minOperatingPriceAvg: mop, occupancyAvg: occupancy } = roadInfo;
  const adjMop = calculateAdjustedMop(mop, occupancy, duration);
  const pricePerImp = adjMop / estTotal;
  const estTgCost = estTgSpecific
    ? pricePerImp * (targetGroupCPM / 100) * estTgSpecific * mediaCount
    : "";

  const estGenCost = estGeneric
    ? pricePerImp * (genericCPM / 100) * estGeneric * mediaCount
    : pricePerImp * (genericCPM / 100) * estTotal * mediaCount;

  return (
    <>
      <tr>
        <RoadName
          roadInfo={roadInfo}
          roadMedia={roadMedia}
          openMedia={openMedia}
          setOpenMedia={setOpenMedia}
        />
        <SitesSelected roadMedia={roadMedia ? roadMedia : []} />
        {/* Road Stretch Impressions*/}
        <TgAndGenImpressions
          estTgSpecific={estTgSpecific ? estTgSpecific : ""}
          estGeneric={estGeneric ? estGeneric : estTotal}
        />

        {/* (Road Stretch * No.of Media) Impressions */}
        <TgAndGenImpressions
          estTgSpecific={estTgSpecific ? estTgSpecific * mediaCount : ""}
          estGeneric={estGeneric ? estGeneric : estTotal}
        />

        <TgAndGenCost estTgSpecific={estTgCost} estGeneric={estGenCost} />

        <SelectionStatus roadStretchId={roadInfo.id} />
      </tr>

      {openMedia && (
        <tr>
          <td className="p-0" colSpan={6}>
            <MediaContainer
              roadInfo={roadInfo}
              campaignPlan={campaignPlan}
              roadMedia={roadMedia}
            />
          </td>
        </tr>
      )}
    </>
  );
}

function AddMediaSection() {
  //Selector
  //open media modal
  const createMediaLoader = useSelector(
    (state) => state.mediaSelection.createMediaLoading
  );

  //Dispatch
  const dispatch = useDispatch();
  return (
    <div className="d-flex justify-content-between">
      <h4>Site Details</h4>
      <div className="d-flex">
        {createMediaLoader && <Spinner className="spinner-border-sm mr-2" />}
        <span
          className="text-primary cursor-pointer"
          data-toggle="modal"
          data-target={`#${ProohFormDataTargets.sellerCreateMediaForm}`}
          onClick={() => dispatch(openCreateMediaFormModal())}
        >
          {"Add a site"}
        </span>
      </div>
    </div>
  );
}

/**
 * Page Section
 */
function RoadAndMediaSection({ campaignId, campaignPlan }) {
  const dispatch = useDispatch();

  const { roadStretchOtsMap = {}, startTimestamp, endTimestamp } = campaignPlan;

  const stretchIds = Object.keys(roadStretchOtsMap);
  const stretchIdsLength = stretchIds.length;

  // RoadStretches
  const roadStretchMap = useSelector(
    (state) => state.orgRoadStretch.roadStretchDetailsMap
  );

  // RoadStretch Media
  const roadStretchMediaMap = useSelector(
    (state) => state.sellerMedia.stretchToSellerMediaMap
  );

  // Campaign Media
  const campaignMedia = useSelector((state) =>
    Object.values(state.mediaSelection.mediaIdToCampaignMedia)
  );

  // SellerMedia Ids
  const sellerMediaIds = useSelector(
    (state) => state.sellerMedia.sellerMediaIds
  );

  // Selected media count
  const selectedMediaCount = useSelector((state) =>
    Object.keys(state.mediaSelection.selectedMedia).reduce(
      (acc, eachMediaId) => {
        if (!state.mediaSelection.selectedMedia[eachMediaId]) {
          return acc;
        }
        acc = acc + 1;
        return acc;
      },
      0
    )
  );

  const sellerMediaIdsLength = sellerMediaIds.length;

  useEffect(() => {
    if (!campaignMedia || campaignMedia.length < 1) {
      dispatch(
        getCampaignMedia(
          campaignId,
          defaultPagination.pageNo,
          defaultPagination.pageSize
        )
      );
    }

    // Dispatch to fetch RoadStretches and its media
    if (stretchIdsLength > 0 && sellerMediaIdsLength < 1) {
      dispatch(getRoadStretchesByIds(stretchIds));
      dispatch(getSellerMediaByStretchIds(stretchIds));
    }
  }, [campaignId, dispatch, stretchIdsLength, sellerMediaIdsLength]);

  useGetMediaPrice(sellerMediaIds);

  if (Object.keys(campaignPlan).length < 1) {
    return null;
  }

  // Total Media Count
  const totalMediaCount = Object.keys(roadStretchMediaMap)
    .map((eachRoadId) => roadStretchMediaMap[eachRoadId])
    .reduce((acc, eachMedia) => acc + eachMedia.length, 0);

  const totalDays = getDifferenceInDays(startTimestamp, endTimestamp);
  const tableHeaders = getTableHeaders(totalMediaCount, selectedMediaCount);

  return (
    <div className="table-responsive mt-5">
      <AddMediaSection />
      <table className="table table-media">
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />
        <tbody>
          {Object.keys(roadStretchMap).map((eachStretchId) => (
            <RoadDetailsRow
              key={eachStretchId}
              campaignPlan={campaignPlan}
              roadInfo={roadStretchMap[eachStretchId]}
              roadMedia={roadStretchMediaMap[eachStretchId]}
              duration={totalDays}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default RoadAndMediaSection;
