//Components
import PageHeader from "../../mavin/components/page-header/PageHeader";

//Page Component
import HelpSidebar from "./HelpSidebar";

const terminologyDefinitionConstant = [
  {
    terminology: "MAVIN",
    definition: {
      title: "",
      content: (
        <span>
          <b>M</b>edia and <b>A</b>dvertisement <b>V</b>isibility <b>In</b>dex
        </span>
      ),
    },
  },

  {
    terminology: "OTS",
    definition: {
      title: "Opportunity To See / Impressions",
      content: (
        <span>
          It is an estimate of the number of people (footfall), populating a
          given road segment averaged over a specified duration of time. These
          people thereby have an <b>O</b>pportunity <b>T</b>o <b>S</b>
          ee an advertisement from the road segment, within that duration.
        </span>
      ),
    },
  },

  {
    terminology: "Road Segment",
    definition: {
      title: "",
      content: (
        <span>
          A continuous stretch of road without any branching out. A road segment
          (AB) therefore, is understood to have the same number of people
          leaving through the End point (B) as had entered through the Start
          point (A).
        </span>
      ),
    },
  },

  {
    terminology: "Reach",
    definition: {
      title: "",
      content: (
        <span>
          Reach is an estimate of the unique number of people who have
          contributed to the OTS (Impressions) on a given Road segment for any
          Advertisement
        </span>
      ),
    },
  },

  {
    terminology: "Frequency",
    definition: {
      title: "",
      content: (
        <span>
          Is the Average no. of times the same audience member has the
          opportunity to see the Media within a specified duration.
        </span>
      ),
    },
  },

  {
    terminology: "LTS",
    definition: {
      title: "Likelihood to See",
      content: (
        <span>
          It’s the measure of probability / likelihood of an advertisement to be
          seen by a passer by based on its Physical attributes relative to other
          media surrounding it.
        </span>
      ),
    },
  },

  {
    terminology: "Aspect Ratio",
    definition: {
      title: "",
      content: (
        <span>
          Is the ratio of Width (W) to Height (H) of a Media Site. Therefore
          Aspect Ratio = (W/H)
        </span>
      ),
    },
  },

  {
    terminology: "Clutter",
    definition: {
      title: "",
      content: (
        <span>
          Refers to the obstructions in the view path of the Media site. A
          collection of closely placed media sites are considered cluttered as
          opposed to a standalone media site.
        </span>
      ),
    },
  },

  {
    terminology: "Dwell Time",
    definition: {
      title: "",
      content: (
        <span>
          Is the Average time (in seconds)a passerby spends in front of the
          media site, i.e. time taken to cover the distance(AB) from the point
          where the media site can be clearly seen(A) to crossing the location
          of the media site (B).
        </span>
      ),
    },
  },

  {
    terminology: "Mobility",
    definition: {
      title: "",
      content: (
        <span>
          Mobility in this context refers to the user mobile device tracking
          datasets.
        </span>
      ),
    },
  },

  {
    terminology: "POI",
    definition: {
      title: "Point of Interest",
      content: (
        <span>
          Typically refers to any establishment that is visited by a user.
        </span>
      ),
    },
  },

  {
    terminology: "SEC",
    definition: {
      title: "Socio-Economic-Classification",
      content: (
        <span>
          Is the affluence level of a population demographic. This is very
          specific to the Geography / Location being analyzed. Eg: The Threshold
          for SEC A might be very different in terms of Absolute value, for
          Patna as compared to The Threshold for SEC A for Mumbai
        </span>
      ),
    },
  },

  {
    terminology: "TG",
    definition: {
      title: "Target Group",
      content: (
        <span>
          Refers to a group of people with high probability to be found at
          common points of interest.
        </span>
      ),
    },
  },

  {
    terminology: "Heat Map",
    definition: {
      title: "",
      content: (
        <span>
          A dynamic representation on Map view, showcasing variance from High
          concentration to Lower concentration of a particular Target group.
        </span>
      ),
    },
  },

  {
    terminology: "Road Stretch",
    definition: {
      title: "",
      content: (
        <span>A group of continuous Road Segments attached in series.</span>
      ),
    },
  },

  {
    terminology: "MoRTH",
    definition: {
      title: "Ministry of Road Transport and Highways",
      content: (
        <span>
          Is the Govt. Authority responsible for conducting the yearly
          transportation surveys and releasing a consolidated report of the
          same.
        </span>
      ),
    },
  },

  {
    terminology: "DULT",
    definition: {
      title: "Directorate of Urban Land Transport",
      content: (
        <span>
          Is a Govt. Authority similar to the Ministry of Road Transport and
          Highways (MoRTH), which conducts transportation surveys, primarily in
          Urban geographies.
        </span>
      ),
    },
  },

  {
    terminology: "OSM",
    definition: {
      title: "Open Street Maps",
      content: (
        <span>
          Is an open-source data repository of crowdsourced geo-spatial data.
        </span>
      ),
    },
  },
];

function TerminologyDefinitionTable() {
  return (
    <table className="table table-bordered">
      <tbody>
        {terminologyDefinitionConstant.map((data, i) => (
          <tr key={i}>
            <td className="font-weight-bold col-2">{data.terminology}</td>
            <td className="col-10">
              <p className="mb-0">
                <ins>{data.definition.title || ""}</ins>
              </p>
              <span>{data.definition.content}</span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function DefinitionHelpPage() {
  const pageTitle = "Terminology and Definitions";

  return (
    <>
      <HelpSidebar activeIdx={5} />

      <div className="content-wrapper">
        <PageHeader title={pageTitle} shadow={true} />

        <div className="page-content">
          <TerminologyDefinitionTable />
        </div>
      </div>
    </>
  );
}

export default DefinitionHelpPage;
