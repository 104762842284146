//Constants
import { LITSTATUS } from "../../../constants/GeneralConstants";

// Components
export function Dimensions({ media }) {
  const { type, litStatus, height, width, otsSummary = {} } = media;
  const { targetGroupName = "" } = otsSummary;
  const status = LITSTATUS[litStatus];

  const typeStr = type ? `${type} | ` : "";
  const heightStr = height ? `${height}ft` : "";
  const widthStr = width ? ` x ${width}ft` : "";
  const litStatusStr = status ? ` | ${status}` : "";
  const targetGroupNameStr = targetGroupName
    ? ` | TargetGroup : ${targetGroupName}`
    : "";

  return `${typeStr}${heightStr}${widthStr}${litStatusStr}${targetGroupNameStr}`;
}

export function PriceInfo({ media }) {
  const priceObj = media.pricing || {};

  if (!priceObj.price) {
    return " | Price not available ";
  }

  // TODO: Logic needs ro be fixed..
  if (priceObj.priceUnit === "INR") {
    return "Rs";
  }

  const priceDisplay = `${priceObj.priceUnit}  ${priceObj.price}`;
  if (priceObj.pricingInterval === "PER_MONTH") {
    return `${priceDisplay} per month`;
  }

  return `${priceDisplay} ${priceObj.pricingInterval}`;
}

// Media Info Components
function MediaInfo({ media = {}, className = "", titleClassName = "" }) {
  if (Object.keys(media).length === 0) {
    return null;
  }
  const { title, pricing, region } = media;

  return (
    <div className={`${className}`}>
      {/* Media title */}
      <h3 className={`text-truncate ${titleClassName}`} title={title}>
        {title}
      </h3>
      <p className="h4">
        {/* Media dimensions */}
        <Dimensions media={media} />
        {/* Media price info */}
        {pricing && <PriceInfo media={media} />}
        {region ? ` | ${region}` : ""}
      </p>
    </div>
  );
}

export default MediaInfo;
