import { all, fork } from "redux-saga/effects";

import MediaSitesSaga from "./map-view/media-sites/MediaSitesSaga";
import ExploreSitesSaga from "./map-view/media-sites/ExploreSitesSaga";
import ExploreSitesSelectionSaga from "./map-view/media-sites/ExploreSitesSelectionSaga";
import SaveMapStateSaga from "./map-view/SaveMapStateSaga";
import mapViewImage from "./citySummary/mapViewImage";
import PoiSaga from "./map-view/poi/PoiSaga";
import PoiSelectionSaga from "./map-view/poi/PoiSelectionSaga";
import BoundariesSaga from "./map-view/BoundariesSaga";
import SecSaga from "./map-view/SecSaga";
import sagaUtils from "./sagaUtils";
import CampaignBasicInfoSaga from "./campaign/CampaignBasicInfoSaga";
import CampaignPerformanceSaga from "./campaign-planning/CampaignPerformanceSaga";
import sellerExcelUpload from "./sellerExcelUpload/sellerExcelUpload";
import RoadSegmentSaga from "./road-segments/RoadSegmentSaga";
import SellerExcelOverview from "./sellerExcelOverview/sellerExcelOverview";
import SiteUploadSaga from "./SiteUploadSaga";

// org Sagas
import OrgDataLayerSaga from "./org/OrgDataLayerSaga";
import OrgManageManageSaga from "./org/OrgUserManageSaga";
import OrgProfileSaga from "./org/OrgProfileSaga";
import OrgTargetGroupSaga from "./org/OrgTargetGroupSaga";
import OrgRoadStretchSaga from "./org/OrgRoadStretchSaga";
import OrgMediaPriceSaga from "./org/OrgMediaPriceSaga";
import OrgMediaSaga from "./org/OrgMediaSaga";

// Auth Saga
import AuthSaga from "./auth/AuthSaga";

// Region Saga
import RegionSaga from "./regions/RegionSaga";
// Kyp saga
import GeoDataSaga from "./geo-data/GeoDataSaga";

// Monitoring
import MonitoringSettingsSaga from "./campaign-monitoring/MonitoringSettingsSaga";
import MonitoringLogSaga from "./campaign-monitoring/MonitoringLogSaga";

//Campaign Report
import CampaignReportSaga from "./campaign-report/CampaignReportSaga";
import CampaignCityReportSaga from "./campaign-report/CampaignCityReportSaga";
import CampaignSaga from "./campaign/CampaignSaga";

// Seller
import SellerSaga from "./seller/SellerSaga";
import SellerMediaSaga from "./seller/SellerMediaSaga";
import SellerAnalyticsSaga from "./seller/SellerAnalyticsSaga";

// CampaignMedia
import CampaignMediaSaga from "./campaign-media/CampaignMediaSaga";

// Buyer
import BuyerSaga from "./buyer/BuyerSaga";

// CampaignPlan
import CampaignPlanSaga from "./campaign-plan/CampaignPlanSaga";

// Location Saga
import LocationSummarySaga from "./location-summary/LocationSummarySaga";

// Prooh Saga
import { proohSagas } from "../prooh/sagas/RootSaga";

// Mavin Tools Saga
import OtsToolSaga from "./mavin-tools/OtsToolSaga";
import LtsToolSaga from "./mavin-tools/LtsToolSaga";
import CompareLocationsToolSaga from "./mavin-tools/CompareLocationsToolSaga";
import ReachAndFrequencyToolSaga from "./mavin-tools/ReachAndFrequencyToolSaga";
import SingleSiteAnalyserToolSaga from "./mavin-tools/SingleSiteAnalyserToolSaga";

/**
 * rootSaga
 */
export default function* root() {
  yield all([
    fork(MediaSitesSaga),
    fork(ExploreSitesSaga),
    fork(ExploreSitesSelectionSaga),
    fork(SaveMapStateSaga),
    fork(mapViewImage),
    fork(PoiSaga),
    fork(PoiSelectionSaga),
    fork(BoundariesSaga),
    fork(SecSaga),
    fork(sagaUtils),
    fork(CampaignBasicInfoSaga),
    fork(CampaignPerformanceSaga),
    fork(sellerExcelUpload),
    fork(SellerExcelOverview),
    fork(SiteUploadSaga),
    fork(RoadSegmentSaga),
    // Org Sagas
    fork(OrgDataLayerSaga),
    fork(OrgTargetGroupSaga),
    fork(OrgRoadStretchSaga),
    fork(OrgManageManageSaga),
    fork(OrgProfileSaga),
    fork(OrgMediaPriceSaga),
    fork(OrgMediaSaga),

    // Auth Saga
    fork(AuthSaga),

    // Region Saga
    fork(RegionSaga),
    // Kyp Saga
    fork(GeoDataSaga),

    //Seller
    fork(SellerSaga),
    fork(SellerMediaSaga),
    fork(SellerAnalyticsSaga),

    // Monitoring
    fork(MonitoringSettingsSaga),
    fork(MonitoringLogSaga),

    //Campaign Report
    fork(CampaignReportSaga),
    fork(CampaignCityReportSaga),
    fork(CampaignSaga),

    // CampaignMedia
    fork(CampaignMediaSaga),

    // Buyer
    fork(BuyerSaga),

    // Campaign-Plan
    fork(CampaignPlanSaga),

    // LocationSaga
    fork(LocationSummarySaga),

    // Mavin Tools
    fork(OtsToolSaga),
    fork(LtsToolSaga),
    fork(CompareLocationsToolSaga),
    fork(ReachAndFrequencyToolSaga),
    fork(SingleSiteAnalyserToolSaga),

    // Prooh Saga
    ...proohSagas,
  ]);
}
