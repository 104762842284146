import { Status } from "../../constants/GeneralConstants";

function setChildrenStatus(dataList, status, label) {
  if (!dataList.length) {
    return [];
  }

  if (label) {
    return dataList.map((data) => {
      if (data.label === label) {
        return {
          ...data,
          status,
        };
      }
      return data;
    });
  }

  return dataList.map((data) => ({
    ...data,
    status,
    children: setChildrenStatus(data.children, status, label),
  }));
}

function getNewStatus(children, status) {
  if (children.length < 1) {
    return status;
  }

  if (children.every((child) => child.status === Status.CHECKED)) {
    return Status.CHECKED;
  }
  if (children.some((child) => child.status === Status.CHECKED)) {
    return Status.PARTIALLY_CHECKED;
  }
  return Status.UNCHECKED;
}

function setStatusToData(data, label, status) {
  if (data.label === label) {
    const children = setChildrenStatus(data.children, status);
    const newStatus = getNewStatus(children, status);
    return {
      ...data,
      status: newStatus,
      children,
    };
  } else {
    const children = setChildrenStatus(data.children, status, label);
    const newStatus =
      children.length < 1 ? data.status : getNewStatus(children, status);
    return {
      ...data,
      status: newStatus,
      children,
    };
  }
}

function setDatalistStatusWithType(dataList, label, status) {
  const children = dataList.children;
  const modifiedChild = children.map((data) =>
    setStatusToData(data, label, status)
  );
  dataList.children = modifiedChild;
  return dataList;
}

export function setDatalistStatus(dataList, path, label, status) {
  return dataList.map((data) => {
    if (data.type) {
      return setDatalistStatusWithType(data, label, status);
    }
    return setStatusToData(data, label, status);
  });
}

function setSelectedToData(data, label) {
  if (data.label === label) {
    return {
      ...data,
      isSelected: !data.isSelected,
    };
  }
  return {
    ...data,
    isSelected: false,
  };
}

const setDatalistSelectedWithType = (dataList, label) => {
  const children = dataList.children;
  const modifiedChild = children.map((data) => setSelectedToData(data, label));
  dataList.children = modifiedChild;
  return dataList;
};

export function setDatalistSelected(dataList, path, label) {
  // if (path.parentIndex === -1) {
  return dataList.map((data) => {
    if (data.type) {
      return setDatalistSelectedWithType(data, label);
    }
    return setSelectedToData(data, label);
  });
}

export function setDatalistAdded(dataList, matchingObject, status) {
  return dataList.map((data) => {
    const { labelId, label } = matchingObject;
    const matchingkey = labelId ? data.labelId : data.label;
    const matchingValue = labelId ? labelId : label;
    if (matchingkey === matchingValue) {
      return {
        ...data,
        status,
      };
    }
    const { children } = data;
    if (children.length === 0) {
      return data;
    }
    const newChildren = setDatalistAdded(children, matchingObject, status);
    return {
      ...data,
      children: newChildren,
    };
  });
}

export function formatRawData(rawData, level = 1, parentLabel = "") {
  if (!rawData) {
    return [];
  }
  return Object.keys(rawData).reduce((acc, curr) => {
    const type = rawData[curr].type;
    if (type) {
      delete rawData[curr].type;
    }
    const labelId = parentLabel ? parentLabel + "__" + curr : curr;
    const children = formatRawData(rawData[curr], level + 1, labelId);
    return [
      ...acc,
      {
        label: curr,
        labelId: labelId,
        level: level,
        isSelected: false,
        status: Status.UNCHECKED,
        children,
        type,
      },
    ];
  }, []);
}
