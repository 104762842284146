import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

// Actions
import {
  getCampaignMediaByCity,
  updateCampaignMediaDuration,
} from "../../../actions/campaign-media/CampaignMediaActions";
import { getCityOverview } from "../../../actions/campaign-report/CampaignCityReportActions";

// Constants and Utils
import { defaultPagination } from "../../../constants/UrlConstants";
import { getModifiedPopulation } from "../../../utils/ReachAndFrequencyUtils";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Component
import Spinner from "../../../components/spinner/Spinner";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import TableHeaders from "../../../components/table/TableHeaders";
import {
  FrequencyCell,
  ImpressionsCell,
  InfoCell,
  LtsCell,
  ReachCell,
} from "../../../components/campaign-media-table-row/MediaRow";
import { DurationCell } from "../../../components/campaign-table-row/CampaignRowCells";
import AlertMessage from "../../../mavin/components/alert-message/AlertMessage";
import { CampaignCityPerformance } from "../../../mavin/components/campaign-city-performance/CampaignCityPerformance";

// Page Constants
const otsHeaderInfo = [
  {
    displayText: "Tg Impressions",
  },
  {
    displayText: "Total Impressions",
  },
];

const reachHeaderInfo = [
  {
    displayText: "Tg Reach (%)",
  },
  {
    displayText: "Total Reach (%)",
  },
];

const tableHeaders = (cityPopulation) => [
  {
    title: {
      displayName: "Site Name",
      className: "col-4",
    },
    subTitle: {
      displayName: "Media type, Dimensions, Lighting, Region",
      className: "",
    },
  },
  {
    title: {
      displayName: "Start and end date",
      className: "col-2 text-right",
    },
    subTitle: {
      displayName: "Duration",
      className: "text-right",
    },
  },
  {
    element: <OtsAndReachHeader headerInfo={otsHeaderInfo} />,
  },
  {
    element: (
      <OtsAndReachHeader
        headerInfo={reachHeaderInfo}
        subText={`Population: ${getModifiedPopulation(cityPopulation)}`}
      />
    ),
  },
  {
    title: {
      displayName: "Frequency",
      className: "col-1 text-right",
    },
  },
  {
    title: {
      displayName: "LTS",
      className: "col-1 text-center",
    },
  },
];

function OtsAndReachHeader({ headerInfo, subText }) {
  return (
    <th className="text-right">
      {headerInfo.map((eachHeaderInfo, i) => (
        <div key={i}>
          <p className="mb-2 mt-0">{eachHeaderInfo.displayText}</p>
        </div>
      ))}

      <p className="sub-text mb-0">{subText}</p>
    </th>
  );
}

// Page Component
function PlanCityViewMediaRow({ media, cityPopulation }) {
  const dispatch = useDispatch();

  if (!media || Object.keys(media).length === 0) {
    return null;
  }

  const {
    startTimestamp,
    endTimestamp,
    duration,
    campaignId,
    id: mediaId,
    reachFrequency,
  } = media;

  // function to update date
  function onDateSelect(selectedDate) {
    dispatch(updateCampaignMediaDuration(selectedDate, media));
  }

  const dateObj = { startTimestamp, endTimestamp };

  // Redirect Url
  const campaignMediaViewPageUrl = RedirectTo.campaignMediaViewPageUrl
    .replace(":campaignId", campaignId)
    .replace(":mediaId", mediaId);

  return (
    <tr>
      <InfoCell
        mediaElementStyle={"p-2"}
        media={media}
        redirectUrl={campaignMediaViewPageUrl}
      />
      <DurationCell
        dateObj={dateObj}
        durationElementStyle={"p-2 text-right align-middle"}
        onDateSelect={onDateSelect}
        duration={duration}
      />
      <ImpressionsCell
        impressionsElementStyle={"p-2 text-right align-middle"}
        media={media}
      />
      <ReachCell
        reachElementStyle={"p-2 text-right align-middle"}
        reachFrequency={reachFrequency}
        cityPopulation={cityPopulation}
        showPopulation={false}
      />
      <FrequencyCell
        frequencyElementClass={"p-2 text-right align-middle"}
        reachFrequency={reachFrequency}
      />
      <LtsCell ltsElementClass={"p-2 text-center align-middle"} media={media} />
    </tr>
  );
}

function PlanCityViewMediaTable({ mediaList, cityPopulation }) {
  return (
    <div className="table-responsive mt-3">
      <table className="table table-media">
        {/* Table header */}
        <TableHeaders
          tableHeaders={tableHeaders(cityPopulation)}
          headerClass={"thead"}
        />

        <tbody>
          {/* Table rows */}
          {mediaList.map((media) => (
            <PlanCityViewMediaRow
              media={media}
              key={media.id}
              cityPopulation={cityPopulation}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function CityViewTableHeading() {
  // update campaignMediaDuration
  const campaignMediaDurationChanging = useSelector(
    (state) => state.campaignMedia.mediaDurationChanging
  );

  return (
    <div className="d-flex mt-4">
      <h4 className="mb-0 mr-2">{"Site Details"}</h4>
      {campaignMediaDurationChanging && (
        <AlertMessage
          className="mb-0 py-1"
          spinnerClassName="spinner-border-sm"
          textMessage="Updating Metrics"
        />
      )}
    </div>
  );
}

/**
 * Page
 */
function CampaignPlanCityviewPage() {
  const { campaignId: id, cityId } = useParams();

  // Selector State
  const citySummaryLoading = useSelector(
    (state) => state.campaignCityReport.citySummaryLoading
  );
  const { campaign = {}, summary: citySummary = {} } = useSelector(
    (state) => state.campaignCityReport.citySummary
  );

  const { targetGroupName = "" } = campaign;

  const { population: cityPopulation } = citySummary;

  // CityMedia and Data
  const cityMediaLoading = useSelector(
    (state) => state.campaignMedia.cityLoading
  );
  const cityMediaSummary = useSelector(
    (state) => state.campaignMedia.citySummary
  );
  const cityMediaSummaryResult = cityMediaSummary.result || {};
  const { items = [] } = cityMediaSummaryResult;

  // Link to pass in CampaignSidebar
  const redirectLinkObj = {
    campaignOverviewLink: RedirectTo.campaignPlanOverviewPageUrl.replace(
      ":campaignId",
      id
    ),
    citySummaryLink: RedirectTo.campaignPlanCityViewPageUrl.replace(
      ":campaignId",
      id
    ),
  };

  const cityInfo = {
    cityId: cityId,
    cityName: citySummary?.cityName || cityId,
  };

  const pageNumber = defaultPagination.pageNo;
  const pageSize = defaultPagination.pageSize;

  //Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCityOverview(id, cityId));
    dispatch(getCampaignMediaByCity(id, cityId, pageNumber, pageSize));
  }, [dispatch, id, cityId]);

  // link to city-specific-Map-view
  const campaignPlanMapViewPageUrl = RedirectTo.campaignPlanMapViewPageUrl
    .replace(":campaignId", id)
    .replace(":cityId", cityId);

  const pageTitle =
    campaign.title +
    " :: " +
    cityInfo.cityName +
    " | TargetGroup :: " +
    targetGroupName;

  const pageHeaderAction = (
    <Link
      className="btn btn-primary rounded-lg px-3"
      to={campaignPlanMapViewPageUrl}
    >
      Select Media on Map
    </Link>
  );

  //checks for page loading
  if (citySummaryLoading || cityMediaLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <>
      {/* Sidebar */}
      <CampaignSidebar
        campaign={campaign}
        citySelected={cityInfo}
        redirectLinkObj={redirectLinkObj}
      />

      {/* Main Content */}
      <div className="content-wrapper">
        {/* Page Header */}
        <PageHeader title={pageTitle} actions={pageHeaderAction} />

        {/* Page Content */}
        <div className="page-content">
          {/* Performance Card */}
          <CampaignCityPerformance citySummary={citySummary} />

          {/* CityView Table Header */}
          <CityViewTableHeading />

          {/* Media Table */}
          <PlanCityViewMediaTable
            mediaList={items}
            cityPopulation={cityPopulation}
          />
        </div>
      </div>
    </>
  );
}

export default CampaignPlanCityviewPage;
