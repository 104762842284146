import { all, put, select, takeLatest } from "redux-saga/effects";

// Api
import { getSellerMedia } from "../../../apis/SellerMediaAPI";
import { ExploreSites } from "../../../constants/action-constants/map-view/media-sites/ExploreSitesActionConstants";

function mediaFilter(applyFilters) {
  if (!applyFilters) {
    return null;
  }

  const filters = select((state) =>
    Object.keys(state.filter.appliedFilters).reduce((acc, eachKey) => {
      if (!state.filter.appliedFilters[eachKey]) {
        return acc;
      }
      acc.push(eachKey);
      return acc;
    }, [])
  );
  return filters;
}

export function* getMapViewExploreSites(action) {
  try {
    const { cityId, applyFilters = false, pn = 1, ps = 10 } = action.payload;

    //media filter
    const filters = yield mediaFilter(applyFilters);

    //making ajax call
    const data = yield getSellerMedia(cityId, filters, pn, ps);

    //dispatching action
    yield put({
      type: ExploreSites.GET_EXPLORE_SITES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: ExploreSites.GET_EXPLORE_SITES_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ExploreSites.GET_EXPLORE_SITES, getMapViewExploreSites),
  ]);
}
