import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Actions
import {
  getClosestRoadSegments,
  resetClosestRoadSegments,
} from "../../actions/org/OrgRoadStretchActions";
import {
  getReachAndFrequencySummary,
  resetReachAndFrequencySummary,
} from "../../actions/mavin-tools/ReachAndFrequencyActions";

// Constants and Utils
import {
  LocationSearchInput,
  MapViewWithPindropAndRoadSelect,
  RouteIdSection,
} from "./ToolsUtils";
import { constructSingleDateString } from "../../common-utils/date-utils/DateUtils";
import { DATE_FORMATS } from "../../constants/GeneralConstants";
import { NoSegmentsMessageSection } from "../org/OrgUtils";
import { toLocaleString } from "../../common-utils/string-utils/StringUtils";

// Components
import { LineChart } from "../../components/charts/Charts";
import TableHeaders from "../../components/table/TableHeaders";
import PageHeader from "../../mavin/components/page-header/PageHeader";
import DurationSelector from "../../mavin/components/duration-selector/DurationSelector";
import { ButtonWithLoader } from "../../mavin/components/button/Button";
import BootstrapDateRangePicker from "../../components/bootstrap-date-range-picker/BootstrapDateRangePicker";

//Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Day #",
      className: "font-weight-bold text-center col-3",
    },
  },

  {
    title: {
      displayName: "No. of Devices",
      className: "font-weight-bold text-center col-2",
    },
  },

  {
    title: {
      displayName: "New Devices",
      className: "font-weight-bold text-center col-2",
    },
  },

  {
    title: {
      displayName: "Frequency",
      className: "font-weight-bold text-center col-2",
    },
  },

  {
    title: {
      displayName: "Total Unique Devices",
      className: "font-weight-bold text-center col-3",
    },
  },
];

const reachAndFrequencyDropdownItems = [
  { id: 7, label: "7 Days" },
  { id: 14, label: "14 Days" },
  { id: 21, label: "21 Days" },
  { id: 30, label: "30 Days" },
];

// Constructing LineChart Data
function constructLineChartData(dailyMobilityData) {
  const lineChartData = dailyMobilityData.map((eachData, i) => ({
    name: i + 1,
    ["Total Unique Devices"]: eachData["totalUniqueDevices"],
  }));
  return lineChartData;
}

// Page Components
function MobilityInfoAndChartSection({
  formattedDate,
  duration,
  reachAndFrequencySummary,
}) {
  const { dailyMobilityData = [], otsLitAvg } = reachAndFrequencySummary;

  // If No-Data
  if (dailyMobilityData.length < 1) {
    return null;
  }

  // to get last value of an array
  const cumulativeValue = dailyMobilityData.at(-1);
  const { cumulativeReach, cumulativeFrequency } = cumulativeValue || {};

  const mobilityInfo = [
    {
      label: "Start Date:",
      value: formattedDate,
    },
    {
      label: "Duration:",
      value: `${duration} Days`,
    },
    {
      // .toFixed returns string value and toLocaleString will accept the number format
      // so again converting toFixed value as a number by add plus sign
      label: "Estimated Reach:",
      value: toLocaleString(+cumulativeReach?.toFixed()),
    },
    {
      label: "Cumulative Frequency:",
      value: toLocaleString(+cumulativeFrequency?.toFixed(4)),
    },
    {
      label: "Impressions:",
      value: toLocaleString(Math.ceil(cumulativeReach * cumulativeFrequency)),
    },
  ];

  // Chart Data
  const lineChartData = constructLineChartData(dailyMobilityData);
  const linesData = [
    {
      type: "linear",
      dataKey: "Total Unique Devices",
    },
  ];

  // if No data
  if (reachAndFrequencySummary.length < 1) {
    return null;
  }

  return (
    <>
      <div className="d-flex pt-4">
        <div className="col-4 p-3">
          <p className="mb-0">
            Monthly Impressions:{" "}
            <span className="font-weight-bold">
              {toLocaleString(otsLitAvg)}
            </span>
          </p>
          <hr className="my-2"></hr>
          {/* Heading */}
          <p>
            <u>{"Mobility"}</u>
          </p>

          {/* Mobility Info */}
          {mobilityInfo.map((info, i) => (
            <p key={i}>
              {info.label}{" "}
              <span className="font-weight-bold">{info.value}</span>
            </p>
          ))}
        </div>

        {/* Chart */}
        <div className="col-8">
          <LineChart
            data={lineChartData}
            linesData={linesData}
            XAxisLabel="Days No."
            YAxisLabel="Total Unique Devices"
          />
        </div>
      </div>
      <hr className="divider row"></hr>
    </>
  );
}

function MobilityProgressTableRow({ eachDayInfo, dayIndex }) {
  const { deviceCount, uniqueDeviceCount, avgFrequency, totalUniqueDevices } =
    eachDayInfo;

  return (
    <tr>
      <td className="text-center">{dayIndex}</td>
      <td className="text-center">{deviceCount}</td>
      <td className="text-center">{uniqueDeviceCount}</td>
      <td className="text-center">
        {avgFrequency ? avgFrequency.toFixed(1) : "--"}
      </td>
      <td className="text-center">{totalUniqueDevices}</td>
    </tr>
  );
}

function MobilityProgressTable({ reachAndFrequencySummary }) {
  const { dailyMobilityData = [] } = reachAndFrequencySummary;

  // If No-Data
  if (dailyMobilityData.length < 1) {
    return null;
  }

  return (
    <>
      <h5 className="font-weight-bold">{"Mobility Progress (Day wise)"}</h5>
      <div className="table-responsive">
        <table className="table table-sm table-media">
          <TableHeaders
            tableHeaders={tableHeaders}
            headerClass={"thead bg-alt"}
          />
          <tbody>
            {dailyMobilityData.map((eachDayInfo, i) => (
              <MobilityProgressTableRow
                key={i}
                eachDayInfo={eachDayInfo}
                dayIndex={i + 1}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

/**
 * Main Page
 */
function ReachAndFrequencyToolPage() {
  const dispatch = useDispatch();
  // State
  const [locationStr, setLocationStr] = useState("");
  const [coordinate, setCoordinate] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState({});
  const [startDate, setStartDate] = useState("");
  const [duration, setDuration] = useState(null);

  // Selector State
  // all closest road-segments
  const roadSegments =
    useSelector((state) =>
      Object.values(state.orgRoadStretch.roadSegmentsMap)
    ) || [];

  // Reach and Frequency Info
  const reachAndFrequencySummary = useSelector(
    (state) => state.reachAndFrequencyTool.reachAndFrequencySummary
  );
  const reachAndFrequencySummaryLoading = useSelector(
    (state) => state.reachAndFrequencyTool.reachAndFrequencySummaryLoading
  );

  const pageTitle = "Impressions :: Reach and Frequency";

  // Converting "startDate" into Required format : DD-MM-YYYY
  const startDateTimestamp = Date.parse(startDate);
  const formattedDate = constructSingleDateString(
    startDateTimestamp,
    DATE_FORMATS.date_month_year
  );

  // DatePicker style
  const styleObject = {
    autoApply: true,
    border: true,
    buttonClassName: "col-2 px-0",
  };

  // Reset Reach and Frequency info
  useEffect(() => {
    dispatch(resetReachAndFrequencySummary());
  }, [dispatch, locationStr, duration, startDate]);

  useEffect(() => {
    setDuration(null);
    setStartDate("");
    dispatch(resetClosestRoadSegments());
  }, [dispatch, locationStr]);

  useEffect(() => {
    // showing toaster to select road segment
    if (roadSegments.length > 0) {
      toast.info("Please select the Road to get Reach and Frequency.");
    }
    // when ever location change we are clearing previous closest road segment
    // and fetch new closest road segment. so length is changing
    // if the roadSegments length change useEffect will get trigger and shows toaster message
  }, [roadSegments.length]);

  // Functions
  // This call-back function is used to pick the lat-lng and
  // fetch all closest road-segments
  function getCoordinatesFromPinDrop(locationData) {
    const coordinateString = `${locationData.lat},${locationData.lng}`;
    // removing selected segment (everytime user select new location)
    setSelectedSegment({});

    setLocationStr(coordinateString);
    setCoordinate([locationData.lat, locationData.lng]);
    dispatch(getClosestRoadSegments(coordinateString));
  }

  function onPolylineClick(e, eachSegment) {
    setSelectedSegment(eachSegment);
    dispatch(resetReachAndFrequencySummary());
    e.originalEvent.view.L.DomEvent.stopPropagation(e);
  }

  // getOts function for Reach&Frequency
  function getOtsForReachAndFrequency() {
    dispatch(
      getReachAndFrequencySummary(selectedSegment.id, formattedDate, duration)
    );
  }

  return (
    <div className="content-wrapper map-content-wrapper">
      {/* Map Left Section */}
      <div className="map-layout-left">
        {/* Page Header */}
        <PageHeader title={pageTitle} shadow={false} />
        <hr className="divider my-3 row"></hr>

        {/* Location search */}
        <LocationSearchInput
          locationStr={locationStr}
          setLocationStr={setLocationStr}
          setCoordinate={setCoordinate}
        />

        {/* No RoadSegments Message */}
        <NoSegmentsMessageSection
          roadSegments={roadSegments}
          locationStr={locationStr}
        />

        {/* RoadId, Date, getOts and Duration Section */}
        {Object.keys(selectedSegment).length > 0 && locationStr && (
          <div className="d-flex justify-content-between align-items-center py-3">
            {/* Route ID */}
            <RouteIdSection id={selectedSegment.id} />

            {/* Single-Date-Selector ==> isSingleDatePicker={true} */}
            <BootstrapDateRangePicker
              isSingleDatePicker={true}
              initialStartDate={startDate}
              onApplyDates={setStartDate}
              placeHolder={"Select Date"}
              styleObject={styleObject}
            />

            {/* Duration Selector */}
            <DurationSelector
              items={reachAndFrequencyDropdownItems}
              onItemSelect={setDuration}
              startDate={startDate}
              buttonClassName="shadow-none col px-2"
            />

            {/* Submit Request Button */}
            <ButtonWithLoader
              displayContent={"Get R&F"}
              className="col-2"
              onClickFunction={getOtsForReachAndFrequency}
              loader={reachAndFrequencySummaryLoading}
              isDisabled={
                !startDate || !duration || reachAndFrequencySummaryLoading
              }
            />
          </div>
        )}
        {Object.keys(selectedSegment).length > 0 && (
          <hr className="divider my-0 row"></hr>
        )}

        {/* Mobility Info and Chart */}
        <MobilityInfoAndChartSection
          formattedDate={formattedDate}
          duration={duration}
          reachAndFrequencySummary={reachAndFrequencySummary}
        />

        {/* Mobility Progress Table */}
        <MobilityProgressTable
          reachAndFrequencySummary={reachAndFrequencySummary}
        />
      </div>

      {/* Map Right Section */}
      <MapViewWithPindropAndRoadSelect
        coordinate={coordinate}
        selectedSegment={selectedSegment}
        getCoordinatesFromPinDrop={getCoordinatesFromPinDrop}
        onPolylineClick={onPolylineClick}
      />
    </div>
  );
}

export default ReachAndFrequencyToolPage;
