import React from "react";

// Other Page Sections
import ProtectedRoute from "../ProtectedRoute";
import { constructRouterPath } from "../App";

// Pages
import HomePage from "./pages/home/HomePage";

// Campaign Planning
import CampaignPlanSetupPage from "./pages/campaign-planning/campaign-plan-setup/CampaignPlanSetupPage";
import CampaignPlanOverviewPage from "./pages/campaign-planning/campaign-plan-overview/CampaignPlanOverviewPage";
import CampaignPlanCityviewPage from "./pages/campaign-planning/campaign-plan-cityview/CampaignPlanCityviewPage";

// CampaignMapView
import CampaignMapView from "./pages/campaign/campaign-map-view/CampaignMapView";

export default function MantarayApp() {
  return (
    <>
      {/* Dashboard Page */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/home", true, true)}
        component={HomePage}
      />

      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:id/:cityId/map", true, true)}
        component={CampaignMapView}
        sidebar={false}
        topNav={false}
      />

      {/* Campaign-Planning pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/plan/setup", true, true)}
        component={CampaignPlanSetupPage}
      />
      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/campaign/:campaignId/plan/overview",
          true,
          true
        )}
        component={CampaignPlanOverviewPage}
        sidebar={true}
      />

      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:campaignId/plan/city/:cityId")}
        component={CampaignPlanCityviewPage}
        sidebar={true}
      />
      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:id/plan/:cityId/map", true, true)}
        component={CampaignMapView}
        topNav={false}
      />
    </>
  );
}
