import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Action
import { updateCampaignDates } from "../../actions/campaign-planning/CampaignPlanningActions";
import { openSingleCitySelectionForm } from "../../../actions/SingleCitySelectionFormActions";

// Constants and Utils
import { getDifferenceInDays } from "../../../common-utils/date-utils/DateUtils";
import { FormDataTargets } from "../../../constants/GeneralConstants";

// Components
import BootstrapDateRangePicker from "../../../components/bootstrap-date-range-picker/BootstrapDateRangePicker";
import DurationSelector from "../../../mavin/components/duration-selector/DurationSelector";

// CSS
import "./CampaignPlanningPage.scss";

function getInitialDateAndDuration(startTimestamp, endTimestamp) {
  if (!startTimestamp && !endTimestamp) {
    return { startDateObj: "", duration: "" };
  }

  const duration = getDifferenceInDays(startTimestamp, endTimestamp);
  return { startDateObj: new Date(startTimestamp), duration };
}

function StartDateAndDurationSection() {
  const dispatch = useDispatch();
  const campaignPlan = useSelector((state) => state.campaignPlan.campaignPlan);

  const { startTimestamp, endTimestamp } = campaignPlan;
  const { startDateObj, duration } = getInitialDateAndDuration(
    startTimestamp,
    endTimestamp
  );

  const [startDate, setStartDate] = useState(startDateObj);

  // DatePicker Style
  const styleObject = {
    autoApply: true,
    border: true,
    buttonClassName: "start-date-selector px-0",
  };

  // Functions
  function selectDuration(duration) {
    const date = new Date(startDate);
    const endDate = new Date(date.setDate(date.getDate() + duration - 1));
    dispatch(updateCampaignDates({ startDate, endDate }));
  }

  return (
    <div className="d-flex ">
      <div className="mr-2">
        <p className="mb-0">{"Est. Start Date"}</p>
        {/* Single-Date-Selector ==> isSingleDatePicker={true} */}
        <BootstrapDateRangePicker
          isSingleDatePicker={true}
          initialStartDate={startDate}
          onApplyDates={setStartDate}
          minDate={new Date()}
          placeHolder={"Select Date"}
          styleObject={styleObject}
        />
      </div>
      <div className="">
        <p className="mb-0">{"Duration"}</p>
        <DurationSelector
          onItemSelect={selectDuration}
          startDate={startDate}
          initialDays={duration}
        />
      </div>
    </div>
  );
}
function SingleCitySelectionSelector() {
  const selectedCityName =
    useSelector((state) => state.campaignPlanning.regionsData.name) || "";

  const dispatch = useDispatch();
  function openCitySelectionFormFn() {
    dispatch(openSingleCitySelectionForm());
  }

  return (
    <div className="mr-5">
      <button
        type="button"
        className="btn border rounded-lg shadow-none px-2 dropdown-toggle"
        data-toggle="modal"
        data-target={`#${FormDataTargets.singleCitySelectionForm}`}
        onClick={openCitySelectionFormFn}
      >
        {selectedCityName ? selectedCityName : "Select City"}
      </button>
    </div>
  );
}

/**
 * Section
 */
function PlanningHeaderSection() {
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);
  const campaignTitle = campaign ? campaign.title : "New Campaign";

  return (
    <>
      <div className="navbar my-2">
        <h3 className="mb-0">{campaignTitle}</h3>
        <div className="row mx-0 align-items-end">
          <SingleCitySelectionSelector />
          <StartDateAndDurationSection />
        </div>
      </div>
      <hr className="divider my-0"></hr>
    </>
  );
}

export default PlanningHeaderSection;
