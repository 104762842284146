import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  selectMultipleMediaSites,
  unselectMultipleMediaSites,
} from "../../../actions/campaign-monitoring/MonitoringSettingsActions";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import Pagination from "../../../components/pagination/Pagination";

// Pages and Sections
import MonitoringCampaignCityViewTableRow from "./MonitoringCampaignCityViewTableRow";

// Page Constants
const tableHeaders = [
  { element: <MonitorSelectAllMediaSites /> },
  {
    title: {
      displayName: "Site Name",
    },
    subTitle: {
      displayName: "Media type, Dimensions, Lighting, Region",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "Contact",
    },
    subTitle: {
      displayName: "Email and Phone no.",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "Monitoring Settings",
    },
    subTitle: {
      displayName: "Start and End date",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "Latest update",
    },
    subTitle: {
      displayName: "Status, update count, last updated date",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "",
    },
  },
];

// Page Functions
function SelectAll({ unSelect, select, mediaIds }) {
  const dispatch = useDispatch();
  const selectedSitesCount = useSelector((state) =>
    Object.values(state.monitoringSettings.selectedSites).reduce(
      (count, eachValue) => {
        return eachValue ? count + 1 : count;
      },
      0
    )
  );

  const checked = mediaIds.length === selectedSitesCount;

  function toggleMediaSites() {
    return checked ? dispatch(unSelect(mediaIds)) : dispatch(select(mediaIds));
  }

  return (
    <th>
      <input type="checkbox" checked={checked} onChange={toggleMediaSites} />
    </th>
  );
}

// function to select all media-sites
function MonitorSelectAllMediaSites() {
  const monitoringCitySummary =
    useSelector((state) => state.monitoringSettings.monitoringCitySummary) ||
    {};
  const citySummaryItems = monitoringCitySummary.items || [];
  const mediaList = citySummaryItems.map((items) => items.media);
  const mediaIds = mediaList.map((media) => media.id);

  return (
    <SelectAll
      unSelect={unselectMultipleMediaSites}
      select={selectMultipleMediaSites}
      mediaIds={mediaIds}
    />
  );
}

/**
 * Campaign Campaign-cityview table
 */
function MonitoringCampaignCityViewTable({
  citySummaryItems,
  pagination,
  loadCityMedia,
}) {
  const { totalCount, pageNo, pageSize } = pagination;

  return (
    <div className="table-responsive mt-4">
      <table className="table monitoring-table-media">
        {/* Table Header */}
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />

        {/* Table Row */}
        <tbody>
          {citySummaryItems.map((item, i) => (
            <MonitoringCampaignCityViewTableRow item={item} key={i} />
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <Pagination
        totalCount={totalCount}
        pageNo={pageNo}
        pageSize={pageSize}
        onClick={loadCityMedia}
      />
    </div>
  );
}

export default MonitoringCampaignCityViewTable;
