import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useCallback } from "react";

//Actions
import { getMapViewExploreSites } from "../../../actions/map-view/media-sites/ExploreSitesActions";

//Constants & Utils
import {
  LocalStorageKeys,
  OrganizationIdsMapping,
  PageSize,
  PatrikaSellerId,
} from "../../../constants/GeneralConstants";
import { SearchFilterAndSortMediaSection } from "./MediaSitesUtils";
import { checkIsOrgSpecific } from "../../../utils/OrgUtils";
import { getItem } from "../../../utils/localstorage";

//Components
import Spinner from "../../../components/spinner/Spinner";

// Page sections
import ExploreSitesTable from "./ExploreSitesTable";

/**
 * Explore SItes Section
 */
function ExploreSitesSection({ listingViewLabels, cityId }) {
  const dispatch = useDispatch();
  //State
  const [exploreSitesList, setExploreSitesList] = useState([]);

  //Selector
  const mediaList = useSelector(
    (state) => state.mapViewExploreSites.exploreSites
  );
  const loading = useSelector((state) => state.mapViewExploreSites.loading);

  const pagination = useSelector(
    (state) => state.mapViewExploreSites.pagination
  );

  const pageNumber = 1,
    pageSize = PageSize.MapView;

  //Dispatch
  useEffect(() => {
    if (mediaList.length === 0 || cityIdFromMedia !== cityId) {
      dispatch(getMapViewExploreSites(cityId, pageNumber, pageSize));
    }
  }, [dispatch, cityId]);

  const oneMedia = mediaList[0] || {};
  const cityIdFromMedia = oneMedia.cityId || "";

  const user = JSON.parse(getItem(LocalStorageKeys.USER)) || {};
  const { orgId } = user;
  const { patrika } = OrganizationIdsMapping;
  const isPatrikaSpecific = checkIsOrgSpecific(orgId, patrika);

  const getPatrikaInventory = useCallback(
    (mediaList) => {
      return mediaList.filter((media) => media.sellerId === PatrikaSellerId);
    },
    [isPatrikaSpecific]
  );

  const updatedMediaList = isPatrikaSpecific
    ? getPatrikaInventory(mediaList)
    : mediaList;

  function loadCityMedia(pageNumber, pageSize) {
    dispatch(getMapViewExploreSites(cityId, pageNumber, pageSize));
  }

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }

  const isMediaSitesAvailable =
    updatedMediaList.length < 1 || exploreSitesList.length < 1;

  return (
    <>
      {/*Search , Sort and filter */}
      <SearchFilterAndSortMediaSection
        setExploreSitesList={setExploreSitesList}
        mediaList={updatedMediaList}
        listingViewLabels={listingViewLabels}
      />
      <hr className="divider my-2" />

      <ExploreSitesTable
        mediaList={exploreSitesList}
        pagination={pagination}
        loadCityMedia={loadCityMedia}
      />

      {/* If there is no sites found */}
      {isMediaSitesAvailable && (
        <p className="text-center mt-3 font-italic">No Sites are available</p>
      )}
    </>
  );
}

export default ExploreSitesSection;
