import { useState } from "react";
import { toast } from "react-toastify";

// Components
import {
  MediaDimension,
  MediaTypeAndLitStatus,
} from "../../../components/campaign-media-form/CampaignMediaFormUtils";
import { ButtonWithLoader } from "../button/Button";

// CSS
import "./MediaInfo.scss";

// Component Sections
export function MediaSiteTitle({ title, setTitle }) {
  return (
    <div className="form-group row">
      <label className="col-3 col-form-label">{"Media Site Title"}</label>
      <div className="col-9">
        <input
          type="search"
          className={"form-control shadow-none rounded-lg"}
          placeholder={"Enter site name. eg Indira Nager Junction facing Metro"}
          value={title}
          onChange={({ target }) => setTitle(target.value)}
        />
      </div>
    </div>
  );
}

export function MediaTiltAngle({ tiltAngle, setTiltAngle }) {
  return (
    <div className="form-group row">
      <label className="col-3 col-form-label">{"Tilt angle to Road"}</label>
      <div className="col-3">
        <input
          className={`form-control shadow-none rounded-lg`}
          placeholder={"Degrees to North (0)"}
          value={tiltAngle}
          onChange={({ target }) => setTiltAngle(target.value)}
        />
      </div>
    </div>
  );
}

// Functions
/**
 * this function returns "True/False" to call an api
 */
export function validateForm(mediaInfoBean) {
  // skipped tilt field and taking other fields
  const { tilt, ...otherMediaInfo } = mediaInfoBean;

  // since we have to ignore "tiltAngle" input for showing toaster,
  const isAllInputsFilled = Object.keys(otherMediaInfo).every((key) => {
    // If user doesn't fill all the inputs toaster will shown
    if (!mediaInfoBean[key]) {
      toast.error("Please fill all the fields");
      return false;
    }
    return mediaInfoBean[key];
  });

  return isAllInputsFilled;
}

/**
 * Media Site Details Form Component
 */
function SiteDetailsFormSection({
  className = "col-12",
  buttonLabel = "Sumbit",
  coordinate,
  dispatchFunction,
  isLoading,
  media = {},
}) {
  // If we pass Default Media then we show Default values from MEDIA.
  // States
  const [title, setTitle] = useState(media.title || "");
  const [type, setType] = useState(media.type || "");
  const [litStatusStr, setLitStatusStr] = useState(media.litStatus || "");
  const [width, setWidth] = useState(media.width || "");
  const [height, setHeight] = useState(media.height || "");
  const [elevation, setElevation] = useState(media.elevation || "");
  const [tiltAngle, setTiltAngle] = useState(media.tilt || "");

  // getting lat-lng
  const [latitude, longitude] = coordinate;
  // to pass in Media-Dimension Section
  const mediaDimensionValuesAndFunction = {
    width,
    height,
    elevation,
    setWidth,
    setHeight,
    setElevation,
  };
  const mediaInfoBean = {
    title,
    type,
    lit: litStatusStr,
    width,
    height,
    elevation,
    latitude,
    longitude,
    tilt: tiltAngle,
  };

  // Tool Action Button Function
  function onClick() {
    // Checking whether all the fields are filled
    const isAllInputsFilled = validateForm(mediaInfoBean);
    if (isAllInputsFilled) {
      dispatchFunction(mediaInfoBean, latitude, longitude);
    }
  }

  return (
    <div className={className}>
      <MediaSiteTitle title={title} setTitle={setTitle} />
      <MediaTypeAndLitStatus
        type={type}
        setType={setType}
        litStatusStr={litStatusStr}
        setLitStatusStr={setLitStatusStr}
        className="rounded-lg"
      />
      <MediaDimension
        mediaDimensionValuesAndFunction={mediaDimensionValuesAndFunction}
        className="rounded-lg"
      />
      {/* temporarily hiding tilt angle field*/}
      <div>
        <MediaTiltAngle tiltAngle={tiltAngle} setTiltAngle={setTiltAngle} />
      </div>

      <div className="report-generate-btn">
        <ButtonWithLoader
          displayContent={buttonLabel}
          onClickFunction={onClick}
          loader={isLoading}
          isDisabled={isLoading}
        />
      </div>
    </div>
  );
}

export default SiteDetailsFormSection;
