/* eslint-disable require-yield */
import { toast } from "react-toastify";
import { all, put, select, takeLatest, delay } from "redux-saga/effects";
import moment from "moment";

// Apis
import { createNewCampaign } from "../../../apis/CampaignAPI";
import {
  createCampaignPlan,
  updateCampaignPlan,
} from "../../../apis/CampaignPlanAPI";
import { getRegionDataByCityId } from "../../../apis/RegionAPI";
import {
  getSavedMapState,
  saveMapState,
} from "../../../apis/map-view/SaveMapStateAPI";

// Constants and utils
import { ActionTypes } from "../../../constants/ActionConstants";
import { GeoData } from "../../../constants/action-constants/GeoDataActionConstants";
import { RoadSegment } from "../../../constants/action-constants/RoadSegmentActionConstants";
import { Region } from "../../../constants/action-constants/RegionActionConstants";
import { RoadStretch } from "../../../constants/action-constants/RoadStretchActionConstants";
import { getErrorMessage } from "../../../utils/util";
import { constructRedirectPath } from "../../../utils/redirect-utils/RediectUtil";
import { DATE_FORMATS } from "../../../constants/GeneralConstants";
import { TargetGroup } from "../../../constants/action-constants/TargetGroupActionConstants";
import { getMergedCityId } from "../../../pages/map-view/CityInfoTempFixUtil";

function* getIdsArray(selectedIdsObject) {
  // "selectedIdsObject" is object of "ids: true/false"
  const ids = Object.keys(selectedIdsObject).reduce((acc, eachId) => {
    if (selectedIdsObject[eachId]) {
      acc.push(eachId);
    }
    return acc;
  }, []);
  return ids;
}

function constructOtsBean(otsDetails) {
  return {
    ots: otsDetails.ots,
    otsLit: otsDetails.otsLit,
    genericOts: otsDetails.genericOts,
    genericOtsLit: otsDetails.genericOtsLit,
    targetOts: otsDetails.targetOts,
    targetOtsLit: otsDetails.targetOtsLit,
  };
}

// function to get "id: {ots}"
function* getSplitOtsMap(ids) {
  const estOtsMap = {};
  for (const id of ids) {
    const eachElementOts = yield select(
      (state) => state.tgSpecificOts.tgSpecificOts[id]
    );
    estOtsMap[id] = constructOtsBean(eachElementOts);
  }
  return estOtsMap;
}

function* constructSplitOtsMap(ids, reducerKey, selectorKey) {
  const estOtsSplitMap = {};
  for (const id of ids) {
    const objInfo = yield select((state) => state[reducerKey][selectorKey][id]);
    const estOtsSplit = {};

    const { otsAvg, otsLitAvg } = objInfo;
    estOtsSplit.ots = otsAvg;
    estOtsSplit.otsLit = otsLitAvg;

    estOtsSplitMap[id] = constructOtsBean(estOtsSplit);
  }
  return estOtsSplitMap;
}

function* getSegmentEstOtsMap(tgId) {
  const selectedIdsObject = yield select(
    (state) => state.planningRoadSegments.selectedSegments
  );
  const segmentIds = yield getIdsArray(selectedIdsObject);

  // segment-ots-with-ids
  const segmentEstOtsMap = tgId
    ? yield getSplitOtsMap(segmentIds)
    : yield constructSplitOtsMap(
        segmentIds,
        "planningRoadSegments",
        "roadSegmentDetailsMap"
      );
  return segmentEstOtsMap;
}

function* getStretchEstOtsMap(tgId) {
  const selectedIdsObject = yield select(
    (state) => state.planningRoadStretches.selectedStretches
  );
  const stretchIds = yield getIdsArray(selectedIdsObject);

  // stretch-ots-with-ids
  const stretchEstOtsMap = tgId
    ? yield getSplitOtsMap(stretchIds)
    : yield constructSplitOtsMap(
        stretchIds,
        "planningRoadStretches",
        "roadStretchDetailsMap"
      );
  return stretchEstOtsMap;
}

function* getPoi() {
  const selectedIdsObject = yield select(
    (state) => state.planningPoi.selectedPoi
  );
  const poiLatLongArr = yield getIdsArray(selectedIdsObject);

  // selected poi ids arr
  const pois = [];
  for (const i in poiLatLongArr) {
    const latLongObj = {
      latitude: Number(poiLatLongArr[i].split(",")[0]),
      longitude: Number(poiLatLongArr[i].split(",")[0]),
    };
    pois.push(latLongObj);
  }
  return pois;
}

function* getSelectedPoi() {
  const brandIds = yield select((state) => state.poiSelection.brandIds);
  const brandToSectorMap = yield select((state) => state.poi.brandToSectorMap);
  const poiState = {};
  Object.keys(brandIds).forEach((brandId) => {
    const sectorId = brandToSectorMap[brandId];
    if (!poiState[sectorId]) {
      poiState[sectorId] = [brandId];
      return;
    }
    poiState[sectorId].push(brandId);
  });
  return poiState;
}

export function* planCampaign(action) {
  try {
    const { campaignId, selectedCitiesMap, history } = action.payload;
    // DD-MM-YYYY
    const dateFormat = DATE_FORMATS.date_month_year;

    const title = yield select((state) => state.campaignPlanning.campaignTitle);
    const tgId = yield select((state) => state.tgSpecificOts.tgId);
    const cityId = yield select((state) => state.campaignPlanning.cityId);
    const segmentEstOtsMap = yield getSegmentEstOtsMap(tgId);
    const stretchEstOtsMap = yield getStretchEstOtsMap(tgId);
    const pois = yield getPoi();
    const tgCPM = yield select((state) => state.campaignPlanning.tgCpm);
    const genericCPM = yield select((state) => state.campaignPlanning.genCpm);
    const dateObj = yield select((state) => state.campaignPlanning.duration);
    const dateString = {
      startDate: moment(dateObj.startDate).format(dateFormat),
      endDate: moment(dateObj.endDate).format(dateFormat),
    };

    const campaignBean = {
      title: title,
      startDate: dateString.startDate,
      endDate: dateString.endDate,
      infoByCity: [
        {
          cityId: cityId,
          startDate: dateString.startDate,
          endDate: dateString.endDate,
        },
      ],
    };
    const campaignPlanBean = {
      targetGroupId: tgId,
      cityId: cityId,
      startDate: dateString.startDate,
      endDate: dateString.endDate,
      roadSegmentOtsMap: segmentEstOtsMap,
      roadStretchOtsMap: stretchEstOtsMap,
      pois: pois,
      targetGroupCPM: tgCPM,
      genericCPM: genericCPM,
    };

    let finalCampaignId = campaignId;
    // If the campaign already exists update the plan
    if (campaignId && !selectedCitiesMap) {
      yield updateCampaignPlan(campaignId, campaignPlanBean);
    } else {
      // First create a new campaign
      let newCampaign = "";
      if (!selectedCitiesMap) {
        newCampaign = yield createNewCampaign(campaignBean);
      }
      finalCampaignId = newCampaign ? newCampaign.id : campaignId;

      // If the campaign does not exists crate new plan
      campaignPlanBean.campaignId = finalCampaignId;
      yield createCampaignPlan(campaignPlanBean);
    }

    //Save Map-State
    const mapState = { cityId: cityId, campaignId: campaignId };

    const poiState = yield getSelectedPoi();
    mapState.poiBrandIds = poiState;

    yield saveMapState(finalCampaignId, cityId, mapState);

    history.push(
      constructRedirectPath(
        `/campaign/${finalCampaignId}/city/${cityId}/media-selection`
      )
    );

    yield put({
      type: ActionTypes.CampaignPlanner.PLAN_CAMPAIGN_SUCCESS,
      campaignId: finalCampaignId,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: ActionTypes.CampaignPlanner.PLAN_CAMPAIGN_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
  }
}

function campaignPlanAction(campaignId, cityId) {
  return put({
    type: ActionTypes.CampaignPlanDetails.GET_CAMPAIGN_PLAN,
    payload: { campaignId, cityId },
  });
}

function campaignAction(campaignId) {
  return put({
    type: ActionTypes.CampaignDetails.GET_CAMPAIGN_BASIC_INFO,
    payload: { campaignId },
  });
}

function restoreCampaignPlanDates(campaignPlan) {
  const dateObj = {
    startDate: new Date(campaignPlan.startTimestamp),
    endDate: new Date(campaignPlan.endTimestamp),
  };
  return put({
    type: ActionTypes.CampaignPlanner.CHANGE_DURATION,
    payload: { dateObj },
  });
}

function restoreRegionsAction() {
  return put({
    type: Region.GET_REGION_NAMES,
  });
}

function restoreTgGroups() {
  return put({
    type: TargetGroup.GET_TARGET_GROUPS,
    payload: { excludeArchive: true },
  });
}

function restoreTgInfo(tgId) {
  if (!tgId) {
    return;
  }

  return put({
    type: TargetGroup.GET_TG_BASIC_INFO,
    payload: { tgId },
  });
}

function restoreCityDetails(cityId) {
  return put({
    type: ActionTypes.CampaignPlanner.GET_REGIONS,
    payload: { cityId, restore: true },
  });
}

function* restoreRoadSegments(campaignPlan) {
  if (Object.keys(campaignPlan.roadSegmentOtsMap).length < 1) {
    return;
  }

  // Fetch the roadSegments
  yield put({
    type: RoadSegment.GET_PLANNING_ROAD_SEGMENTS,
    payload: { segmentIds: Object.keys(campaignPlan.roadSegmentOtsMap) },
  });

  let segmentsSuccess = yield select(
    (state) => state.planningRoadSegments.segmentsSuccess
  );

  while (!segmentsSuccess) {
    segmentsSuccess = yield select(
      (state) => state.planningRoadSegments.segmentsSuccess
    );
    yield delay(1000);
  }

  // Fetch the media of road Segments
  const segmentIds = yield select((state) =>
    Object.keys(state.planningRoadSegments.roadSegmentDetailsMap)
  );
  yield put({
    type: RoadSegment.GET_ROAD_SEGMENT_MEDIA,
    payload: { segmentIds },
  });
}

function* restoreTgHeatMap() {
  let regionsData = yield select((state) => state.campaignPlanning.regionsData);
  let tgInfo = yield select((state) => state.orgTargetGroup.tgInfo);

  while (
    !regionsData ||
    Object.keys(regionsData).length < 1 ||
    !tgInfo ||
    Object.keys(tgInfo).length < 1
  ) {
    regionsData = yield select((state) => state.campaignPlanning.regionsData);
    tgInfo = yield select((state) => state.orgTargetGroup.tgInfo);
    yield delay(1000);
  }

  const { bbox } = regionsData;
  const resultLayers = tgInfo?.targetGroup?.resultLayers;
  const resPoiLayers = resultLayers.map((eachLayer) => eachLayer.poiTypeId);

  yield put({
    type: GeoData.GET_POI_TYPE_LAYER_DATA,
    payload: {
      resPoiLayers,
      bbox,
    },
  });
}

function* restorePois(campaignPlan) {
  if (!campaignPlan.pois) {
    return;
  }
  const poiActions = campaignPlan.pois.map((eachPoi) => {
    const exactLatLong = [eachPoi.latitude, eachPoi.longitude];
    return put({
      type: ActionTypes.ExactLatLong.ADD_EXACT_LAT_LONG,
      payload: { exactLatLong },
    });
  });
  yield all(poiActions);
}

function* restoreTgSpecificOts(campaignPlan) {
  if (!campaignPlan.targetGroupId) {
    return;
  }

  yield put({
    type: ActionTypes.CampaignPlanner.GET_TG_SPECIFIC_OTS_FOR_CITY,
    payload: {
      tgId: campaignPlan.targetGroupId,
      cityId: campaignPlan.cityId,
    },
  });
}

function* selectionActions(campaignPlan, campaign) {
  const actions = [];

  // roadStretches of the city
  const roadStretches = yield select(
    (state) => state.planningRoadStretches.roadStretchDetailsMap
  );

  // Add stretch actions
  if (Object.keys(campaignPlan.roadStretchOtsMap).length > 0) {
    Object.keys(campaignPlan.roadStretchOtsMap).reduce((acc, eachStretchId) => {
      acc.push(
        put({
          type: RoadStretch.SELECT_ROAD_STRETCH,
          payload: {
            roadStretch: roadStretches[eachStretchId],
          },
        })
      );
      return acc;
    }, actions);
  }

  // Add segment Actions
  if (Object.keys(campaignPlan.roadSegmentOtsMap).length > 0) {
    Object.keys(campaignPlan.roadSegmentOtsMap).reduce((acc, eachSegmentId) => {
      acc.push(
        put({
          type: RoadSegment.SELECT_ROAD_SEGMENT,
          payload: { roadSegmentId: eachSegmentId },
        })
      );

      return acc;
    }, actions);
  }

  // select campaignName
  actions.push(
    put({
      type: ActionTypes.CampaignPlanner.SET_CAMPAIGN_TITLE,
      payload: { titleStr: campaign.title },
    })
  );

  // select tg costs
  if (campaignPlan.genericCPM) {
    actions.push(
      put({
        type: ActionTypes.CampaignPlanner.SET_GEN_CPM,
        payload: { genCpmStr: campaignPlan.genericCPM },
      })
    );
  }

  if (campaignPlan.targetGroupCPM) {
    actions.push(
      put({
        type: ActionTypes.CampaignPlanner.SET_TG_CPM,
        payload: { tgCpmStr: campaignPlan.targetGroupCPM },
      })
    );
  }

  yield all(actions);
}

function* resetActions() {
  const actions = [];

  // Reset CampaignPlan
  actions.push(
    put({
      type: ActionTypes.CampaignPlanner.RESET_PLAN_CAMPAIGN,
    })
  );

  // Reset TargetGroups
  actions.push(
    put({
      type: TargetGroup.RESET_TARGET_GROUPS,
    })
  );

  // Reset TargetGroup Specific Details
  actions.push(
    put({
      type: ActionTypes.CampaignPlanner.RESET_TG_SPECIFIC_OTS,
    })
  );

  // Reset Road Segments
  actions.push(
    put({
      type: RoadSegment.RESET_ROAD_SEGMENTS,
    })
  );

  // Reset Road Stretches
  actions.push(
    put({
      type: RoadStretch.RESET_ROAD_STRETCHES,
    })
  );

  // Reset POI's
  actions.push(
    put({
      type: ActionTypes.ExactLatLong.RESET_POI_SELECTION,
    })
  );

  // Reset Media
  actions.push(
    put({
      type: ActionTypes.Media.RESET_SELECTED_MEDIA,
    })
  );

  // Reset HeatMap
  actions.push(
    put({
      type: GeoData.REMOVE_POI_TYPE_LAYER_DATA,
    })
  );

  yield all(actions);
}

function* waitTillStretchSegmentAreLoaded(campaignPlan) {
  let stretchSuccess = yield select(
    (state) => state.planningRoadStretches.requestSuccess
  );

  while (!stretchSuccess) {
    stretchSuccess = yield select(
      (state) => state.planningRoadStretches.requestSuccess
    );
    yield delay(1000);
  }

  if (Object.keys(campaignPlan.roadSegmentOtsMap).length > 0) {
    let segmentSuccess = yield select(
      (state) => state.planningRoadSegments.roadSegmentMediaInfoSuccess
    );

    while (!segmentSuccess) {
      segmentSuccess = yield select(
        (state) => state.planningRoadSegments.roadSegmentMediaInfoSuccess
      );
      yield delay(1000);
    }
  }
}

function* restorePoiBrandInfo(savedMapState, cityId) {
  const { poiBrandIds } = savedMapState;
  if (!poiBrandIds || Object.keys(poiBrandIds).length < 1) {
    return null;
  }

  const brandIds = Object.values(poiBrandIds).reduce((acc, brandIdArray) => {
    acc = acc.concat(brandIdArray);
    return acc;
  }, []);

  yield put({
    type: ActionTypes.PoiSelectionForm.GET_SELECTED_POI_INFO,
    payload: { cityId, brandIds },
  });
}

export function* restoreCampaignPlan(action) {
  try {
    const { campaignId, cityId } = action.payload;

    // Reset all the actions before restoring..
    yield resetActions();
    const getCampaignAndItsPlan = [];

    // We are fetching savedMapState to restore the Poi-Brand Data.
    const savedMapState = yield getSavedMapState(campaignId, cityId);
    getCampaignAndItsPlan.push(campaignPlanAction(campaignId, cityId));
    getCampaignAndItsPlan.push(campaignAction(campaignId));
    yield all(getCampaignAndItsPlan);

    let campaign = yield select((state) => state.c_campaignBasicInfo.campaign);
    let campaignPlan = yield select((state) => state.campaignPlan.campaignPlan);

    while (!campaign || !campaignPlan) {
      campaign = yield select((state) => state.c_campaignBasicInfo.campaign);
      campaignPlan = yield select((state) => state.campaignPlan.campaignPlan);
      yield delay(1000);
    }

    // Store all the actions to be executed in the array.
    const getDetailsActions = [];
    getDetailsActions.push(restoreCampaignPlanDates(campaignPlan));
    getDetailsActions.push(restoreRegionsAction());
    getDetailsActions.push(restoreTgGroups());
    getDetailsActions.push(restoreCityDetails(campaignPlan.cityId));
    getDetailsActions.push(restoreTgInfo(campaignPlan.targetGroupId));

    // Dispatch all the actions asynchronously
    yield all(getDetailsActions);

    // Restore Tg HeatMap
    yield restoreTgHeatMap();

    // Restore the selected roadSegments if exists in the campaignPlan
    yield restoreRoadSegments(campaignPlan);

    // Restore Poi's
    yield restorePois(campaignPlan);

    // Fetch the TG specific OTS for all the stretches, segments, media
    // Wait till all the stretches, segments details are loaded
    // TODO: We need to wait for poi details also ...
    yield waitTillStretchSegmentAreLoaded(campaignPlan);
    yield restoreTgSpecificOts(campaignPlan);

    // Select POI, Stretches, Segments and campaignName
    yield selectionActions(campaignPlan, campaign);

    // Restore Poi-Brand Info
    yield restorePoiBrandInfo(savedMapState, getMergedCityId(cityId));

    yield put({
      type: ActionTypes.CampaignPlanner.RESTORE_CAMPAIGN_PLAN_SUCCESS,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: ActionTypes.CampaignPlanner.RESTORE_CAMPAIGN_PLAN_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
  }
}

export function* getRegionsData(action) {
  const { cityId, restore } = action.payload;
  try {
    // api response for region data of any city
    const regionsData = yield getRegionDataByCityId(cityId);
    const bbox = regionsData.bbox;
    // required data
    const regionDataBean = {
      name: regionsData.name,
      id: regionsData.id,
      bbox: regionsData.bbox,
      center: regionsData.center,
    };

    yield put({
      type: ActionTypes.CampaignPlanner.GET_REGIONS_SUCCESS,
      regionsData: regionDataBean,
      cityId: cityId,
    });

    // after getting "regionData" we calling the API for getting "roadStretches"
    yield put({
      type: RoadStretch.GET_CITY_ROAD_STRETCHES,
      payload: { bbox },
    });

    // Wait till all the road Stretches are fetched..
    let roadStretchesDataMap = yield select(
      (state) => state.planningRoadStretches.roadStretchDetailsMap
    );
    while (Object.keys(roadStretchesDataMap).length < 1) {
      yield delay(1000);
      roadStretchesDataMap = yield select(
        (state) => state.planningRoadStretches.roadStretchDetailsMap
      );
    }

    // Select all the road Stretches by default
    // NOTE : If the request is from restoring (conitnue editing) then skit it
    if (!restore) {
      yield put({
        type: RoadStretch.SELECT_ROAD_STRETCHS,
        payload: { roadStretchsMap: roadStretchesDataMap },
      });
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignPlanner.GET_REGIONS_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.CampaignPlanner.PLAN_CAMPAIGN, planCampaign),
    takeLatest(
      ActionTypes.CampaignPlanner.RESTORE_CAMPAIGN_PLAN,
      restoreCampaignPlan
    ),
    takeLatest(ActionTypes.CampaignPlanner.GET_REGIONS, getRegionsData),
  ]);
}
