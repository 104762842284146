//Component
import OrgSettingsSidebar from "../OrgSettingsSidebar";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

//Css
import "../OrgSettings.scss";

//Page Components
function UserAccountsCard() {
  return (
    <div className="card-body">
      <h4 className="mb-3">User Accounts</h4>
      <p>Total Users: 10</p>
      <p>Active Users: 3</p>
      <p>Available Users: 7</p>
    </div>
  );
}

function SubscriptionDetailsCard() {
  return (
    <div className="card-body">
      <h4 className="mb-3">Subscription Details</h4>
      <p>Current Subscription type: Annual</p>
      <p>Last Renewal Date: XX/XX//XXXX</p>
      <p>Next Renewal Date: XX/XX/XXXX</p>
    </div>
  );
}

function SubscriptionCardSection() {
  return (
    <div className="d-flex col-8">
      <div className="card col-5 mx-1">
        <SubscriptionDetailsCard />
      </div>
      <div className="card col-5 mx-1">
        <UserAccountsCard />
      </div>
    </div>
  );
}

/**
 * Page
 */
export default function OrgSubscriptionPage() {
  const pageTitle = "Org :: Subscription";
  // Render Page
  return (
    <>
      <OrgSettingsSidebar activeIdx={3} />

      <div className="content-wrapper">
        {/** Page Header */}
        <PageHeader title={pageTitle} shadow={true} />

        {/** Page Content */}
        <div className="page-content">
          <SubscriptionCardSection />
        </div>
      </div>
    </>
  );
}
