import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Actions
import { openCampaignConfirmFormModal } from "../../actions/campaign-review/CampaignConfirmModalActions";
import { isAgencySpecific } from "../../../components/hoc/MediaAgencySpecific";
import { continueToPlanning } from "../components/CampaignRedirect";

// Components
import {
  SaveAsDraft,
  SendForConfirmmation,
  ContinueEditing,
  CampaignDetails,
} from "../components/HeaderNav";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

export function HeaderSection({ campaign, cityId }) {
  const dispatch = useDispatch();
  const history = useHistory();

  function confirmationAction() {
    dispatch(openCampaignConfirmFormModal());
  }

  const EnableContinueEditing = isAgencySpecific(ContinueEditing);
  const EnableSaveAsDraft = isAgencySpecific(SaveAsDraft);
  const EnableSendForConfirmmation = isAgencySpecific(SendForConfirmmation);

  const pageTitle = <CampaignDetails campaign={campaign} />;
  const pageActions = (
    <div className="d-flex ">
      {EnableContinueEditing && (
        <EnableContinueEditing
          onClickAction={() => continueToPlanning(campaign.id, cityId, history)}
        />
      )}
      {EnableSaveAsDraft && <EnableSaveAsDraft />}
      {EnableSendForConfirmmation && (
        <EnableSendForConfirmmation confirmAction={confirmationAction} />
      )}
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} shadow={true} />;
}
