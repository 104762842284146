//Utils
import {
  downloadFile,
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";
import { getToken } from "../utils/token";

//Constants
import {
  downloadExcelReportUrl,
  downloadCampaignPdfReportUrl,
  generateCampaignReportUrl,
  getCampaignSummaryUrl,
} from "../urls/CampaignReportURL";

export async function getCampaignSummaryData(campaignId) {
  //getting urls
  const getCampaignOverviewUrl = getCampaignSummaryUrl.replace(
    ":id",
    campaignId
  );

  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendGetRequest(getCampaignOverviewUrl, {}, true, headerParams);
}

export async function initiateMavinReportGeneration(campaignId) {
  const headerParams = {
    "Content-Type": "application/json",
  };
  const url = generateCampaignReportUrl.replace(":id", campaignId);
  return sendCreateOrUpdateRequest(url, {}, true, "PUT", headerParams, {});
}

export async function downloadExcelReportFn(campaignId) {
  const headerParams = {
    Authorization: "Bearer " + getToken(),
  };

  // Include Reach,Frequency in report,
  // Include tg specific reach and impressions as well
  const params = {
    _rnf: true,
    _tg: true,
  };

  return downloadFile(
    downloadExcelReportUrl.replace(":id", campaignId),
    true,
    headerParams,
    params
  );
}

export async function downloadCampaignPdfReportFn(campaignId) {
  const headerParams = {
    Authorization: "Bearer " + getToken(),
  };

  return downloadFile(
    downloadCampaignPdfReportUrl.replace(":id", campaignId),
    true,
    headerParams
  );
}
