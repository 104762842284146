// Components
import TableHeaders from "../../../components/table/TableHeaders";

// Table Headers
const tableHeaders = [
  {
    title: {
      displayName: "",
    },
  },
  {
    title: {
      displayName: <b>{"Lit"}</b>,
    },
  },
  {
    title: {
      displayName: <b>{"Not Lit"}</b>,
    },
  },
];

// Monthly Est Impression Table
export function MonthlyEstImpressionTable({
  impressionInfo,
  title,
  className = "",
}) {
  return (
    <div className={className}>
      {title && <strong>{title}</strong>}
      <table className="table table-sm mt-4">
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />
        <tbody>
          {impressionInfo.map((info, i) => (
            <tr key={i}>
              <td>
                <strong>{info.title}</strong>
              </td>
              <td>{info.otsLit}</td>
              <td>{info.ots}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
