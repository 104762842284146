import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { getCampaignPerformanceById } from "../../actions/campaign-performance/CampaignPerformanceActions";
import { getCampaignPlanOverview } from "../../../actions/campaign-plan/CampaignPlanActions";
import { getBasicCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";

// Utils and Constants
import { getDifferenceInDays } from "../../../common-utils/date-utils/DateUtils";
import { constructRedirectPath } from "../../../utils/redirect-utils/RediectUtil";
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";
import { DurationConstants } from "../../../constants/GeneralConstants";

// Components
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import Spinner from "../../../components/spinner/Spinner";
import TableHeaders from "../../../components/table/TableHeaders";
import { isAgencySpecific } from "../../../components/hoc/MediaAgencySpecific";
import {
  CampaignDetails,
  CancelCampaign,
  StopCampaign,
} from "../components/HeaderNav";
import { ProgressBarLarge } from "../components/ProgressBarLarge";
import CampaignViewCards from "../components/CampaignViewCards";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Pages and Section
import LiveCityHighlightsTable from "./LiveCityHighlightsTable";

// Page Constants
function getLiveCityPerformanceTableHeaders(
  activeCities,
  totalCities,
  campaignDuration,
  campaignDaysCompleted,
  totalMediaCount
) {
  return [
    {
      title: {
        displayName: (
          <span>
            {"Active Cities: "}
            <b>{`${activeCities} / ${totalCities}`}</b>
          </span>
        ),
        className: "col-3 border-right",
      },
    },
    {
      title: {
        displayName: (
          <div className="d-flex justify-content-between">
            <span>
              {"Active Media Sites: "}

              {/* TODO - show the count of active media sites */}
              <b>{`-- / ${totalMediaCount || 0}`}</b>
            </span>
            <span>
              <b>{campaignDaysCompleted || 0}</b>
              {" of the total "}
              <b>{campaignDuration || 0}</b>
              {" days Completed"}
            </span>
          </div>
        ),
        className: "col-9",
      },
    },
  ];
}

// Page-Component
function HeaderSection({ campaign }) {
  const updateStateLoading = useSelector(
    (state) => state.c_campaignBasicInfo.updateStateLoading
  );

  if (!campaign) {
    return null;
  }

  const EnableCancelCampaign = isAgencySpecific(CancelCampaign);
  const EnableStopCampaign = isAgencySpecific(StopCampaign);

  const pageTitle = <CampaignDetails campaign={campaign} />;
  const pageActions = (
    <div className="d-flex mt-2">
      <div>
        {EnableCancelCampaign && <CancelCampaign campaign={campaign} />}
        {EnableStopCampaign && <StopCampaign campaignId={campaign.id} />}
      </div>
      {updateStateLoading && <Spinner className="mx-3" />}
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} shadow={true} />;
}

function LiveCityPerformanceTableRow({
  cityId,
  eachLogSummary,
  infoByCity,
  eachCityInfo = {},
}) {
  const {
    totalGenericOts,
    totalGenericOtsLit,
    totalTargetOts,
    totalTargetOtsLit,
    targetGroupId,
  } = eachCityInfo;

  //Selector
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  const targetOtsServedTotal = eachLogSummary?.targetOtsServedTotal;
  const genericOtsServedTotal = eachLogSummary?.genericOtsServedTotal;

  const cityName = infoByCity[cityId]?.cityName;

  const mediaCount = infoByCity[cityId]?.mediaCount;

  // Planned Est. Impressions
  // TODO:: Remove 30 as multiplication factor - fix PlanData
  const plannedGenericOts =
    (totalGenericOtsLit ?? totalGenericOts) * DurationConstants.THIRTY_DAYS;
  const plannedTargetOts =
    (totalTargetOtsLit ?? totalTargetOts) * DurationConstants.THIRTY_DAYS;

  return (
    <tr>
      <td className="border-right">
        <div className="text-right">
          <p className="mb-1 btn-link mb-1">{cityName}</p>
          <span className="text-muted mb-2">{tgName}</span>
          <span className="text-muted d-block mt-2">{"General"}</span>
        </div>
      </td>
      <td>
        <div className="text-left">
          {/* TODO - show the count of active media count */}
          <p className="mb-1">{`-- of ${mediaCount || 0} sites are Active`}</p>

          {/* Tg Impression */}
          {targetGroupId && (
            <ProgressBarLarge
              impression={targetOtsServedTotal}
              plannedEstImpressions={plannedTargetOts}
              className="bg-success"
            />
          )}
          {/* General Impression */}
          <ProgressBarLarge
            impression={genericOtsServedTotal}
            plannedEstImpressions={plannedGenericOts}
            className="bg-primary"
          />
        </div>
      </td>
    </tr>
  );
}

function LiveCityPerformanceTable({ campaignPlanOverview, campaign }) {
  // Selector
  const campaignPerformance =
    useSelector((state) => state.campaignPerformance.campaignPerformance) || {};

  const { planByCity = {} } = campaignPlanOverview;

  const totalCities = Object.keys(planByCity).length;

  // to get active city count
  const activeCities = Object.values(planByCity).reduce((acc, eachCity) => {
    if (eachCity.isLive) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const { infoByCity = {}, mediaCount } = campaign;
  const { startTimestamp, endTimestamp } = campaign;

  const campaignDuration = getDifferenceInDays(startTimestamp, endTimestamp);

  const todayDate = new Date();
  const campaignDaysCompleted = getDifferenceInDays(startTimestamp, todayDate);

  // Table headers
  const tableHeaders = getLiveCityPerformanceTableHeaders(
    activeCities,
    totalCities,
    campaignDuration,
    campaignDaysCompleted,
    mediaCount
  );

  return (
    <div className="mt-5">
      <h4>Performace</h4>
      <div className="table-responsive">
        <table className="table table-media">
          <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />
          <tbody>
            {Object.keys(planByCity).map((eachCityId) => (
              <LiveCityPerformanceTableRow
                key={eachCityId}
                eachLogSummary={campaignPerformance[eachCityId]}
                cityId={eachCityId}
                infoByCity={infoByCity}
                eachCityInfo={planByCity[eachCityId]}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

/**
 * Page
 */
function CampaignLiveOverviewPage() {
  // State
  const { campaignId } = useParams();

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  // Get campaignPlan
  const campaignPlanOverview = useSelector(
    (state) => state.campaignPlan.campaignPlanOverview
  );

  // Tg name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  const { planByCity = {}, summary = {} } = campaignPlanOverview;

  // TODO:: once the api is returning target group id ,
  // Get "campaign plan overview" api
  // target group id
  const targetGroupId = Object.values(planByCity)[0]?.targetGroupId;

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBasicCampaignDetails(campaignId));
    dispatch(getCampaignPerformanceById(campaignId));
    dispatch(getCampaignPlanOverview(campaignId));
  }, [dispatch, campaignId]);

  // dispatching target group action
  useGetTgInfo(targetGroupId);

  const redirectLinkObj = {
    campaignOverviewLink: constructRedirectPath(`/campaign/${campaignId}/live`),
    citySummaryLink: constructRedirectPath(
      `/campaign/${campaignId}/city/:cityId/live`
    ),
  };

  const pageTitle = <CampaignDetails campaign={campaign} />;

  return (
    <>
      {/* SideBar */}
      <CampaignSidebar campaign={campaign} redirectLinkObj={redirectLinkObj} />

      {/* Content-Section */}
      <div className="content-wrapper px-0">
        {/* Page Header Section */}
        <PageHeader title={pageTitle} shadow={true} />

        {/* Page-Content */}
        <div className="page-content">
          {/* campaign view Cards Section */}
          <CampaignViewCards
            tgName={tgName}
            campaign={campaign}
            campaignPlanSummary={summary}
            mediaCount={campaign.mediaCount}
          />

          {/* Campaign Cities Performance */}
          <LiveCityPerformanceTable
            campaignPlanOverview={campaignPlanOverview}
            campaign={campaign}
          />

          {/* Cities Details Table */}
          <LiveCityHighlightsTable
            campaignPlanOverview={campaignPlanOverview}
          />
        </div>
      </div>
    </>
  );
}

export default CampaignLiveOverviewPage;
