import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router";

//Actions
import {
  generateMavinReport,
  getCampaignOverview,
} from "../../../actions/campaign-report/CampaignReportActions";
import {
  removeCityFromCampaign,
  updateCampaignTitle,
} from "../../../actions/campaign/CampaignActions";

//Components
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import Spinner from "../../../components/spinner/Spinner";
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import TitleEditor from "../../../components/title-editor/TitleEditor";
import CampaignViewTable from "./CampaignViewTable";
import ConfirmationForm from "../../../components/confirmation-form/ConfirmationForm";
import AlertMessage from "../../../mavin/components/alert-message/AlertMessage";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Pages and Sections
import OverviewSection from "./OverviewSection";
import ReportSection from "./ReportSection";

function PageHeaderSection({ campaign, loader, targetGroupName }) {
  const dispatch = useDispatch();

  const campaignId = campaign.id;

  // variables
  const pageTitle = (
    <div className="d-flex align-items-center">
      <TitleEditor
        title={campaign.title}
        onTitleChange={(titleStr) =>
          dispatch(updateCampaignTitle(titleStr, campaignId))
        }
        loader={loader}
        inputBoxClass={"rounded-lg"}
        titleClass={"px-0 mb-0 h1"}
      />
      <h3 className="mb-0"> | Target Group:{targetGroupName}</h3>
    </div>
  );
  const pageActions = <ReportSection campaign={campaign} />;

  return <PageHeader title={pageTitle} actions={pageActions} />;
}

function UpdateMavinStatsIndicator() {
  //Selector
  const campaignDurationChanging = useSelector(
    (state) => state.campaign.campaignDurationChanging
  );

  //Checks for campaignDurationChanging
  if (!campaignDurationChanging) {
    return null;
  }
  return (
    <AlertMessage
      className="mb-1 py-1"
      spinnerClassName="spinner-border-sm"
      textMessage="Updating Metrics"
    />
  );
}

/**
 * Page
 */
function CampaignViewPage() {
  const dispatch = useDispatch();
  const { id } = useParams();

  //Selector
  const campaign = useSelector(
    (state) => state.campaignReport.campaignSummary.campaign
  );

  const { targetGroupName = "" } = campaign || {};
  const summary = useSelector(
    (state) => state.campaignReport.campaignSummary.summary
  );

  const loading = useSelector(
    (state) => state.campaignReport.campaignSummaryLoading
  );

  // Title Loading
  const titleLoader = useSelector((state) => state.campaign.titleUpdateLoader);

  // Confirmation Form :: [true/false and dataObject]
  const openConfirmationForm = useSelector(
    (state) => state.confirmationModal.openModal
  );
  const processDataObject = useSelector(
    (state) => state.confirmationModal.processDataObject
  );

  // Link to pass in CampaignSidebar
  const redirectLinkObj = {
    campaignOverviewLink: RedirectTo.campaignViewPageUrl.replace(
      ":campaignId",
      id
    ),
    citySummaryLink: RedirectTo.campaignCityViewPageUrl.replace(
      ":campaignId",
      id
    ),
  };

  // Dispatch
  useEffect(() => {
    dispatch(getCampaignOverview(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!loading && summary && summary.reportStatus !== "GENERATED") {
      dispatch(generateMavinReport(id));
    }
  }, [summary?.reportStatus]);

  //Checks for page loading
  if (loading) {
    return <Spinner className="spinner-center mt-2" />;
  }
  //Checks for campaign and summary
  if (!campaign || !summary) {
    return null;
  }

  const infoByCity = campaign?.infoByCity;

  // Functions to "remove city from existing campaign"
  function removeCity() {
    dispatch(removeCityFromCampaign(id, processDataObject.cityId));
  }

  return (
    <>
      {/* Sidebar */}
      <CampaignSidebar campaign={campaign} redirectLinkObj={redirectLinkObj} />

      <div className="content-wrapper" id="cont-campaign-details">
        {/* Page Header */}
        <PageHeaderSection
          campaign={campaign}
          loader={titleLoader}
          targetGroupName={targetGroupName}
        />

        <div className="page-content">
          {/* Campaign Performance and Stats Card */}
          <OverviewSection campaign={campaign} summary={summary} />

          <div className="mt-5 d-flex">
            <h4 className="mr-2">{"City highlights"}</h4>
            {/* Mavin Stats Indicator */}
            <UpdateMavinStatsIndicator />
          </div>

          {/* Table */}
          <CampaignViewTable infoByCity={infoByCity} />
        </div>
      </div>

      {/** Modals */}
      {openConfirmationForm && <ConfirmationForm onClick={removeCity} />}
    </>
  );
}

export default CampaignViewPage;
