import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

//Actions
import { getCampaignMediaByCity } from "../../../actions/campaign-media/CampaignMediaActions";
import {
  getCityOverview,
  downloadCityPdfReport,
} from "../../../actions/campaign-report/CampaignCityReportActions";
import { cancelAppliedFilters } from "../../../actions/filter/FilterActions";

//Utils and Constants
import { PageSize } from "../../../constants/GeneralConstants";

//Components
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import Spinner from "../../../components/spinner/Spinner";
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import CampaignMediaForm from "../../../components/campaign-media-form/CampaignMediaForm";
import { CampaignCityPerformance } from "../../../mavin/components/campaign-city-performance/CampaignCityPerformance";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Pages and Section
import CampaignCityMapView from "./CampaignCityMapView";
import CityViewSiteOptions from "./CityViewSiteOptions";
import CampaignCityViewMediaTable from "./CampaignCityViewMediaTable";

//Css
import "./CampaignCityViewPage.scss";

// Page Components
function DownloadReport({ campaignId, cityId }) {
  const dispatch = useDispatch();

  // Selector
  const downloadingReport = useSelector(
    (state) => state.campaignCityReport.downloadCityPdfReportLoading
  );

  //checks for Report downloading
  if (downloadingReport) {
    return <Spinner className="spinner-border mr-3" />;
  }

  return (
    <p
      className="text-primary cursor-pointer download-report my-2"
      onClick={() => dispatch(downloadCityPdfReport(campaignId, cityId))}
      data-html2canvas-ignore="true"
    >
      {"Download Pdf Report"}
    </p>
  );
}

function PageHeaderSection({ campaign, citySelected, targetGroupName }) {
  const { cityName, cityId } = citySelected;

  const pageTitle =
    campaign.title + " :: " + cityName + " | TargetGroup :: " + targetGroupName;
  const pageActions = (
    <DownloadReport campaignId={campaign.id} cityId={cityId} />
  );

  return <PageHeader title={pageTitle} actions={pageActions} shadow={true} />;
}

/**
 * Page
 */
export default function CampaignCityViewPage() {
  const { id, cityId } = useParams();

  //Selector States
  const citySummaryLoading = useSelector(
    (state) => state.campaignCityReport.citySummaryLoading
  );
  const { campaign = {}, summary: citySummary = {} } = useSelector(
    (state) => state.campaignCityReport.citySummary
  );
  const { population } = citySummary;

  const { targetGroupName = "" } = campaign;
  // City media summary
  const cityMediaLoading = useSelector(
    (state) => state.campaignMedia.cityLoading
  );
  const cityMediaSummary = useSelector(
    (state) => state.campaignMedia.citySummary
  );
  const cityMediaSummaryResult = cityMediaSummary.result || {};
  const { items = [], pagination = {} } = cityMediaSummaryResult;

  // Create Media Modal Key
  const openMediaForm = useSelector((state) => state.campaignMedia.openModal);

  const citySelected = {
    cityId: cityId,
    cityName: citySummary.cityName || cityId,
  };

  // Link to pass in CampaignSidebar
  const redirectLinkObj = {
    campaignOverviewLink: RedirectTo.campaignViewPageUrl.replace(
      ":campaignId",
      id
    ),
    citySummaryLink: RedirectTo.campaignCityViewPageUrl.replace(
      ":campaignId",
      id
    ),
  };

  // TODO: as of now we are assuming pageSize of 1000 only to unblock
  // PDF report, need to look back on this..
  const pageNumber = 1,
    pageSize = PageSize.CitySummary;

  //Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(cancelAppliedFilters());

    dispatch(getCityOverview(id, cityId));
    dispatch(getCampaignMediaByCity(id, cityId, pageNumber, pageSize));
  }, [dispatch, id, cityId, pageSize]);

  // Functions
  function loadCityMedia() {
    dispatch(getCampaignMediaByCity(id, cityId, pageNumber, pageSize, true));
  }

  //checks for page loading
  if (cityMediaLoading || citySummaryLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }
  return (
    <>
      {/* Sidebar */}
      <CampaignSidebar
        campaign={campaign}
        citySelected={citySelected}
        redirectLinkObj={redirectLinkObj}
      />

      <div className="content-wrapper" id="cont-city-details">
        {/* Page Header */}
        <PageHeaderSection
          campaign={campaign}
          citySelected={citySelected}
          targetGroupName={targetGroupName}
        />

        <div className="page-content">
          {/* Performance Card */}
          <CampaignCityPerformance citySummary={citySummary} />

          {/* City Map View */}
          <CampaignCityMapView mediaList={items} />

          {/* City Site Option */}
          <CityViewSiteOptions loadCityMedia={loadCityMedia} />

          {/* Media Table */}
          <CampaignCityViewMediaTable
            mediaList={items}
            pagination={pagination}
            loadCityMedia={loadCityMedia}
            cityPopulation={population}
          />
        </div>
      </div>

      {/** Modals */}
      {openMediaForm && (
        /* Modal :: Create CampaignMedia */
        <CampaignMediaForm campaignId={id} cityId={cityId} />
      )}
    </>
  );
}
