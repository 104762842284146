import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";
import { updateCampaingPlanStatus } from "../../../apis/CampaignPlanAPI";

// Apis
import { updateCampaignFn } from "../../../apis/CampaignAPI";
import { saveSettingFn } from "../../../apis/ShareSettingsAPI";

// Contants and Utils
import { ActionTypes } from "../../../constants/ActionConstants";
import { CampaignState } from "../../pages/components/planningConstants";
import { constructRedirectPath } from "../../../utils/redirect-utils/RediectUtil";
import { getErrorMessage } from "../../../utils/util";

function* updateCampaignAndShareSettings(action) {
  try {
    const { title, buyerId, campaignId, saveSettings, history } =
      action.payload;

    const saveSettingsBean = Object.values(saveSettings);

    // api call to update campaign
    yield updateCampaignFn(title, buyerId, campaignId);

    // api call to save settings
    yield saveSettingFn(saveSettingsBean);

    yield updateCampaingPlanStatus(campaignId, CampaignState.REVIEW);

    yield put({
      type: ActionTypes.CampaignReview
        .UPDATE_CAMPAIGN_AND_SAVE_SETTINGS_SUCCESS,
    });

    // to close campaign confirm modal popup
    yield put({
      type: ActionTypes.CampaignConfirmFormModal.CLOSE_CAMPAIGN_CONFIRM_MODAL,
    });

    history.push(constructRedirectPath("/home"));
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: ActionTypes.CampaignReview
        .UPDATE_CAMPAIGN_AND_SAVE_SETTINGS_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.CampaignReview.UPDATE_CAMPAIGN_AND_SAVE_SETTINGS,
      updateCampaignAndShareSettings
    ),
  ]);
}
