// Utils and Constants
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import {
  getFrequencyValue,
  getModifiedPopulation,
  getReachPercentage,
  getReachValueAsString,
} from "../../../utils/ReachAndFrequencyUtils";
import { getOtsTotal } from "../../../utils/mavinMetrics";

// Components
import { getLtsRatingAvg } from "../../../components/campaign-table-row/CampaignRowCells";

function HeadCards({ item }) {
  const { title, text, component } = item;
  return (
    <div className="col">
      <div className="card h-100">
        <div className="card-body">
          <h4 className="card-title">{title}</h4>
          {component ? component : <p className="card-text">{text}</p>}
        </div>
      </div>
    </div>
  );
}

/**
 * @param {*} citySummary
 * @returns City Specific Performance
 */
export function CampaignCityPerformance({ citySummary = {} }) {
  const { ltsRatingAvg, reachFrequency, population } = citySummary;

  const { otsTotal, tgOtsTotal } = getOtsTotal(citySummary) || {};
  const ltsAvg = getLtsRatingAvg(ltsRatingAvg);

  // Reach And Frequency
  const { reach } = getReachValueAsString(reachFrequency);
  const reachPercentage = getReachPercentage(population, reachFrequency);
  const frequencyValue = getFrequencyValue(reachFrequency);
  const headCardsData = [
    {
      title: "No. of Sites",
      text: `${formatText(citySummary.mediaCount)}`,
    },
    {
      title: "Impressions",
      component: (
        <>
          <p>Tg : {formatText(toLocaleString(tgOtsTotal))}</p>
          <p>Total:{formatText(toLocaleString(otsTotal))}</p>
        </>
      ),
    },
    {
      title: "Population",
      text: getModifiedPopulation(population),
    },
    {
      title: "Reach (%)",
      text: `${formatText(reach)} ${reachPercentage}`,
    },
    {
      title: "Frequency",
      text: frequencyValue,
    },
    {
      title: "Avg LTS rating",
      text: `${formatText(ltsAvg)}`,
    },
  ];

  return (
    <>
      <h4 className="sec-title">{"City Performance"}</h4>
      <div className="row">
        {/* Card */}
        {headCardsData.map((item, index) => (
          <HeadCards key={index} item={item} />
        ))}
      </div>
    </>
  );
}
