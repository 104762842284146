import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import { getTargetGroups } from "../../../actions/org/OrgTargetGroupActions";
import { getClosestRoadSegments } from "../../../actions/org/OrgRoadStretchActions";

// Constants and Utils
import { defaultPagination } from "../../../constants/UrlConstants";
import { SegmentLoading } from "./ReportUtils";
import { constructRedirectPath } from "../../../utils/redirect-utils/RediectUtil";

// Components
import SearchInput from "../../../components/search-input/SearchInput";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import TargetGroupForm from "../campaign-planning/TargetGroupForm";

// Pages and Sections
import EarningCalculatorTable from "./EarningCalculatorTable";
import OtsAndEarningCards from "./OtsAndEarningCards";
import ReportMapView from "./ReportMapView";
import DayAndLitContainer from "./DayAndLitContainer";

// Page Components
function InputSearchSection({ locationStr, setLocationStr }) {
  const dispatch = useDispatch();
  const TXT_PLACEHOLDER_SEARCH = "Enter latitude, longitude";

  function onClickSearch() {
    dispatch(getClosestRoadSegments(locationStr));
  }

  return (
    <>
      <div className="p-3">
        <SearchInput
          placeholder={TXT_PLACEHOLDER_SEARCH}
          onClickSearch={onClickSearch}
          onTextChange={(e) => setLocationStr(e.target.value)}
        />
      </div>
      <hr className="divider my-0"></hr>
    </>
  );
}

/**
 * Page
 */
function SellerSingleSiteReportPage() {
  // State
  const [locationStr, setLocationStr] = useState("");
  const [dayCountAndLit, setDayCountAndLit] = useState({
    dayCount: "1",
    lit: "Lit",
  });

  // Selector State
  // all road-segments info
  const roadSegmentsMap = useSelector(
    (state) => state.orgRoadStretch.roadSegmentsMap
  );
  // segmentIds array to pass in TgForm
  const segmentIds = Object.keys(roadSegmentsMap);

  const segmentLoading = useSelector(
    (state) => state.orgRoadStretch.closestRoadSegmentLoading
  );

  // for "TG setup-Form"
  const openTgSetupForm = useSelector(
    (state) => state.targetGroupFormModal.openModal
  );

  const pageNumber = defaultPagination.pageNo,
    pageSize = defaultPagination.pageSize;

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTargetGroups(false, "", pageNumber, pageSize));
  }, [dispatch]);

  // const pageActions = (
  //   <Link
  //     className="mt-1"
  //     to={constructRedirectPath("/pitch/seller/excel-upload")}
  //   >
  //     {"Upload multiple sites"}
  //   </Link>
  // );

  return (
    <>
      <div className="content-wrapper row h-100">
        <div className="col-5 px-0">
          {/* Header Section */}
          <PageHeader
            title={"Seller Site Report"}
            // actions={pageActions}
            shadow={true}
          />

          {/* Location Search Section */}
          <InputSearchSection
            locationStr={locationStr}
            setLocationStr={setLocationStr}
          />

          {/* Page-Body Section */}
          {segmentLoading && <SegmentLoading />}
          {Object.keys(roadSegmentsMap).length > 0 && (
            <>
              {/* Days And Lit Selection Section */}
              <DayAndLitContainer setDayCountAndLit={setDayCountAndLit} />

              {/* Ots and Earning Cards */}
              <OtsAndEarningCards
                allRoadSegmentsData={Object.values(roadSegmentsMap)}
                dayCountAndLit={dayCountAndLit}
              />

              {/* Earning Calculator Table */}
              <EarningCalculatorTable dayCountAndLit={dayCountAndLit} />
            </>
          )}
        </div>

        {/* map-view */}
        <div className="col-7 bg-alt px-0">
          <ReportMapView
            locationStr={locationStr}
            allRoadSegmentsData={Object.values(roadSegmentsMap)}
          />
        </div>
      </div>

      {/* MODALS */}
      {/* TG-selection-form */}
      {openTgSetupForm && <TargetGroupForm segmentIds={segmentIds} />}
    </>
  );
}

export default SellerSingleSiteReportPage;
