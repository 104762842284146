import { useSelector } from "react-redux";

// Utils
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";
import { calEstPrice } from "../../utils/PriceFactorUtil";
import { getStretchImpressions } from "../../utils/campaignPlanningUtil";
import { StretchSelectionButton } from "./PlanningUtils";

function ProgressBar({ baseOts, currentOts, className }) {
  const width = currentOts ? (currentOts / baseOts) * 100 : 0;
  const widthPecentage = width + "%";

  return (
    <span
      className={`progress progress-bar ${className}`}
      role="progressbar"
      style={{ width: widthPecentage }}
      aria-valuenow={width}
      aria-valuemin="0"
      aria-valuemax="100"
    ></span>
  );
}

function ImpressionInfoCell({ stretchInfo, baseOts, dayCount }) {
  const {
    otsAvg,
    otsLitAvg,
    genericOtsLit,
    genericOts,
    targetOtsLit,
    targetOts,
  } = stretchInfo;

  const estImpressions = otsLitAvg ?? otsAvg;

  // for TG-specific-impressions
  const genericImpressions = genericOtsLit ?? genericOts;
  const specificImpressions = targetOtsLit ?? targetOts;

  if (genericImpressions || specificImpressions) {
    return (
      <div className="p-2 col-5">
        <div className="d-flex">
          <span className="col-2 px-0">{"Tg Imp: "}</span>
          <span
            className="col-3 text-truncate px-0"
            title={toStringWithRoundUp(dayCount * specificImpressions)}
          >
            {specificImpressions
              ? toStringWithRoundUp(dayCount * specificImpressions)
              : "NA"}
          </span>
          <div className="col-5">
            <ProgressBar
              baseOts={baseOts}
              currentOts={specificImpressions}
              className={"bg-success"}
            />
          </div>
        </div>

        <div className="d-flex">
          <span className="col-2 px-0">{"Gen Imp: "}</span>
          <span className="col-3 text-truncate px-0">
            {genericImpressions
              ? toStringWithRoundUp(dayCount * genericImpressions)
              : "NA"}
          </span>
          <div className="col-6">
            <ProgressBar baseOts={baseOts} currentOts={genericImpressions} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-2 col-5 d-flex">
      <span className="col-2 px-0">{"Gen Imp: "}</span>
      <span className="col-3 px-0 text-truncate">
        {estImpressions ? toStringWithRoundUp(dayCount * estImpressions) : "NA"}
      </span>
      <div className="col-6">
        <ProgressBar baseOts={baseOts} currentOts={estImpressions} />
      </div>
    </div>
  );
}

function StretchInfoCell({ stretchInfo, dayCount }) {
  const { name } = stretchInfo;
  const { genericImpressions, specificImpressions } =
    getStretchImpressions(stretchInfo);

  // Total Impression ==> After selecting TG
  if (specificImpressions) {
    const totalImpressions = specificImpressions + genericImpressions;
    return (
      <div className="p-2 col-5">
        <p className="mb-0">{name}</p>
        <span className="sub-text">
          Total Imp:{" "}
          {totalImpressions
            ? toStringWithRoundUp(dayCount * totalImpressions)
            : "NA"}
        </span>
      </div>
    );
  }

  // Total Impressions ==> Before selecting TG
  return (
    <div className="p-2 col-5">
      <p className="mb-0">{name}</p>
      <span className="sub-text">
        Total Imp:{" "}
        {genericImpressions
          ? toStringWithRoundUp(dayCount * genericImpressions)
          : "NA"}
      </span>
    </div>
  );
}

function EstimatedPrice({ stretchInfo, dayCount }) {
  const { minOperatingPriceAvg: mop, occupancyAvg: occupancy } = stretchInfo;
  const { genericImpressions, specificImpressions } =
    getStretchImpressions(stretchInfo);

  const genPriceFactor = useSelector((state) => state.campaignPlanning.genCpm);
  const tgPriceFactor = useSelector((state) => state.campaignPlanning.tgCpm);

  const estimatePriceBean = {
    genericImpressions,
    specificImpressions,
    mop,
    occupancy,
    dayCount,
    tgPriceFactor,
    genPriceFactor,
  };
  const { genericCost, specificCost } = calEstPrice(estimatePriceBean);
  const totalEstCost = specificCost ? genericCost + specificCost : genericCost;

  return (
    <div className="col-2">
      <p>{toStringWithRoundUp(mop ? mop : "NA")}</p>
      <p>{toStringWithRoundUp(totalEstCost ? totalEstCost : "NA")}</p>
    </div>
  );
}

function RoadStretch({ stretchInfo, baseOts, dayCount }) {
  return (
    <>
      <div className="d-flex justify-content-between px-3 py-1">
        <StretchInfoCell stretchInfo={stretchInfo} dayCount={dayCount} />

        <ImpressionInfoCell
          stretchInfo={stretchInfo}
          baseOts={baseOts}
          dayCount={dayCount}
        />

        <EstimatedPrice stretchInfo={stretchInfo} dayCount={dayCount} />

        <StretchSelectionButton stretchInfo={stretchInfo} />
      </div>
      <hr className="divider my-0"></hr>
    </>
  );
}

function RoadStretchContainer({ roadStretchData, baseOts }) {
  // Duration
  const duration = useSelector(
    (state) => state.campaignPlanning.durationInDays
  );

  // RoadStretch data is empty return null
  if (roadStretchData.length < 1) {
    return null;
  }

  return (
    <>
      {roadStretchData.map((stretchInfo) => (
        <RoadStretch
          key={stretchInfo.id}
          stretchInfo={stretchInfo}
          dayCount={duration}
          baseOts={baseOts}
        />
      ))}
    </>
  );
}

export default RoadStretchContainer;
