import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Actions
import {
  getClosestRoadSegments,
  resetClosestRoadSegments,
} from "../../actions/org/OrgRoadStretchActions";
import { clearSegmentOtsInfo } from "../../actions/mavin-tools/OtsToolActions";

// Utils and Constants
import {
  getIsTrafficDataAvailable,
  LocationSearchInput,
  MapViewWithPindropAndRoadSelect,
  NoDataMessageSection,
  SelectedSegmentInfoSection,
} from "./ToolsUtils";
import {
  formatText,
  toLocaleString,
} from "../../common-utils/string-utils/StringUtils";
import { toStringWithRoundUp } from "../../common-utils/number-utils/NumberUtils";
import { constructLineChartData } from "../../utils/ChartUtils";
import { NoSegmentsMessageSection } from "../org/OrgUtils";
import { IsTrafficOtsMethodology } from "../../common-utils/subscription-utils/SubscriptionUtil";
// Components
import PageHeader from "../../mavin/components/page-header/PageHeader";
import { LineChart } from "../../components/charts/Charts";
import { MonthlyEstImpressionTable } from "../../mavin/components/media-info/MonthlyEstImpressionTable";
import AlertMessage from "../../mavin/components/alert-message/AlertMessage";

// Page Components
function ImpressionAndDwellTimeSection({ segmentOtsInfo }) {
  const {
    region = {},
    mobilityOts,
    dwellTime = {},
    avg_ots = {},
  } = segmentOtsInfo;
  // segments

  const { ots, otsLit } = avg_ots;

  const trafficOts = otsLit ?? ots;

  const isTrafficMethodology = IsTrafficOtsMethodology();

  const averageTime =
    Object.keys(dwellTime).length > 0
      ? `${toStringWithRoundUp(dwellTime.averageTime)} Sec`
      : "--";

  // Region
  const { otsAvg, otsLitAvg, dwellTimeAvg } = region;
  const regionOtsAvg = toLocaleString(otsLitAvg ?? otsAvg) || "--";
  const regionDwellTimeAvg = toStringWithRoundUp(dwellTimeAvg) || "--";

  return (
    <div className="row py-3">
      {/* Monthly Impressions */}
      <div className="col">
        <h5>{`Monthly Impressions`}</h5>
        <h3 className="mb-0">
          {formatText(
            toLocaleString(isTrafficMethodology ? trafficOts : mobilityOts)
          )}
        </h3>
        <span className="text-muted">{`City Avg: ${regionOtsAvg}`}</span>
      </div>

      {/* Avg Dwell Time */}
      <div className="col">
        <h5>{"Avg. Dwell Time"}</h5>
        <h3 className="mb-0">{`${averageTime}`}</h3>
        <span className="text-muted">{`City Avg:  ${regionDwellTimeAvg} Sec`}</span>
      </div>
    </div>
  );
}

function HourlyTrafficAndOtsSection({ selectedSegment, segmentOtsInfo }) {
  const { avgSpeedProfile, maxSpeedProfile, minSpeedProfile } = selectedSegment;
  const trafficData = {
    hour_avg: avgSpeedProfile,
    hour_max: maxSpeedProfile,
    hour_min: minSpeedProfile,
  };

  // Chart Data
  const chartTrafficData = constructLineChartData(trafficData);

  // preparing data for "Estimate-Impression-Table"
  const { avg_ots, max_ots, min_ots } = segmentOtsInfo;

  const impressionInfo = [
    {
      title: "Min.",
      otsLit: formatText(toLocaleString(min_ots?.otsLit)),
      ots: formatText(toLocaleString(min_ots?.ots)),
    },
    {
      title: "Avg.",
      otsLit: formatText(toLocaleString(avg_ots?.otsLit)),
      ots: formatText(toLocaleString(avg_ots?.ots)),
    },
    {
      title: "Max.",
      otsLit: formatText(toLocaleString(max_ots?.otsLit)),
      ots: formatText(toLocaleString(max_ots?.ots)),
    },
  ];
  const isTrafficMethodology = IsTrafficOtsMethodology();

  //if chart traffic data is available
  if (chartTrafficData.length > 0) {
    return (
      <div className="my-4 d-flex">
        {/* hourly traffic chart */}
        <div className="col-7 pl-0">
          <h5 className="mb-3">{"Hourly Traffic Profile"}</h5>

          <LineChart data={chartTrafficData} />
        </div>

        {/* Est Monthly Impressions */}
        {isTrafficMethodology && (
          <MonthlyEstImpressionTable
            title={"Monthly Impressions Estimates"}
            className={"col-5 pr-0"}
            impressionInfo={impressionInfo}
          />
        )}
      </div>
    );
  }

  return (
    <h5 className=" mt-3 text-center font-italic">
      Hourly Traffic Profile is not available
    </h5>
  );
}

/**
 * Page : OTS Tool
 */
function OtsToolPage() {
  const dispatch = useDispatch();
  // State
  const [locationStr, setLocationStr] = useState("");
  const [coordinate, setCoordinate] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState({});

  // Checking that Traffic Data is available or not
  const isTrafficDataAvailable = getIsTrafficDataAvailable(selectedSegment);

  // all closest road-segments
  const roadSegments =
    useSelector((state) =>
      Object.values(state.orgRoadStretch.roadSegmentsMap)
    ) || [];

  // Segment Ots Info
  const segmentOtsInfo = useSelector((state) => state.otsTool.segmentOtsInfo);

  // Reset Ots info
  useEffect(() => {
    setSelectedSegment({});
    dispatch(clearSegmentOtsInfo());
    dispatch(resetClosestRoadSegments());
  }, [dispatch, locationStr]);

  useEffect(() => {
    // showing toaster to select road segment
    if (roadSegments.length > 0) {
      toast.info("Please select the Road to generate OTS");
    }
    // when ever location change we are clearing previous closest road segment
    // and fetch new closest road segment. so length is changing
    // if the roadSegments length change useEffect will get trigger and shows toaster message
  }, [roadSegments.length]);

  // Functions
  // This call-back function is used to pick the lat-lng and
  // fetch all closest road-segments
  function getCoordinatesFromPinDrop(locationData) {
    const coordinateString = `${locationData.lat},${locationData.lng}`;
    setLocationStr(coordinateString);
    setCoordinate([locationData.lat, locationData.lng]);
    dispatch(getClosestRoadSegments(coordinateString, true));
  }

  function onPolylineClick(e, eachSegment) {
    setSelectedSegment({});
    dispatch(clearSegmentOtsInfo());
    setSelectedSegment(eachSegment);
    e.originalEvent.view.L.DomEvent.stopPropagation(e);
  }

  return (
    <div className="content-wrapper map-content-wrapper">
      {/* Map Left Section */}
      <div className="map-layout-left">
        <PageHeader title={"Impressions (OTS)"} shadow={false} />
        <hr className="divider my-3 row"></hr>

        {/* Left Content */}
        <LocationSearchInput
          locationStr={locationStr}
          setLocationStr={setLocationStr}
          setCoordinate={setCoordinate}
        />

        {/* No RoadSegments Message */}
        <NoSegmentsMessageSection
          roadSegments={roadSegments}
          locationStr={locationStr}
        />

        {/* Selected Segement Info Section */}
        {Object.keys(selectedSegment).length > 0 && (
          <SelectedSegmentInfoSection
            selectedSegment={selectedSegment}
            isTrafficDataAvailable={isTrafficDataAvailable}
          />
        )}

        {/* No Traffic Data Message */}
        {!isTrafficDataAvailable && (
          <NoDataMessageSection
            messageText={"No Traffic Data Available, Select other Segment."}
          />
        )}

        {Object.keys(segmentOtsInfo).length > 0 && (
          <>
            {/* Impression and Dwell Time Section */}
            <ImpressionAndDwellTimeSection segmentOtsInfo={segmentOtsInfo} />
            <hr className="divider row my-0"></hr>

            {/* Hourly Traffic Section */}
            {isTrafficDataAvailable && (
              <HourlyTrafficAndOtsSection
                selectedSegment={selectedSegment}
                segmentOtsInfo={segmentOtsInfo}
              />
            )}
          </>
        )}
      </div>

      {/* MapView Section */}
      <MapViewWithPindropAndRoadSelect
        coordinate={coordinate}
        selectedSegment={selectedSegment}
        getCoordinatesFromPinDrop={getCoordinatesFromPinDrop}
        onPolylineClick={onPolylineClick}
      />
    </div>
  );
}

export default OtsToolPage;
