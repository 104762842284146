import React from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

// Actions
import { updateCampaignDuration } from "../../../actions/campaign/CampaignActions";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Utils
import { getModifiedPopulation } from "../../../utils/ReachAndFrequencyUtils";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import {
  CityNameCell,
  MediaCountCell,
  DurationCell,
  ImpressionsCell,
  LtsCell,
  HamburgerCell,
} from "../../../components/campaign-table-row/CampaignRowCells";
import {
  FrequencyCell,
  ReachCell,
} from "../../../components/campaign-media-table-row/MediaRow";

// Page Constants
const otsHeaderInfo = [
  {
    displayText: "Tg Impressions",
  },
  {
    displayText: "Total Impressions",
  },
];

const reachHeaderInfo = [
  {
    displayText: "Tg Reach  (%)",
  },
  {
    displayText: "Total Reach  (%)",
  },
];

const campaignSummaryTableHeaders = () => [
  {
    title: {
      displayName: "City",
      className: "w-10",
    },
    subTitle: {
      displayName: "Status",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "No. of sites",
      className: "w-10 text-right",
    },
    subTitle: {
      displayName: "",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "Start and end date",
      className: "w-20 text-right",
    },
    subTitle: {
      displayName: "Duration",
      className: "sub-text",
    },
  },
  {
    element: <OtsAndReachHeader headerInfo={otsHeaderInfo} subText="Sites" />,
  },
  {
    element: (
      <OtsAndReachHeader headerInfo={reachHeaderInfo} subText="Population" />
    ),
  },

  {
    title: {
      displayName: "Frequency",
      className: "w-20 text-right",
    },
  },

  {
    title: {
      displayName: "LTS",
      className: "w-10 text-right",
    },
    subTitle: {
      displayName: "Sites",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "",
      className: "w-10 text-right",
    },
    subTitle: {
      displayName: "",
      className: "sub-text",
    },
  },
];

function OtsAndReachHeader({ headerInfo, subText }) {
  return (
    <th className="text-right">
      {headerInfo.map((eachHeaderInfo, i) => (
        <div key={i}>
          <p className="mb-2 mt-0">
            {eachHeaderInfo.displayText}
            {eachHeaderInfo.percentage && (
              <span> {eachHeaderInfo.percentage}</span>
            )}
          </p>
        </div>
      ))}

      <p className="sub-text mb-0">{subText}</p>
    </th>
  );
}

function CampaignSummaryTableRow({ campaignId, cityInfo }) {
  const dispatch = useDispatch();
  const { cityId, endTimestamp, startTimestamp, reachFrequency, population } =
    cityInfo;

  // function to update date
  function onDateSelect(selectedDate) {
    dispatch(updateCampaignDuration(selectedDate, cityInfo));
  }

  const dateObj = { startTimestamp, endTimestamp };

  const campaignCityViewPageUrl = RedirectTo.campaignCityViewPageUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);

  return (
    <tr key={`camp-${cityId}`}>
      <CityNameCell
        cityElementStyle="p-3 align-middle"
        cityInfo={cityInfo}
        redirectUrl={campaignCityViewPageUrl}
      />

      <MediaCountCell
        mediaCountElementStyle="p-3 text-right align-middle"
        cityInfo={cityInfo}
      />

      <DurationCell
        durationElementStyle="text-right pr-0 align-middle"
        duration={cityInfo.duration}
        onDateSelect={onDateSelect}
        dateObj={dateObj}
      />

      <ImpressionsCell
        impressionsElementStyle="p-3 text-right align-middle"
        cityInfo={cityInfo}
      />

      <ReachCell
        reachElementStyle={"p-3 text-right align-middle"}
        reachFrequency={reachFrequency}
        cityPopulation={population}
        showPopulation={true}
      />
      <FrequencyCell
        frequencyElementClass={"p-3 text-right align-middle"}
        reachFrequency={reachFrequency}
      />

      <LtsCell
        ltsElementClass="p-3 text-right align-middle"
        cityInfo={cityInfo}
      />

      <HamburgerCell
        hamburgerElementStyle="p-3 text-right align-middle"
        cityInfo={cityInfo}
      />
    </tr>
  );
}

function CampaignViewTable({ infoByCity }) {
  const { id } = useParams();

  return (
    <div className="table-responsive mt-3">
      <table className="table table-media">
        {/* Table Headers */}
        <TableHeaders
          tableHeaders={campaignSummaryTableHeaders()}
          headerClass={"thead"}
        />
        <tbody>
          {/* Table Row */}
          {Object.values(infoByCity).map((_cityInfo, index) => {
            return (
              <CampaignSummaryTableRow
                campaignId={id}
                cityInfo={_cityInfo}
                key={index}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default CampaignViewTable;
