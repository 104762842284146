/**
 * Campaign Map View by City
 */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

// Actions
import {
  saveMapDetails,
  restoreMapSavedState,
} from "../../../../actions/map-view/SaveMapStateActions";
import { removeMediaSite } from "../../../../actions/map-view/media-sites/MediaSitesActions";
import { getTgHeatMapData } from "../../../../actions/geo-data/GeoDataActions";
import { getTargetGroups } from "../../../../actions/org/OrgTargetGroupActions";

// Constants and Utils
import {
  bcForAnalyserMapView,
  bcForPlanningMapView,
} from "../../../../utils/BreadCrumb";
import {
  useResetMapState,
  useUrlKeyCheck,
} from "../../../../mavin/utils/hooks/HooksUtil";
import { MapView, UrlKeys } from "../../../../constants/GeneralConstants";
import { MapInfoBox } from "../../../../pages/map-view/boundaries/BoundariesUtil";
import { defaultPagination } from "../../../../constants/UrlConstants";

// Components
import LLMap from "../../../../components/map/leaflet-map/LLMap";
import NavbarBranding from "../../../../components/top-nav/NavbarBranding";
import Breadcrumb from "../../../../components/breadcrumb/Breadcrumb";
import Spinner from "../../../../components/spinner/Spinner";
import TargetGroupHeatmap from "../../../../components/map/target-group-heatmap/TargetGroupHeatmap";
import { ButtonWithLoader } from "../../../../mavin/components/button/Button";
import ConfirmationForm from "../../../../components/confirmation-form/ConfirmationForm";

// Pages and Sections
import SiteMarkers from "../../../../pages/map-view/media-sites/SiteMarkers";
import DataLayerMarkers from "../../../../pages/map-view/data-layers/DataLayerMarkers";
import BoundaryMarkers from "../../../../pages/map-view/boundaries/BoundaryMarkers";
import SecLayer from "../../../../pages/map-view/sec/SecLayer";
import PoiMarkers from "../../../../components/map/markers/PoiMarkers";
import MapTabsSection from "./MapTabsSection";
import RoadStretchMarkers from "../../../../pages/map-view/road-stretches/RoadStretchMarkers";
import MapControlsSection from "../../../../pages/map-view/MapControlsSection";
import LocationOfIntrestForm from "../../../../mantaray/components/map-view/location-of-intrest/LocationOfIntrestForm";
import LocationOfIntrestMarker from "../../../../mantaray/components/map-view/location-of-intrest/LocationOfIntrestMarker";

// CSS
import "../../../../pages/map-view/MapView.scss";

// Components
function BreadcrumbSection() {
  const { id, cityId } = useParams();

  // Selector State
  const restoreLoading = useSelector(
    (state) => state.saveMapState.restoreLoading
  );
  const cityName = useSelector((state) => state.saveMapState.cityName);

  // Conditionally rendered breadcrumb
  const analyserBreadcrumbItems = bcForAnalyserMapView(id, cityId, cityName);
  const planningBreadcrumbItems = bcForPlanningMapView(id, cityId, cityName);

  const { campaignPlanning } = UrlKeys;

  // checking "plan" key in url and render "Breadcrumb" conditionally
  // if the plan is present in url then its is from campaign planning page
  const isFromCampaignPlanner = useUrlKeyCheck(campaignPlanning);
  if (restoreLoading) return null;

  // Return
  if (isFromCampaignPlanner) {
    return <Breadcrumb items={planningBreadcrumbItems} />;
  }
  return <Breadcrumb items={analyserBreadcrumbItems} />;
}

// Page Components
// --------------------------------------------------
function MapHeader() {
  const dispatch = useDispatch();
  const { id, cityId } = useParams();

  const { campaignPlanning } = UrlKeys;
  // checks map view is from campaign analyser or campaign planner
  // if it is from campaign analyser - saveMapAsImage = true
  const saveMapAsImage = !useUrlKeyCheck(campaignPlanning);
  // Selector State
  const saveMapViewLoading = useSelector((state) => state.saveMapState.loading);

  return (
    <nav className="main-header map-header navbar navbar-expand">
      <NavbarBranding />

      {/* Conditionally rendered breadcrumb */}
      <BreadcrumbSection />

      <div className="navbar-nav ml-auto">
        {/* Save Map State Button*/}
        <ButtonWithLoader
          displayContent={"Save Map State"}
          onClickFunction={() =>
            dispatch(saveMapDetails(id, cityId, saveMapAsImage))
          }
          loader={saveMapViewLoading}
          isDisabled={saveMapViewLoading}
          className="ml-3"
        />
      </div>
    </nav>
  );
}

function MapViewLoader() {
  return (
    <div className="position-absolute text-center w-100 mt-5">
      <Spinner className="mt-4" />
    </div>
  );
}

/**
 * Page
 */
function CampaignMapView() {
  const { id, cityId } = useParams();

  // useLocation
  const location = useLocation();
  const { targetGroupId = "" } = location.state;

  // State
  const [selectedTab, setSelectedTab] = useState(0);
  const [hoveredBoundaryInfo, setHoveredBoundaryInfo] = useState({});
  const [locationStr, setLocationStr] = useState("");
  const [coordinate, setCoordinate] = useState([]);

  // Selector State
  const restoredCenter = useSelector(
    (state) => state.saveMapState.restoreCenter
  );

  const restoreLoading = useSelector(
    (state) => state.saveMapState.restoreLoading
  );

  const restoredZoom =
    useSelector((state) => state.saveMapState.restoreZoom) ||
    MapView.ZOOM_INDEX;

  const bbox = useSelector((state) => state.saveMapState.cityBBox);

  // Confirmation Form :: [true/false and dataObject]
  const openConfirmationForm = useSelector(
    (state) => state.confirmationModal.openModal
  );

  const openLocationOfIntrestForm = useSelector(
    (state) => state.locationOfIntrest.openModal
  );

  const processDataObject = useSelector(
    (state) => state.confirmationModal.processDataObject
  );

  // all "poiTypeLayer" points combined array to Show on Map after selecting any TG
  const allPoiPointsArr = useSelector((state) => state.geoData.allPoiPointsArr);

  // for listing of TG's (includes all information)
  const targetGroupsList = useSelector((state) => state.orgTargetGroup.tgList);

  const selectedTg =
    targetGroupsList.find((eachTg) => eachTg.id === targetGroupId) || {};

  // to get  all resultingPoiLayers
  const { resultLayers = [] } = selectedTg;

  const resPoiLayers = resultLayers.map((eachLayer) => eachLayer.poiTypeId);
  const pageNumber = defaultPagination.pageNo;
  const PageSize = defaultPagination.pageSize;

  // Dispatch
  const dispatch = useDispatch();

  // Reset Map State
  useResetMapState();

  useEffect(() => {
    dispatch(restoreMapSavedState(id, cityId));
  }, [dispatch, id, cityId]);

  useEffect(() => {
    dispatch(getTargetGroups(true, "", pageNumber, PageSize));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTgHeatMapData(resPoiLayers, bbox));
  }, [dispatch, bbox]);

  // Functions
  function tabOnClick(index) {
    if (selectedTab === index) {
      setSelectedTab(0);
      return;
    }
    setSelectedTab(index);
  }

  // This is passed in "ConfirmAction Form" to Remove selected media-site
  function removeMediaSiteFn() {
    dispatch(removeMediaSite(id, processDataObject.campaignMediaInfo));
  }

  return (
    <div className="content-wrapper map-content">
      {/* Header-Sections */}
      <MapHeader />

      {/* Loader and MapView */}
      {restoreLoading ? (
        <MapViewLoader />
      ) : (
        <>
          {/* Tabs Section */}
          <MapTabsSection
            selectedTab={selectedTab}
            tabOnClick={tabOnClick}
            campaignId={id}
            cityId={cityId}
            bbox={bbox}
          />

          {/* Map Container */}
          {restoredCenter && (
            <div className="map-cont">
              <LLMap
                center={[Number(restoredCenter[0]), Number(restoredCenter[1])]}
                zoom={restoredZoom}
              >
                {/* LocationOfIntrestMarker */}
                {coordinate.length > 0 && (
                  <LocationOfIntrestMarker position={coordinate} />
                )}

                {/* CampaignMedia and InventoryMedia Markers */}
                <SiteMarkers />

                <PoiMarkers />
                <DataLayerMarkers cityId={cityId} />

                {/* This component covers pinCodes and Wards Layers */}
                <BoundaryMarkers
                  setHoveredBoundaryInfo={setHoveredBoundaryInfo}
                />

                <SecLayer />

                {/* Target-Group-HeatMap */}
                <TargetGroupHeatmap
                  hmkey={targetGroupId}
                  allPoiPointsArr={allPoiPointsArr}
                />
                <RoadStretchMarkers />

                {/* Map Info Box */}
                <MapInfoBox hoveredBoundaryInfo={hoveredBoundaryInfo} />
              </LLMap>
            </div>
          )}

          {/* Legends for mapView */}
          <MapControlsSection />
        </>
      )}

      {/** Modals */}
      {openConfirmationForm && <ConfirmationForm onClick={removeMediaSiteFn} />}

      {openLocationOfIntrestForm && (
        <LocationOfIntrestForm
          locationStr={locationStr}
          setLocationStr={setLocationStr}
          setCoordinate={setCoordinate}
          center={restoredCenter}
        />
      )}
    </div>
  );
}

export default CampaignMapView;
