import { useSelector } from "react-redux";

// Constants  and Utils
import { MediaLitStatus } from "../../../constants/GeneralConstants";
import { constructRadarChartData } from "../../../utils/ChartUtils";
import { getMediaLtsRating } from "../../../utils/mavinMetrics";
import { IsTrafficOtsMethodology } from "../../../common-utils/subscription-utils/SubscriptionUtil";

// Components
import { RadarChart } from "../../../components/charts/Charts";
import MediaInfo from "../../../mavin/components/media-info/MediaInfo";
import {
  ArAC_ratings,
  CityPopulation,
  MediaFrequency,
  MediaReach,
  MediaTgFrequency,
  MonthlyImpressions,
  OtherDetails,
} from "../../../mavin/components/media-site-performance/SitePerformanceUtils";

// Section Functions
function constructMediaObject(mediaInfoBean) {
  const media = {
    title: "",
    type: "",
    height: "",
    width: "",
    litStatus: "",
  };

  if (Object.keys(mediaInfoBean).length === 0) {
    return media;
  }

  media.title = mediaInfoBean.title;
  media.type = mediaInfoBean.type;
  media.height = mediaInfoBean.height;
  media.width = mediaInfoBean.width;
  media.litStatus = mediaInfoBean.lit;

  return media;
}

function getOtsOfMedia(mavinMetrics, lit) {
  const otsSummary = mavinMetrics.otsSummary || {};

  if (Object.keys(otsSummary).length < 1) {
    return null;
  }

  const isTrafficMethodology = IsTrafficOtsMethodology();

  if (isTrafficMethodology) {
    const ots =
      lit !== MediaLitStatus.NOT_LIT ? otsSummary.otsLit : otsSummary.ots;
    return ots;
  }

  const ots = otsSummary.expMobileOts;
  return ots;
}

function SitePerformanceSection({ mavinMetrics, regionInfo }) {
  const {
    otsSummary,
    ltsSummary,
    dwellTimeSummary,
    reachFrequency = {},
    query,
  } = mavinMetrics;

  // LIT STATUS
  const { lit } = query;

  // OTS
  const { durationDays } = otsSummary || {};
  const ots = getOtsOfMedia(mavinMetrics, lit);

  // LTS
  const ltsRating = getMediaLtsRating(ltsSummary);
  const { population } = regionInfo || {};

  return (
    <div className="row">
      {/* Impressions */}
      <MonthlyImpressions impressions={{ ots }} duration={durationDays} />

      {/* Reach, Population, Frequency */}
      <CityPopulation cityPopulation={population} />
      <MediaReach reachFrequency={reachFrequency} cityPopulation={population} />
      <MediaFrequency reachFrequency={reachFrequency} />
      <MediaTgFrequency reachFrequency={reachFrequency} />

      {/* Lts rating */}
      <OtherDetails
        ltsRating={ltsRating}
        reachFrequency={reachFrequency}
        dwellTimeSummary={dwellTimeSummary}
      />

      {/* ArAC Rating */}
      <ArAC_ratings ltsSummary={ltsSummary} />
    </div>
  );
}

// Site Info and Performance Section
function MediaInfoAndPerformanceSection({ mavinMetrics, regionInfo }) {
  // Selector
  const mediaInfoBean = useSelector(
    (state) => state.singleSiteAnalyserTool.mediaInfoBean
  );

  if (Object.keys(mavinMetrics).length === 0) {
    return null;
  }

  // Lts Summary
  const ltsSummary = mavinMetrics.ltsSummary || {};

  // Constructing MediaObject for MediaInfo component
  const media = constructMediaObject(mediaInfoBean);

  // Chart data
  const chartData = constructRadarChartData(ltsSummary, regionInfo);

  return (
    <>
      <p className="h2 py-3 font-weight-bold">{"Site Report"}</p>
      <div className="d-flex">
        <div className="col-9 px-0">
          <MediaInfo media={media} titleClassName={"h2"} />
          <hr className="divider"></hr>
          {/* Site performance */}
          <SitePerformanceSection
            mavinMetrics={mavinMetrics}
            regionInfo={regionInfo}
          />
        </div>
        <div className="col-3 px-0">
          <RadarChart data={chartData} height={300} />
        </div>
      </div>
      <hr className="divider"></hr>
    </>
  );
}

export default MediaInfoAndPerformanceSection;
