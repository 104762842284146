import { useState } from "react";
import moment from "moment";

// Rechart
import {
  Legend,
  Line,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart as RadChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  LineChart as ReactLineChart,
  Pie,
  Cell,
  PieChart as ReactPieChart,
  Area,
  AreaChart,
  Sector,
} from "recharts";

// Chart Constant
const CHART_COLORS = [
  "#0070b4",
  "#ff700e",
  "#00b029",
  "#f00029",
  "#a646bd",
  "#984f4b",
  "#fd4bc2",
];

// used to render the "Lines" in the "LineChart"
const DEFAULT_LINES_DATA = [
  {
    type: "linear",
    dataKey: "Hour Average",
  },
  {
    type: "linear",
    dataKey: "Hour Maximum",
  },
  {
    type: "linear",
    dataKey: "Hour Minimum",
  },
];

// "LineChart" Margin
const DEFAULT_CHART_MARGIN = {
  top: 5,
  right: 30,
  left: 0,
  bottom: 5,
};

/**
 * Radar Chart
 */
const RadarChart = ({ data, height }) => {
  return (
    <ResponsiveContainer height={height}>
      <RadChart data={data}>
        <PolarGrid />
        <PolarRadiusAxis domain={[0, 5]} tickCount={6} />
        <PolarAngleAxis dataKey={"label"} />
        <Radar name="Media" dataKey="media" fill="#82ca9d" fillOpacity={0.3} />
        <Radar
          name="Region"
          dataKey="region"
          fill="#8884d8"
          fillOpacity={0.3}
        />
        <Legend />
      </RadChart>
    </ResponsiveContainer>
  );
};

/**
 * Line Chart
 */
function LineChart({
  data = [],
  linesData = DEFAULT_LINES_DATA,
  XAxisLabel = "",
  YAxisLabel = "",
  chartMargin = DEFAULT_CHART_MARGIN,
}) {
  return (
    <ResponsiveContainer height={330}>
      <ReactLineChart data={data} margin={chartMargin}>
        <XAxis
          interval={1}
          dataKey={"name"}
          label={{
            value: XAxisLabel,
            position: "insideBottomRight",
            dy: 10,
          }}
        />
        <YAxis width={50} label={{ value: YAxisLabel, angle: -90, dx: -20 }} />
        <Tooltip />
        <Legend />

        {/* Lines User wants to return in chart */}
        {linesData.map((eachLine, i) => (
          <Line
            key={i}
            type={eachLine.type}
            dataKey={eachLine.dataKey}
            stroke={eachLine.stroke || CHART_COLORS[i]}
          />
        ))}
      </ReactLineChart>
    </ResponsiveContainer>
  );
}

// for Hover animation
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    midAngle,
    fill,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + innerRadius + 10 * cos;
  const sy = cy + innerRadius + 10 * sin;
  return (
    <Sector
      cx={sx}
      cy={sy}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
  );
};

function renderCustomizedLabel(props) {
  const { cx, cy, midAngle, outerRadius, value, fill } = props;
  const RADIAN = Math.PI / 180;
  const radius = outerRadius + 20;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const percentage = value ? `${value}%` : "";
  return (
    <text
      x={x}
      y={y}
      fill={fill}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {percentage}
    </text>
  );
}

/**
 * Pie Chart
 */
function PieChart({ data, colors = CHART_COLORS }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const onMouseOver = (data, index) => {
    setActiveIndex(index);
  };

  const onMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <ResponsiveContainer height={350}>
      <ReactPieChart>
        <Legend
          verticalAlign="bottom"
          align="center"
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          payload={data.map((item, index) => {
            const { name, value } = item;
            return {
              value: `${name} (${value}%)`,
              color: value ? colors[index % colors.length] : "#bebebe", // gray color,
            };
          })}
        />
        <Pie
          activeIndex={activeIndex}
          isAnimationActive={true}
          data={data}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={120}
          fill="#8884d8"
          dataKey="value"
          activeShape={renderActiveShape}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </ReactPieChart>
    </ResponsiveContainer>
  );
}

/**
 * Time series chart
 */
function TimeSeriesChart({ data, label }) {
  function formatXAxis(tickItem) {
    return moment(tickItem).format("MM-DD");
  }

  return (
    <ResponsiveContainer height={330}>
      <AreaChart data={data}>
        <XAxis dataKey="key" tickFormatter={formatXAxis} interval={20} />
        <YAxis
          dataKey={label}
          width={25}
          domain={["auto", () => 0]}
          tickCount={7}
        />
        <Tooltip />
        <Legend />
        <Area
          type="linear"
          dot={{ fill: "#0070b4", stroke: "#0070b4", strokeWidth: 1 }}
          dataKey={label}
          fill="#cce2f0"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export { LineChart, PieChart, TimeSeriesChart, RadarChart };
