import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";

// Actions
import {
  getTargetGroups,
  resetTgGroup,
} from "../../../actions/org/OrgTargetGroupActions";
import { getRegionNames } from "../../../actions/regions/RegionActions";
import { openTargetGroupForm } from "../../actions/campaign-planning/TargetGroupFormActions";
import { resetRoadStretches } from "../../actions/campaign-planning/CampaignPlanningRoadStretchesActions";
import { resetTgSpecificOts } from "../../actions/campaign-planning/TgSpecificOtsActions";
import {
  getRegionsData,
  resetCampaignPlanning,
} from "../../actions/campaign-planning/CampaignPlanningActions";
import { restCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";
import { resetCampaignPlanDetails } from "../../../actions/campaign-plan/CampaignPlanActions";
import { removeTgHeatMapData } from "../../../actions/geo-data/GeoDataActions";
import { openPoiSelectionForm } from "../../actions/campaign-planning/PoiSelectionFormActions";

// Constants and Utils
import { defaultPagination } from "../../../constants/UrlConstants";
import { useRestoreCampaignPlan } from "../../utils/HooksUtil";
import { FormDataTargets } from "../../../constants/GeneralConstants";
import { useGetPoiHirarchy } from "../../../mavin/utils/hooks/HooksUtil";
import { getMergedCityId } from "../../../pages/map-view/CityInfoTempFixUtil";

// Components
import Spinner from "../../../components/spinner/Spinner";

// Page Sections
import PlanningMapView from "./PlanningMapView";
import PlanningFooterSection from "./PlanningFooterSection";
import PlanningHeaderSection from "./PlanningHeaderSection";
import { SortingBtn, useSortableData } from "./SortingUtils";
import { AddCaptiveAreaSection, LoadingRoadStretches } from "./PlanningUtils";
import RoadStretchContainer from "./RoadStretchContainer";
import CostAndOtsSection from "./CostAndOtsSection";
import PoiAndCaptiveAreaForm from "./AddPoiAreaForm";
import TargetGroupForm from "./TargetGroupForm";
import SingleCitySelectionForm from "../../../mavin/components/single-city-selection-form/SingleCitySelectionForm";
import { PoiSelectionForm } from "./PoiSelectionForm";

// Page-Component
function TargetGroupFormSection() {
  const selectedTgName = useSelector((state) => state.orgTargetGroup.tgName);

  //tg select loader
  const tgSelectLoading = useSelector((state) => state.tgSpecificOts.loading);

  const dispatch = useDispatch();
  function openTgFormFunction() {
    dispatch(openTargetGroupForm());
  }

  return (
    <div>
      <p className="mb-1">{"Target-Group"}</p>
      <div className="d-flex">
        <button
          type="button"
          className="btn border rounded-lg shadow-none px-2"
          data-toggle="modal"
          data-target={`#${FormDataTargets.targetGroupForm}`}
          onClick={openTgFormFunction}
        >
          {selectedTgName ? selectedTgName : "Select TG"}
        </button>
        {tgSelectLoading && <Spinner className="ml-2" />}
      </div>
    </div>
  );
}

function PoiSelelctionFormSection() {
  const dispatch = useDispatch();

  // Fetching Selected Pois
  const getSelectedPoisLoading = useSelector(
    (state) => state.poiSelectionForm.getSelectedPoisLoading
  );

  return (
    <div className="ml-3">
      <p className="mb-1 ml-3">{"Poi"}</p>
      <div className="d-flex">
        <button
          type="button"
          className="btn border rounded-lg shadow-none px-2"
          data-toggle="modal"
          data-target="#poi-selection-form"
          onClick={() => dispatch(openPoiSelectionForm())}
        >
          {"Select Poi"}
        </button>
        {getSelectedPoisLoading && <Spinner className="ml-2" />}
      </div>
    </div>
  );
}

function StretchActionsSection({
  roadStretchesDataMap,
  requestRoadStretchSort,
  requestRoadSegmentsSort,
}) {
  const roadStretchesDataMapLength = Object.keys(roadStretchesDataMap).length;

  if (roadStretchesDataMapLength < 1) {
    return null;
  }

  return (
    <>
      <div className="d-flex justify-content-between p-3">
        <span className="mt-2">{`Road Stretches (${roadStretchesDataMapLength})`}</span>
        <div className="d-flex">
          {roadStretchesDataMapLength > 0 && (
            <SortingBtn
              requestRoadStretchSort={requestRoadStretchSort}
              requestRoadSegmentsSort={requestRoadSegmentsSort}
            />
          )}

          {/* Add Captive Area (Road-segment/Poi) Section */}
          {/* <AddCaptiveAreaSection /> */}
        </div>
      </div>
      <hr className="divider my-0"></hr>
    </>
  );
}

function getQueryParams(search) {
  const queryCityId = new URLSearchParams(search).get("_city");
  const existigCitiesSring = new URLSearchParams(search).get("_extcities");
  let selectedCitiesMap = "";
  if (existigCitiesSring) {
    selectedCitiesMap = existigCitiesSring
      .split(",")
      .reduce((acc, eachCity) => {
        acc[eachCity] = true;
        return acc;
      }, {});
  }

  const existingTgId = new URLSearchParams(search).get("_tg");
  return { queryCityId, selectedCitiesMap, existingTgId };
}

/**
 * Page
 */
function CampaignPlanningPage() {
  const dispatch = useDispatch();

  const { campaignId } = useParams();
  const search = useLocation().search;
  const { queryCityId, selectedCitiesMap, existingTgId } =
    getQueryParams(search);

  // const openAddCaptiveAreaForm = useSelector((state) => state.poiFormModal.openModal);
  // for "TG setup-Form"
  const openTgSetupForm = useSelector(
    (state) => state.targetGroupFormModal.openModal
  );

  // for "SINGLE City-Selection-form"
  const openSingleCitySelectionForm = useSelector(
    (state) => state.singleCitySelectionModal.openModal
  );

  // For Poi-Selection-Form Model
  const openPoiSelectionForm = useSelector(
    (state) => state.poiSelectionForm.openModal
  );

  // for "region-data"
  const regionsData =
    useSelector((state) => state.campaignPlanning.regionsData) || {};
  const center = regionsData.center;

  // TgSpecific Ots
  const tgSpecificOts =
    useSelector((state) => state.tgSpecificOts.tgSpecificOts) || {};

  // baseOts
  const baseOts = useSelector((state) => state.planningRoadStretches.baseOts);

  // for "road-stretch"
  const roadStretchesDataMap = useSelector(
    (state) => state.planningRoadStretches.roadStretchDetailsMap
  );

  // "road-stretches" with tgSpecific Ots
  const newRoadStretchesData = Object.values(roadStretchesDataMap).map(
    (item) => ({
      ...item,
      ...tgSpecificOts[item.id],
    })
  );

  // road-stretches sorting details
  const [sortedRoadStretchesData, requestRoadStretchSort] =
    useSortableData(newRoadStretchesData);

  const roadStretchLoading = useSelector(
    (state) => state.planningRoadStretches.loading
  );

  // for road-segment
  const roadSegmentDataMap = useSelector(
    (state) => state.planningRoadSegments.roadSegmentDetailsMap
  );

  // road-segments with tgSpecific Ots
  const newRoadSegmentsData = Object.values(roadSegmentDataMap).map((item) => ({
    ...item,
    ...tgSpecificOts[item.id],
  }));

  // road-segments sorting details
  const [sortedRoadSegmentsData, requestRoadSegmentsSort] =
    useSortableData(newRoadSegmentsData);

  // for exact lat-long
  const poiData = useSelector((state) => state.planningPoi.poiDetails) || {};

  // "IDs required for "TGSelector" component
  const segmentIds = Object.keys(roadSegmentDataMap) || [];

  // regions name list
  const regionsName = useSelector((state) => state.region.regionsName) || [];
  const regionNamesLoading = useSelector((state) => state.region.namesLoading);

  // TG list Loading
  const tgListLoading = useSelector(
    (state) => state.orgTargetGroup.targetGroupLoading
  );

  // Get CityId once city is selected
  const cityId = useSelector((state) => state.campaignPlanning.cityId);

  const pageNo = defaultPagination.pageNo,
    pageSize = defaultPagination.pageSize;

  // Dispatch
  useEffect(() => {
    // Reset Actions
    dispatch(resetRoadStretches());
    dispatch(resetTgSpecificOts());
    dispatch(resetCampaignPlanning());
    dispatch(resetTgGroup());
    dispatch(restCampaignDetails());
    dispatch(resetCampaignPlanDetails());
    dispatch(removeTgHeatMapData());

    dispatch(getRegionNames());
    dispatch(getTargetGroups(false, "", pageNo, pageSize));
  }, [dispatch]);

  // Custom useEffect
  useRestoreCampaignPlan(campaignId, queryCityId);
  useGetPoiHirarchy(getMergedCityId(cityId));

  // Functions
  function onSubmitCitySelectionForm(cityId) {
    dispatch(getRegionsData(cityId));
  }

  if (regionNamesLoading || tgListLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <>
      <div className="campaign-planning">
        <div className="planning-layout-left">
          {/* Header Section */}
          <PlanningHeaderSection />

          {cityId && (
            <>
              <div className="d-flex px-3 my-2">
                {/* TG Selection  */}
                <TargetGroupFormSection />

                {/* POI Selection */}
                <PoiSelelctionFormSection />
              </div>
              <hr className="divider my-0"></hr>
            </>
          )}

          {/* StretchCount, Sorting, Filter, AddCaptiveArea */}
          <StretchActionsSection
            roadStretchesDataMap={roadStretchesDataMap}
            requestRoadStretchSort={requestRoadStretchSort}
            requestRoadSegmentsSort={requestRoadSegmentsSort}
          />

          {/* Road Stretch Container */}
          <div className="planning-road-container">
            {roadStretchLoading && <LoadingRoadStretches />}
            <RoadStretchContainer
              roadStretchData={Object.values(sortedRoadStretchesData)}
              baseOts={baseOts}
            />
          </div>

          {/* Footer Section */}
          <div className="fixed-bottom col-6 px-0 bg-white">
            <CostAndOtsSection />
            <PlanningFooterSection selectedCitiesMap={selectedCitiesMap} />
          </div>
        </div>

        {/* map view */}
        <div className="planning-layout-right bg-alt">
          <PlanningMapView
            center={center}
            roadStretchData={Object.values(newRoadStretchesData)}
            segmentData={Object.values(roadSegmentDataMap)}
            poiData={poiData}
          />
        </div>
      </div>

      {/* MODALS */}
      {/* {openAddCaptiveAreaForm && <PoiAndCaptiveAreaForm />} */}

      {/* TG-selection-form */}
      {openTgSetupForm && (
        <TargetGroupForm existingTgId={existingTgId} cityId={cityId} />
      )}

      {/* CitySelection form */}
      {openSingleCitySelectionForm && (
        <SingleCitySelectionForm
          regionsName={regionsName}
          selectedCitiesMap={selectedCitiesMap}
          onSubmit={onSubmitCitySelectionForm}
          selectedCityId={cityId}
        />
      )}

      {/* Poi-Selection form */}
      {openPoiSelectionForm && (
        <PoiSelectionForm cityId={getMergedCityId(cityId)} />
      )}
    </>
  );
}

export default CampaignPlanningPage;
