import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Actions
import { updateCampaignState } from "../../../actions/campaign/CampaignBasicInfoActions";

// Constants And Utils
import { constructRedirectPath } from "../../../utils/redirect-utils/RediectUtil";
import { ProohFormDataTargets } from "../../constants/GeneralConstants";

// Components
import Spinner from "../../../components/spinner/Spinner";

// Other page Sections
import { CampaignState, CampaignStateTest } from "./planningConstants";

function StatusIcon({ displayText, textTypeClass, iconClass }) {
  return (
    <div className={`d-flex ml-2 ${textTypeClass}`}>
      <i className={`fas ${iconClass} mt-1`}></i>
      <span className="ml-1">{displayText}</span>
    </div>
  );
}

export function CampaignStatus({ state }) {
  if (state === CampaignState.DRAFT) {
    return (
      <StatusIcon
        displayText={CampaignStateTest.DRAFT}
        textTypeClass="text-warning"
        iconClass="fa-exclamation-triangle"
      />
    );
  }

  if (state === CampaignState.REVIEW) {
    return (
      <StatusIcon
        displayText={CampaignStateTest.REVIEW}
        textTypeClass="text-warning"
        iconClass="fa-exclamation-triangle"
      />
    );
  }

  if (state === CampaignState.CONFIRMED) {
    return (
      <StatusIcon
        displayText={CampaignStateTest.CONFIRMED}
        iconClass="fa-clock"
      />
    );
  }

  if (state === CampaignState.SCHEDULED) {
    return (
      <StatusIcon
        displayText={CampaignStateTest.SCHEDULED}
        iconClass="fa-clock"
      />
    );
  }

  if (state === CampaignState.LIVE) {
    return (
      <StatusIcon
        displayText={CampaignStateTest.LIVE}
        textTypeClass="text-success"
        iconClass="fa-video"
      />
    );
  }

  if (state === CampaignState.COMPLETED) {
    return (
      <StatusIcon
        displayText={CampaignStateTest.COMPLETED}
        textTypeClass="text-success"
        iconClass="fa-check-circle"
      />
    );
  }

  if (state === CampaignState.CANCELLED) {
    // TODO: Set Cancelled Icon
    return (
      <StatusIcon
        displayText={CampaignStateTest.CANCELLED}
        textTypeClass="text-danger"
        iconClass="fa-window-close"
      />
    );
  }

  return null;
}

function getClientName(campaign) {
  const { planStatus } = campaign;
  let client = "TODO";
  if (CampaignState.DRAFT === planStatus) {
    client = "NA";
  }
  return client;
}

export function CampaignDetails({ campaign }) {
  if (Object.keys(campaign).length < 1) {
    return null;
  }

  const { title = "New Campaign", planStatus } = campaign;
  const client = getClientName(campaign);
  return (
    <>
      <h3>{title}</h3>
      <div className="d-flex">
        <span>
          {`Client: ${client} | Last Updated: ${new Date(
            campaign.updatedOn
          ).toDateString()} | `}{" "}
        </span>
        <CampaignStatus state={planStatus} />
      </div>
    </>
  );
}

export function Review({ campaignId, cityId }) {
  const history = useHistory();
  function redirectToReviewPage() {
    history.push(
      constructRedirectPath(`/campaign/${campaignId}/city/${cityId}/review`)
    );
  }
  return (
    <button
      type="button"
      className="btn btn-primary m-2"
      onClick={redirectToReviewPage}
    >
      Review
    </button>
  );
}

export function SaveAsDraft() {
  const history = useHistory();

  function redirectToHomePage() {
    history.push(constructRedirectPath("/home"));
  }

  return (
    <button
      type="button"
      className="btn btn-outline-primary m-2"
      onClick={redirectToHomePage}
    >
      Save as Draft
    </button>
  );
}

export function StopCampaign({ campaignId }) {
  const dispatch = useDispatch();
  return (
    <button
      type="button"
      className="btn btn-primary"
      onClick={() => dispatch(updateCampaignState(campaignId, "CANCELLED"))}
    >
      {"Stop Campaign"}
    </button>
  );
}

export function SendForConfirmmation({ confirmAction }) {
  //Selector
  const sendForConfirmation = useSelector(
    (state) => state.campaignReview.campaignConfirmLoading
  );

  return (
    <button
      type="button"
      data-toggle="modal"
      disabled={sendForConfirmation}
      data-target={`#${ProohFormDataTargets.sendConfirmationForm}`}
      onClick={confirmAction}
      className="btn btn-primary m-2"
    >
      <span>Send For Confirmation</span>{" "}
      {sendForConfirmation && (
        <Spinner className="ml-1 text-dark spinner-border-sm" />
      )}
    </button>
  );
}

export function ContinueEditing({ onClickAction }) {
  return (
    <button type="button" className="btn btn-link" onClick={onClickAction}>
      Continue Editing
    </button>
  );
}

export function ExtendCampaign({ campaignId }) {
  return (
    <button type="button" className="btn btn-primary">
      {"Extend Campaign"}
    </button>
  );
}

export function ShareCampaign({ campaignId }) {
  return (
    <button type="button" className="btn btn-primary">
      {"Share"}
      <i className="fa fa-share-alt pl-2"></i>
    </button>
  );
}

export function CancelCampaign({ campaignId }) {
  return (
    <button type="button" className="btn btn-link">
      {"Cancel Campaign"}
    </button>
  );
}

export function AddCityInCampaign({ onClickAction }) {
  return (
    <button
      type="button"
      className="btn btn-primary mx-2"
      onClick={onClickAction}
    >
      {"Add City"}
      <i className="fa fa-share-alt pl-2"></i>
    </button>
  );
}

export function ConfirmCampaign({ confirmAction }) {
  //Selector
  const updateStateLoading = useSelector(
    (state) => state.campaignPlan.updateStateLoading
  );

  return (
    <button
      type="button"
      className="btn btn-primary mx-2"
      onClick={confirmAction}
    >
      <span>Confirm</span>{" "}
      {updateStateLoading && (
        <Spinner className="ml-1 text-dark spinner-border-sm" />
      )}
    </button>
  );
}

export function ScheduleCampaign({ scheduleCampaign }) {
  //Selector
  const updateStateLoading = useSelector(
    (state) => state.campaignPlan.updateStateLoading
  );
  return (
    <button
      type="button"
      className="btn btn-primary m-2"
      onClick={scheduleCampaign}
    >
      <span>Schedule</span>{" "}
      {updateStateLoading && (
        <Spinner className="ml-1 text-dark spinner-border-sm" />
      )}
    </button>
  );
}
