import { CompareLocations } from "../../constants/action-constants/mavin-tools/CompareLocationsActionConstants";

// for Compare Location Tool
export const getCompareLocationSummary = (
  coordinate1,
  coordinate2,
  radius
) => ({
  type: CompareLocations.GET_COMPARE_LOCATION_SUMMARY,
  payload: { coordinate1, coordinate2, radius },
});

export const clearCompareLocationSummary = () => ({
  type: CompareLocations.CLEAR_COMPARE_LOCATION_SUMMARY,
});
