//campaign City report
export const CampaignCityReport = {
  // City Summary
  GET_CITY_OVERVIEW: "GET_CITY_OVERVIEW",
  GET_CITY_OVERVIEW_SUCCESS: "GET_CITY_OVERVIEW_SUCCESS",
  GET_CITY_OVERVIEW_FAILURE: "GET_CITY_OVERVIEW_FAILURE",

  // Approx ots for city
  APPROX_OTS_FOR_CITY: "APPROX_OTS_FOR_CITY",
  APPROX_OTS_FOR_CITY_SUCCESS: "APPROX_OTS_FOR_CITY_SUCCESS",
  APPROX_OTS_FOR_CITY_FAILURE: "APPROX_OTS_FOR_CITY_FAILURE",

  // City summary report
  DOWNLOAD_CITY_PDF_REPORT: "DOWNLOAD_CITY_PDF_REPORT",
  DOWNLOAD_CITY_PDF_REPORT_SUCCESS: "DOWNLOAD_CITY_PDF_REPORT_SUCCESS",
  DOWNLOAD_CITY_PDF_REPORT_FAILURE: "DOWNLOAD_CITY_PDF_REPORT_FAILURE",
};
