import React from "react";

// Components
import { PlainDropdown } from "../dropdown/Dropdown";

export default function TargetGroupSelectionCard({
  TargetGroupData,
  tgList,
  handleTgselection,
}) {
  const { step, title, message } = TargetGroupData;
  return (
    <div className="card shadow mb-4 mt-3">
      <div className="d-flex justify-content-between">
        <div className="card-content">
          <p className="card__step">{step}</p>
          <p className="card__title">{title}</p>
          <p className="card__message">
            {message}
            <a className="text-primary"> Learn about templates</a>
          </p>
        </div>
        <div className="card-action">
          <PlainDropdown
            items={tgList}
            className="btn  shadow-none rounded-lg border  mt-4"
            placeHolder="Select Target Group"
            onItemSelect={handleTgselection}
            dropMenuClassName="dropdown-fixed-height"
          />
        </div>
      </div>
    </div>
  );
}
