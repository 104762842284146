//Utils
import {
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";

//Constants
import {
  getOrgAllUserListUrl,
  getOrgUserByIdUrl,
  disableOrgUserUrl,
  enableOrgUserUrl,
  createOrgUserUrl,
  updateOrgUserUrl,
  updateOrgUserRoleUrl,
} from "../urls/OrgUserManageURL";

export async function getOrgAllUsersData(pageNumber, pageSize) {
  const params = {
    pn: pageNumber,
    ps: pageSize,
  };
  return sendGetRequest(getOrgAllUserListUrl, params);
}

export async function getOrgUserDataById(userId) {
  const url = getOrgUserByIdUrl.replace(":userId", userId);
  return sendGetRequest(url);
}

export async function createOrgUser(newUserObj) {
  return sendCreateOrUpdateRequest(createOrgUserUrl, newUserObj);
}

export async function enableOrgUser(userId) {
  const url = enableOrgUserUrl.replace(":userId", userId);
  return sendCreateOrUpdateRequest(url, {}, true, "put");
}

export async function disableOrgUser(userId) {
  const url = disableOrgUserUrl.replace(":userId", userId);
  return sendCreateOrUpdateRequest(url, {}, true, "put");
}

export async function updateOrgUserInfo(orgUserInfo, userId) {
  const url = updateOrgUserUrl.replace(":userId", userId);
  return sendCreateOrUpdateRequest(url, orgUserInfo, true, "put");
}

//  TODO ::Remove this once update api is ready
// export async function updateOrgUserRoles(roles, userId) {
//   const url = updateOrgUserRoleUrl.replace(":userId", userId);
//   return sendCreateOrUpdateRequest(url, roles, true, "put");
// }
