export const Campaign = {
  //Get all campaigns
  GET_CAMPAIGNS: "GET_CAMPAIGNS",
  GET_CAMPAIGNS_SUCCESS: "GET_CAMPAIGNS_SUCCESS",
  GET_CAMPAIGNS_FAILURE: "GET_CAMPAIGNS_FAILURE",

  //Update Campaign Title
  UPDATE_CAMPAIGN_TITLE: "UPDATE_CAMPAIGN_TITLE",
  UPDATE_CAMPAIGN_TITLE_SUCCESS: "UPDATE_CAMPAIGN_TITLE_SUCCESS",
  UPDATE_CAMPAIGN_TITLE_FAILURE: "UPDATE_CAMPAIGN_TITLE_FAILURE",

  //Create campaign
  CREATE_CAMPAIGN: "CREATE_CAMPAIGN",
  CREATE_CAMPAIGN_SUCCESS: "CREATE_CAMPAIGN_SUCCESS",
  CREATE_CAMPAIGN_FAILURE: "CREATE_CAMPAIGN_FAILURE",

  //Update campaign duration
  UPDATE_CAMPAIGN_DURATION: "UPDATE_CAMPAIGN_DURATION",
  UPDATE_CAMPAIGN_DURATION_SUCCESS: "UPDATE_CAMPAIGN_DURATION_SUCCESS",
  UPDATE_CAMPAIGN_DURATION_FAILURE: "UPDATE_CAMPAIGN_DURATION_FAILURE",

  // Get all shared campaigns
  GET_SHARED_CAMPAIGN: "GET_SHARED_CAMPAIGN",
  GET_SHARED_CAMPAIGN_SUCCESS: "GET_SHARED_CAMPAIGN_SUCCESS",
  GET_SHARED_CAMPAIGN_FAILURE: "GET_SHARED_CAMPAIGN_FAILURE",

  // Add Cities to exsting Campaign
  ADD_CITIES_TO_EXISTING_CAMPAIGN: "ADD_CITIES_TO_EXISTING_CAMPAIGN",
  ADD_CITIES_TO_EXISTING_CAMPAIGN_SUCCESS:
    "ADD_CITIES_TO_EXISTING_CAMPAIGN_SUCCESS",
  ADD_CITIES_TO_EXISTING_CAMPAIGN_FAILURE:
    "ADD_CITIES_TO_EXISTING_CAMPAIGN_FAILURE",

  // Remove City from Campaign
  REMOVE_CITY_FROM_CAMPAIGN: "REMOVE_CITY_FROM_CAMPAIGN",
  REMOVE_CITY_FROM_CAMPAIGN_SUCCESS: "REMOVE_CITY_FROM_CAMPAIGN_SUCCESS",
  REMOVE_CITY_FROM_CAMPAIGN_FAILURE: "REMOVE_CITY_FROM_CAMPAIGN_FAILURE",
};
