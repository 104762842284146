import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

//Action
import { openCreateCampaignMediaForm } from "../../../actions/campaign-media/CampaignMediaActions";

//Constants
import { FormDataTargets, PageSize } from "../../../constants/GeneralConstants";

//Components
import { Dropdown } from "../../../components/dropdown/Dropdown";
import Spinner from "../../../components/spinner/Spinner";
import ApproxMavinMetrics from "./ApproxMavinMetrics";
import GenerateMavinMetrics from "./GenerateMavinMetrics";
import Filter from "../../../components/filter/Filter";
import AlertMessage from "../../../mavin/components/alert-message/AlertMessage";

function CityViewSiteOptions({ loadCityMedia }) {
  const history = useHistory();
  const { id, cityId } = useParams();

  //Selector
  // update campaignMediaDuration
  const campaignMediaDurationChanging = useSelector(
    (state) => state.campaignMedia.mediaDurationChanging
  );
  const cmcIsLoading = useSelector((state) => state.campaignMedia.loading);

  //Dispatch
  const dispatch = useDispatch();
  function openCreateCampaignMediaFormFunction() {
    dispatch(openCreateCampaignMediaForm());
  }

  // route-change on excel-upload
  const routeChange = () => {
    history.push({
      pathname: "/site-upload",
      state: {
        campaignId: id,
        cityId: cityId,
      },
    });
  };

  // Checks for Campaign Media Duration Changing
  if (campaignMediaDurationChanging) {
    return (
      <div className="d-flex">
        <AlertMessage
          className="py-2 mt-5 mb-0"
          spinnerClassName="spinner-border-sm"
          textMessage="Updating Metrics"
        />
      </div>
    );
  }

  return (
    <div className="mt-5 navbar px-0">
      <div className="d-flex align-items-center">
        <h4 className="site-details">{"Site Details"}</h4>
        {/* Approx Mavin Metrics */}
        <ApproxMavinMetrics campaignId={id} cityId={cityId} />
      </div>

      <div className="d-flex align-items-center">
        {/* checks for create media loading */}
        {cmcIsLoading && <Spinner />}

        <GenerateMavinMetrics campaignId={id} cityId={cityId} />

        {/* Upload Site Dropdown */}
        <Dropdown buttonName="Upload more sites" style={"mt-0"}>
          {/* Upload excell */}
          <div className="dropdown-menu dropdown-menu-right">
            <button className="dropdown-item" onClick={routeChange}>
              Excel file
            </button>

            {/* To open create media form  */}
            <button
              className="dropdown-item"
              data-toggle="modal"
              data-target={`#${FormDataTargets.campaignMediaForm}`}
              onClick={openCreateCampaignMediaFormFunction}
            >
              Create new media site
            </button>
          </div>
        </Dropdown>

        {/* Media Type Filter */}
        <Filter
          onFiltersApplied={loadCityMedia}
          pageSize={PageSize.CitySummary}
        />
      </div>
    </div>
  );
}

export default CityViewSiteOptions;
