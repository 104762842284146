import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  setGenCpm,
  setTgCpm,
} from "../../actions/campaign-planning/CampaignPlanningActions";

// Constants and Utils
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";
import { constructTotalImpAndCost } from "../../utils/campaignPlanningUtil";

// Page Functions
function calculateOtsSplitAndCost(
  selectedRoads,
  tgSpecificOts,
  dayCount,
  tgPriceFactor,
  genPriceFactor
) {
  const roadStretchOtsMap = Object.keys(selectedRoads).reduce(
    (acc, eachStretchId) => {
      acc[eachStretchId] = tgSpecificOts[eachStretchId];
      return acc;
    },
    {}
  );
  return constructTotalImpAndCost(
    selectedRoads,
    roadStretchOtsMap,
    dayCount,
    tgPriceFactor,
    genPriceFactor
  );
}

function calculateAccImpAndCost(
  segmentOtsSplitAndCost,
  stretchOtsSplitAndCost
) {
  const totalOtsSplitAndCost = {
    genericImpressions: 0,
    specificImpressions: 0,
    specificCost: 0,
    genericCost: 0,
  };

  // Segment ots and cost calculation
  //---------------------------------------
  totalOtsSplitAndCost.specificImpressions =
    totalOtsSplitAndCost.specificImpressions +
    segmentOtsSplitAndCost.specificImpressions;

  totalOtsSplitAndCost.genericImpressions =
    totalOtsSplitAndCost.genericImpressions +
    segmentOtsSplitAndCost.genericImpressions;

  totalOtsSplitAndCost.specificCost =
    totalOtsSplitAndCost.specificCost + segmentOtsSplitAndCost.specificCost;
  totalOtsSplitAndCost.genericCost =
    totalOtsSplitAndCost.genericCost + segmentOtsSplitAndCost.genericCost;

  // Stretch ots and cost calculation
  //---------------------------------------
  totalOtsSplitAndCost.specificImpressions =
    totalOtsSplitAndCost.specificImpressions +
    stretchOtsSplitAndCost.specificImpressions;

  totalOtsSplitAndCost.genericImpressions =
    totalOtsSplitAndCost.genericImpressions +
    stretchOtsSplitAndCost.genericImpressions;

  totalOtsSplitAndCost.specificCost =
    totalOtsSplitAndCost.specificCost + stretchOtsSplitAndCost.specificCost;
  totalOtsSplitAndCost.genericCost =
    totalOtsSplitAndCost.genericCost + stretchOtsSplitAndCost.genericCost;

  return totalOtsSplitAndCost;
}

function CostAndOtsSection() {
  const dispatch = useDispatch();

  const tgId = useSelector((state) => state.tgSpecificOts.tgId);
  const selectedTgName = useSelector((state) => state.orgTargetGroup.tgName);

  const roadStretchMap = useSelector(
    (state) => state.planningRoadStretches.roadStretchDetailsMap
  );

  // TG Specific impressions
  const tgSpecificOts = useSelector(
    (state) => state.tgSpecificOts.tgSpecificOts
  );

  //  impressions of Stretch
  const selectedStretches = useSelector(
    (state) => state.planningRoadStretches.selectedStretches
  );

  const selectedSegmentIds = useSelector(
    (state) => state.planningRoadSegments.selectedSegments
  );

  const genPriceFactor = useSelector((state) => state.campaignPlanning.genCpm);
  const tgPriceFactor = useSelector((state) => state.campaignPlanning.tgCpm);

  // Duration
  const duration = useSelector(
    (state) => state.campaignPlanning.durationInDays
  );

  // If Tg is selected...
  // if (tgId) {
  // For Road-Segments
  // TODO: we will re-use this when segments features come back
  // const segmentOtsSplitAndCost = calculateOtsSplitAndCost(
  //   selectedSegmentIds,
  //   tgSpecificOts,
  //   duration,
  //   tgPriceFactor,
  //   genPriceFactor
  // );
  const segmentOtsSplitAndCost = {
    genericImpressions: 0,
    specificImpressions: 0,
    specificCost: 0,
    genericCost: 0,
  };

  // For Road-Stretches..
  const stretchOtsSplitAndCost = calculateOtsSplitAndCost(
    selectedStretches,
    tgId && Object.keys(tgSpecificOts).length > 0
      ? tgSpecificOts
      : roadStretchMap,
    duration,
    tgPriceFactor,
    genPriceFactor
  );

  const { genericImpressions, specificImpressions, specificCost, genericCost } =
    calculateAccImpAndCost(
      segmentOtsSplitAndCost,
      stretchOtsSplitAndCost,
      duration
    );

  const setGenCpmFunction = (e) => {
    const genCpmStr = e.target.value;
    dispatch(setGenCpm(genCpmStr));
  };

  const setTgCpmFunction = (e) => {
    const tgCpmStr = e.target.value;
    dispatch(setTgCpm(tgCpmStr));
  };

  return (
    <>
      <hr className="divider my-0"></hr>
      <div className="d-flex justify-content-between p-3">
        <div className="px-2">
          <b>Target Group</b>
          {selectedTgName && <span className="d-block">{selectedTgName}</span>}
          <span className="d-block"> General</span>
        </div>

        <div className="px-2">
          <b>Est Impressions</b>
          {tgId && (
            <span className="d-block">
              {specificImpressions !== 0
                ? toStringWithRoundUp(specificImpressions)
                : "0"}
            </span>
          )}
          <span className="d-block">
            {" "}
            {genericImpressions !== 0
              ? toStringWithRoundUp(genericImpressions)
              : "0"}
          </span>
        </div>

        <div className="input-group-sm px-2">
          <b>Price Factor (%)</b>
          {tgId && (
            <input
              type="text"
              placeholder="Enter CPM"
              onChange={setTgCpmFunction}
              value={tgPriceFactor}
              className="form-control cpm-input shadow-none my-1"
            />
          )}
          <input
            type="text"
            placeholder="Enter CPM"
            onChange={setGenCpmFunction}
            value={genPriceFactor}
            className="form-control cpm-input shadow-none"
          />
        </div>

        <div className="px-2">
          <b>Est Cost</b>
          {tgId && (
            <span className="d-block">
              {"Rs. "}
              {specificCost !== 0 ? toStringWithRoundUp(specificCost) : "0"}
            </span>
          )}
          <span className="d-block">
            {"Rs. "}
            {genericCost !== 0 ? toStringWithRoundUp(genericCost) : "0"}
          </span>
        </div>
      </div>
    </>
  );
}

export default CostAndOtsSection;
