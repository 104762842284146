import {
  approxOtsForCityUrl,
  downloadCityPdfReportUrl,
  getCitySummaryUrl,
} from "../urls/CampaignCityReportURL";

// Constants and Utils
import {
  downloadFile,
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";
import { getToken } from "../utils/token";

export async function getCitySummaryData(campaignId, cityId) {
  //getting urls
  const cityOverviewUrl = getCitySummaryUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);

  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendGetRequest(cityOverviewUrl, {}, true, headerParams);
}

export async function approxMavinOts(campaignId, cityId) {
  // Request Headers
  const headerParams = {
    "Content-Type": "application/json",
  };

  return sendCreateOrUpdateRequest(
    approxOtsForCityUrl
      .replace(":campaignId", campaignId)
      .replace(":cityId", cityId),
    {},
    true,
    "PUT",
    headerParams
  );
}

export async function downloadCityPdfReportFn(campaignId, cityId) {
  const headerParams = {
    Authorization: "Bearer " + getToken(),
  };

  return downloadFile(
    downloadCityPdfReportUrl
      .replace(":campaignId", campaignId)
      .replace(":cityId", cityId),
    true,
    headerParams
  );
}
