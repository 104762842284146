//Utils
import { VideoAndPdfDownload } from "./HelpPageUtils";

// Components
import PageHeader from "../../mavin/components/page-header/PageHeader";

//Components
import HelpSidebar from "./HelpSidebar";

function SiteApproximationContent() {
  const pageContent = `
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus facilis non
      molestiae, maxime modi animi perferendis in ducimus quas dolore quo porro,
      veniam neque ab fugiat, quis vitae itaque voluptate. Lorem ipsum dolor sit
      amet consectetur adipisicing elit. Culpa labore inventore, consequatur
      aliquid omnis officia eaque. Qui iste temporibus inventore, culpa adipisci
      quasi doloremque quae, maiores libero in esse voluptate?
    `;

  function getContent() {
    const content = [];
    for (let i = 0; i <= 7; i++) {
      content.push(
        <p key={i} className="lead">
          {pageContent}
        </p>
      );
    }
    return content;
  }

  const siteApproximationContent = getContent();

  return <div>{siteApproximationContent}</div>;
}

function SiteApproximationSection() {
  return (
    <section className="px-4">
      <div>
        <SiteApproximationContent />
      </div>
    </section>
  );
}

function SiteApproximationHelpPage() {
  const pageTitle = "Site Approximation Methodologies";

  return (
    <>
      <HelpSidebar activeIdx={3} />

      <div className="content-wrapper">
        <PageHeader title={pageTitle} shadow={true} />

        <div className="page-content d-flex">
          <VideoAndPdfDownload />
          <SiteApproximationSection />
        </div>
      </div>
    </>
  );
}

export default SiteApproximationHelpPage;
