import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

//Actions
import {
  getOrgProfile,
  updateOrgProfile,
  updateOrgProfileLogo,
} from "../../../actions/org/OrgProfileActions";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

//Utils and Constants
import { ErrorMessage } from "./OrgFormUtils";
import { LoadingData } from "../OrgUtils";
import { ImageFileTypes } from "../../../constants/GeneralConstants";

//Components
import Spinner from "../../../components/spinner/Spinner";
import { selectFile } from "../../../common-utils/file-utils/FileUtils";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Pages and Sections
import OrgSettingsSidebar from "../OrgSettingsSidebar";

//CSS
import "../OrgSettings.scss";

//Validation schema
const formValidationSchema = yup.object({
  name: yup.string().required("please fill the Org Name"),
  line1: yup.string().required("please fill the Address"),
  city: yup.string().required("please fill the City"),
  state: yup.string().required("please fill the State"),
  zipcode: yup.string().required("please fill the Pincode"),
  country: yup.string().required("please fill the Country"),
  contactNo: yup
    .number()
    .typeError("Phone Number is not valid")
    .required("please fill the Phone Number"),
});

// Page Components
function FooterSection() {
  //Selector
  const isLoading = useSelector((state) => state.orgProfile.orgProfileLoading);

  return (
    <div className="mt-4 float-right">
      <Link to={RedirectTo.homeUrl}>
        <button className="btn btn-outline-primary mr-2 px-4">Cancel</button>
      </Link>
      <button
        disabled={isLoading}
        type="submit"
        className="btn btn-primary px-4"
      >
        Save {isLoading && <Spinner className="spinner-border-sm text-light" />}
      </button>
    </div>
  );
}

function UploadLogoSection({ fileSelectedHandler, loader }) {
  //Selector
  const orgLogo = useSelector((state) => state.orgProfile.orgProfileLogo);

  if (orgLogo) {
    return (
      <img
        className="org-logo mr-3"
        onClick={fileSelectedHandler}
        src={orgLogo}
        alt="org profile logo"
      ></img>
    );
  }
  return (
    <button
      onClick={fileSelectedHandler}
      className="col-sm-2 px-0 py-4 bg-white text-primary border mr-3"
      disabled={loader}
    >
      Upload Logo
    </button>
  );
}

function ProfileLogoSection() {
  //Selector
  const isLoading = useSelector(
    (state) => state.orgProfile.orgProfileLogoLoading
  );

  //Dispatch
  const dispatch = useDispatch();
  async function fileSelectedHandler() {
    const files = await selectFile(false, ImageFileTypes);

    dispatch(updateOrgProfileLogo(files));
  }
  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label">Your Logo</label>
      <div className="col-10 row align-items-center">
        <UploadLogoSection
          fileSelectedHandler={fileSelectedHandler}
          loader={isLoading}
        />
        {isLoading && <Spinner />}
        <div className="col">
          <small className="d-block">
            This logo will appear on header and email notifications.
          </small>
          <small>
            Preferred Image Size:240px X 240px @ 72 DPI Maximum size of 1MB
          </small>
        </div>
      </div>
    </div>
  );
}

function OrgNameSection({ formikValuesAndFn, inputClassName }) {
  const { values, handleChange, handleBlur, errors, touched } =
    formikValuesAndFn;

  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label">Organization Name</label>
      <div className="col-sm-10 px-0">
        <input
          className={`${inputClassName} ${
            errors.name && touched.name && "is-invalid"
          }`}
          placeholder="Enter Name"
          id="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorMessage error={errors.name} />
      </div>
    </div>
  );
}

function AddressLineOneSection({ formikValuesAndFn, inputClassName }) {
  const { values, handleChange, handleBlur, errors, touched } =
    formikValuesAndFn;

  return (
    <>
      <input
        className={`${inputClassName} ${
          errors.line1 && touched.line1 && "is-invalid"
        }`}
        placeholder="Address Line 1"
        id="line1"
        value={values.line1}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <ErrorMessage error={errors.line1} />
    </>
  );
}

function AddressLineTwoSection({ formikValuesAndFn, inputClassName }) {
  const { values, handleChange } = formikValuesAndFn;

  return (
    <input
      className={`mt-3 ${inputClassName}`}
      placeholder="Address Line 2"
      id="line2"
      value={values.line2}
      onChange={handleChange}
    />
  );
}

function CitySection({ formikValuesAndFn, inputClassName }) {
  const { values, handleChange, handleBlur, errors, touched } =
    formikValuesAndFn;

  return (
    <div className="col px-0 mr-2">
      <input
        className={`${inputClassName} ${
          errors.city && touched.city && "is-invalid"
        }`}
        id="city"
        placeholder="City"
        value={values.city}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <ErrorMessage error={errors.city} />
    </div>
  );
}

function StateSection({ formikValuesAndFn, inputClassName }) {
  const { values, handleChange, handleBlur, errors, touched } =
    formikValuesAndFn;

  return (
    <div className="col px-0 mr-2">
      <input
        className={`${inputClassName} ${
          errors.state && touched.state && "is-invalid"
        }`}
        id="state"
        placeholder="State"
        value={values.state}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <ErrorMessage error={errors.state} />
    </div>
  );
}

function PincodeSection({ formikValuesAndFn, inputClassName }) {
  const { values, handleChange, handleBlur, errors, touched } =
    formikValuesAndFn;

  return (
    <div className="col px-0">
      <input
        className={`${inputClassName} ${
          errors.zipcode && touched.zipcode && "is-invalid"
        }`}
        id="zipcode"
        placeholder="Pincode"
        value={values.zipcode}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <ErrorMessage error={errors.zipcode} />
    </div>
  );
}

function CountrySection({ formikValuesAndFn, inputClassName }) {
  const { values, handleChange, handleBlur, errors, touched } =
    formikValuesAndFn;

  return (
    <>
      <input
        className={`mt-3 ${inputClassName} ${
          errors.country && touched.country && "is-invalid"
        }`}
        placeholder="Country"
        id="country"
        value={values.country}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <ErrorMessage error={errors.country} />
    </>
  );
}

function PhoneNoSection({ formikValuesAndFn, inputClassName }) {
  const { values, handleChange, handleBlur, errors, touched } =
    formikValuesAndFn;

  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label">Phone No.</label>
      <div className="col-sm-10 px-0">
        <input
          className={`col ${inputClassName} ${
            errors.contactNo && touched.contactNo && "is-invalid"
          }`}
          placeholder="Enter Phone No."
          id="contactNo"
          value={values.contactNo}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorMessage error={errors.contactNo} />
      </div>
    </div>
  );
}

function WebsiteUrlSection({ formikValuesAndFn, inputClassName }) {
  const { values, handleChange } = formikValuesAndFn;

  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label">Website URL</label>
      <div className="col-sm-10 px-0">
        <input
          className={inputClassName}
          placeholder="Enter Website URL"
          id="website"
          value={values.website}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

function ProfileForm({ orgProfile }) {
  //Dispatch
  const dispatch = useDispatch();

  //Form validation
  const { handleSubmit, values, handleChange, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        name: orgProfile.name || "",
        line1: orgProfile.line1 || "",
        line2: orgProfile.line2 || "",
        city: orgProfile.city || "",
        state: orgProfile.state || "",
        zipcode: orgProfile.zipcode || "",
        country: orgProfile.country || "",
        contactNo: orgProfile.contactNo || "",
        website: orgProfile.website || "",
      },
      validationSchema: formValidationSchema,
      onSubmit: (orgProfileObj) => {
        dispatch(updateOrgProfile(orgProfileObj));
      },
    });
  const formikValuesAndFn = {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
  };

  //Input class name
  const inputClassName = "form-control shadow-none";

  return (
    <div className="col-10">
      {/* Logo */}
      <ProfileLogoSection />

      <form onSubmit={handleSubmit}>
        {/* Organization Name */}
        <OrgNameSection
          formikValuesAndFn={formikValuesAndFn}
          inputClassName={inputClassName}
        />

        {/* Address */}
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">Company Address</label>
          <div className="col-sm-10 px-0">
            {/* Address-1 */}
            <AddressLineOneSection
              formikValuesAndFn={formikValuesAndFn}
              inputClassName={inputClassName}
            />

            {/* Address-2 */}
            <AddressLineTwoSection
              formikValuesAndFn={formikValuesAndFn}
              inputClassName={inputClassName}
            />
            <div className="d-flex mt-3">
              {/* City */}
              <CitySection
                formikValuesAndFn={formikValuesAndFn}
                inputClassName={inputClassName}
              />

              {/* State */}
              <StateSection
                formikValuesAndFn={formikValuesAndFn}
                inputClassName={inputClassName}
              />

              {/* Pincode */}
              <PincodeSection
                formikValuesAndFn={formikValuesAndFn}
                inputClassName={inputClassName}
              />
            </div>

            {/* Country */}
            <CountrySection
              formikValuesAndFn={formikValuesAndFn}
              inputClassName={inputClassName}
            />
          </div>
        </div>

        {/* Contact No*/}
        <PhoneNoSection
          formikValuesAndFn={formikValuesAndFn}
          inputClassName={inputClassName}
        />

        {/* Website Url */}
        <WebsiteUrlSection
          formikValuesAndFn={formikValuesAndFn}
          inputClassName={inputClassName}
        />
        <hr className="mt-3 col-12" />

        {/* Submit and cancel Button */}
        <FooterSection />
      </form>
    </div>
  );
}

function ProfileSection({ orgProfile }) {
  //Selector
  const orgProfileLoader = useSelector((state) => state.orgProfile.loading);

  // Checks for profile loading
  if (orgProfileLoader) {
    return <LoadingData dataType="User Profile" />;
  }

  // Profile Form
  return <ProfileForm orgProfile={orgProfile} />;
}

/**
 * Page
 */
export default function OrgProfilePage() {
  //Selector
  const orgProfile = useSelector((state) => state.orgProfile.orgProfileInfo);

  const pageTitle = "Org :: Profile";

  //Dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrgProfile());
  }, [dispatch]);

  // Render Page
  return (
    <>
      <OrgSettingsSidebar activeIdx={1} />

      <div className="content-wrapper ">
        {/** Page Header */}
        <PageHeader title={pageTitle} shadow={true} />

        <div className="page-content">
          <ProfileSection orgProfile={orgProfile} />
        </div>
      </div>
    </>
  );
}
