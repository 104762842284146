import { Monitoring } from "../../constants/action-constants/MonitoringActionConstants";

export const getMonitoringMediaLog = (
  campaignId,
  mediaId,
  pageNumber,
  pageSizeCount
) => ({
  type: Monitoring.GET_MONITORING_MEDIA_LOG,
  payload: {
    campaignId,
    mediaId,
    pageNumber,
    pageSizeCount,
  },
});

export const downloadMonitoringMediaLogPdfReport = (campaignId, mediaId) => ({
  type: Monitoring.DOWNLOAD_MONITORING_MEDIA_LOG_PDF_REPORT,
  payload: {
    campaignId,
    mediaId,
  },
});

// for opening monitoring media log image modal
export const openMonitoringMediaLogImageModal = (processDataObject) => ({
  type: Monitoring.OPEN_MONITORING_MEDIA_LOG_IMAGE_MODAL,
  payload: { processDataObject },
});

// for closing monitoring media log image modal
export const closeMonitoringMediaLogImageModal = () => ({
  type: Monitoring.CLOSE_MONITORING_MEDIA_LOG_IMAGE_MODAL,
});

// Download monitoring media log image
export const downloadMonitoringMediaLogImage = (imageUrl) => ({
  type: Monitoring.DOWNLOAD_MONITORING_MEDIA_LOG_IMAGE,
  payload: { imageUrl },
});
