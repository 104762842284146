import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// Actions
import NestedList from "../../../components/nested-list/NestedList";
import {
  setPoiStatus,
  setSelected,
} from "../../../actions/map-view/poi/PoiActions";
import { getSelectedPoiInfo } from "../../actions/campaign-planning/PoiSelectionFormActions";
import { unSelectPoiBrands } from "../../../actions/map-view/poi/PoiSelectionActions";

// Constants
import { Status } from "../../../constants/GeneralConstants";

export function PoiSelectionForm({ cityId }) {
  // State
  const [selectedBrandIdsMap, setSelectedBrandIdsMap] = useState({});

  //poi dataList
  const dataList = useSelector((state) => state.poi.dataList);
  const brandLabelToId = useSelector((state) => state.poi.brandLabelToId);
  const brandIdToLabel = useSelector((state) => state.poi.brandIdToLabel);
  const sectorLabelToId = useSelector((state) => state.poi.sectorLabelToId);
  const sectorToBrandMap = useSelector((state) => state.poi.sectorToBrandMap);

  // Dispatch
  const dispatch = useDispatch();

  if (dataList.length < 1) {
    return null;
  }

  function actionOnAddButtonSelect(selectedIndex, label) {
    dispatch(setSelected(selectedIndex, label));
  }

  function actionOnAddButton(label, status) {
    dispatch(setPoiStatus(label, status));
  }

  function appendBrandId(brandId, status) {
    setSelectedBrandIdsMap({
      ...selectedBrandIdsMap,
      [brandId]: {
        value: status === Status.CHECKED,
        count: selectedBrandIdsMap[brandId]
          ? selectedBrandIdsMap[brandId]["count"] + 1
          : 1,
      },
    });
  }

  function actionOnAddButtonStatus(selectedIndex, label, status) {
    const brandId = brandLabelToId[label];

    // If label have respective brandId
    if (brandId) {
      appendBrandId(brandId, status);
      return;
    }

    // Else get brandIds from the sectorId
    const sectorId = sectorLabelToId[label];
    const brandIds = sectorToBrandMap[sectorId];
    brandIds.forEach((eachBrandId) => appendBrandId(eachBrandId, status));
  }

  function onConfirmAction() {
    const unSelectedBrandIds = [];
    const selectedBrandIds = [];

    for (const eachBrandId in selectedBrandIdsMap) {
      selectedBrandIdsMap[eachBrandId]["value"]
        ? selectedBrandIds.push(eachBrandId)
        : unSelectedBrandIds.push(eachBrandId);
    }

    dispatch(unSelectPoiBrands(unSelectedBrandIds));
    dispatch(getSelectedPoiInfo(cityId, selectedBrandIds));
    setSelectedBrandIdsMap({});
  }

  function onCancelAction() {
    Object.keys(selectedBrandIdsMap).forEach((eachBrandId) => {
      const valueObject = selectedBrandIdsMap[eachBrandId];

      // We are ignoring the case where user performs same action like
      // (select and unselect) or (unselect and select) where the state
      // does not change
      if (valueObject["count"] % 2 !== 0) {
        dispatch(
          setPoiStatus(
            brandIdToLabel[eachBrandId],
            valueObject["value"] ? Status.UNCHECKED : Status.CHECKED
          )
        );
      }
    });
    setSelectedBrandIdsMap({});
  }

  return (
    <div className="modal fade" id="poi-selection-form" data-backdrop="static">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          {/* Form Header */}
          <div className="modal-header bg-alt px-5">
            <h4 className="modal-title">{"Poi Selection"}</h4>
          </div>

          {/* Form Body */}
          <div className="modal-body tg-form-body px-5">
            <NestedList
              data={dataList}
              setSelected={actionOnAddButtonSelect}
              setAdded={actionOnAddButton}
              setAddButtonStatus={actionOnAddButtonStatus}
            />
          </div>

          {/* Form Footer */}
          {/* TODO: Use Buttons components here */}
          <div className="modal-footer px-5">
            <button
              type="button"
              className="btn btn-outline-primary btn-action mt-2 mr-3 rounded-lg"
              data-dismiss="modal"
              onClick={onCancelAction}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary btn-action mt-2 rounded-lg"
              data-dismiss="modal"
              onClick={onConfirmAction}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
