import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router";
import { Marker, Popup } from "react-leaflet";

//Actions
import {
  downloadMapImage,
  getMapImage,
} from "../../../actions/citySummary/MapViewImageActions";

// Constants and Utils
import { getMediaIcon } from "../../../utils/MediaUtils";
import { MapZoom, MediaColor } from "../../../constants/GeneralConstants";
import { constructRedirectPath } from "../../../utils/redirect-utils/RediectUtil";

// Url
import { RedirectTo } from "../../../urls/PageLinksURL";

//Components
import Spinner from "../../../components/spinner/Spinner";
import LLMap from "../../../components/map/leaflet-map/LLMap";

function HeaderSection() {
  return (
    <div className="px-4 pt-3 pb-2 border-bottom">
      <div className="row">
        <div className="col-12">
          <p className="mb-0">
            <strong>Points of Interest</strong> within 5 kms of sites
          </p>
          <p>Corporate Hubs: 6 | 5 star hotels: 0 | Metro: 3 | Junctions: 7</p>
        </div>
      </div>
    </div>
  );
}

function DownloadMapImage() {
  //Dispatch
  const dispatch = useDispatch();

  //Selector
  const imageUrl = useSelector((state) => state.mapViewImage.imageUrl);
  const imageDownloading = useSelector((state) => state.cities.imgDownloading);

  //Checks for image loading
  if (imageDownloading) {
    return <Spinner />;
  }
  return (
    <button
      className="btn btn-outline-primary"
      data-html2canvas-ignore="true"
      onClick={() => dispatch(downloadMapImage(imageUrl))}
    >
      Download image
    </button>
  );
}

function Footer({ campaignId, cityId }) {
  // Url
  const campaignMapViewPageUrl = RedirectTo.campaignMapViewPageUrl
    .replace(":campaignId", campaignId)
    .replace(":cityId", cityId);

  return (
    <div className="px-4 py-3 border-top d-flex justify-content-end">
      <div>
        {/* <DownloadMapImage /> */}
        <Link
          data-html2canvas-ignore="true"
          to={constructRedirectPath(campaignMapViewPageUrl)}
          className="btn btn-outline-primary ml-3"
        >
          Edit map view
        </Link>
      </div>
    </div>
  );
}

function CityMapView({ center, mediaList }) {
  if (Object.keys(center).length === 0) {
    return null;
  }

  const { latitude, longitude } = center;
  // CampaignMedia Marker Color
  const campaignMediaIcon = getMediaIcon(MediaColor.campaign);

  return (
    <LLMap
      center={[latitude, longitude]}
      zoom={MapZoom.zoomLevel9}
      scrollWheelZoom={false}
    >
      {mediaList.map((media) => (
        <Marker
          key={media.id}
          position={[media.latitude, media.longitude]}
          icon={campaignMediaIcon}
        >
          <Popup>
            <b>{media.title}</b>
          </Popup>
        </Marker>
      ))}
    </LLMap>
  );
}

function MapViewImage({ imageUrl, center, mediaList }) {
  if (imageUrl === "NoMapImage") {
    return <CityMapView mediaList={mediaList} center={center} />;
  }

  // NOTE: We are appending timeStamp and crossOrigin parameter to solve the
  // Issue of loading svg images from server (AWS) in chrome..
  // Reference : https://github.com/niklasvh/html2canvas/issues/154
  // TODO:: code is working without below return statement also , but keeping it for future reference
  // return (
  //   <img
  //     className="map-img-cont img-fluid"
  //     src={imageUrl + "?time=" + new Date().valueOf()}
  //     crossOrigin=""
  //   />
  // );
  return (
    <img className="map-img-cont img-fluid" src={imageUrl} crossOrigin="" />
  );
}

function CampaignCityMapView({ mediaList }) {
  const { id, cityId } = useParams();

  //Selector
  const { summary = {} } = useSelector(
    (state) => state.campaignCityReport.citySummary
  );
  const center = summary.cityCenter || {};
  const imageUrl = useSelector((state) => state.mapViewImage.imageUrl);

  //Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMapImage(id, cityId));
  }, [dispatch, id, cityId]);

  return (
    <div className="mt-5">
      <h4 className="sec-title">Map view with points of interests</h4>
      <div className="card mt-4">
        {/* Map header */}
        <HeaderSection />

        <div className="map-img-cont bg-alt">
          {/* Map image */}
          <MapViewImage
            imageUrl={imageUrl}
            center={center}
            mediaList={mediaList}
          />
        </div>
        {/* Map footer */}
        <Footer campaignId={id} cityId={cityId} />
      </div>
    </div>
  );
}

export default CampaignCityMapView;
