import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Actions
import { createCampaign } from "../../../../actions/campaign/CampaignActions";
import { openMultiCitySelectionForm } from "../../../../actions/campaign-planning/MultiCitySelectionFormActions";
import { getRegionNames } from "../../../../actions/regions/RegionActions";
import { getTargetGroups } from "../../../../actions/org/OrgTargetGroupActions";

// Constants
import { defaultPagination } from "../../../../constants/UrlConstants";
import { FormDataTargets } from "../../../../constants/GeneralConstants";

// Components
import { Input } from "../../../../components/form-input/FormInput";
import Spinner from "../../../../components/spinner/Spinner";
import MultiCitySelectionForm from "../../../../mavin/components/multi-city-selection-form/MultiCitySelectionForm";
import BootstrapDateRangePicker from "../../../../components/bootstrap-date-range-picker/BootstrapDateRangePicker";
import DurationSelector from "../../../../mavin/components/duration-selector/DurationSelector";
import { PlainDropdown } from "../../../../components/dropdown/Dropdown";

// CSS
import "../../../../pages/campaign-planning/CampaignPlanning.scss";

// Page Components
function PageHeader() {
  return (
    <div className="page-header shadow">
      <h2 className="">{"New Campaign"}</h2>
    </div>
  );
}

function CampaignTitleSection({ title, setTitle }) {
  return (
    <div className="py-3">
      <h5>{"Campaign Title"}</h5>
      <Input
        type="text"
        placeHolder="Enter title"
        inputBoxStyle="rounded-lg form-control shadow-none"
        id={"title"}
        value={title}
        onChange={(id, value) => {
          setTitle(value);
        }}
      />
    </div>
  );
}

function StartDateAndDurationSection({
  startDate,
  setStartDate,
  selectDuration,
}) {
  // Style for DatePicker
  const stylesObject = {
    autoApply: true,
    border: true,
    buttonClassName: "col-12 px-0",
  };

  return (
    <div className="d-flex py-3">
      <div className="col-6 pl-0">
        <h5>{"Est. Start Date"}</h5>
        {/* Single-Date-Selector ==> isSingleDatePicker={true} */}
        <BootstrapDateRangePicker
          isSingleDatePicker={true}
          initialStartDate={startDate}
          onApplyDates={setStartDate}
          minDate={new Date()}
          placeHolder={"Select Date"}
          styleObject={stylesObject}
        />
      </div>
      <div className="col-6 pr-0">
        <h5>{"Duration"}</h5>
        <DurationSelector
          onItemSelect={selectDuration}
          startDate={startDate}
          buttonClassName="shadow-none col px-2"
        />
      </div>
    </div>
  );
}

function TargetGroupAndBudgetSection({ tgList, setTg }) {
  return (
    <div className="d-flex py-3">
      <div className="col-6 pl-0">
        <h5>{"Target Group"}</h5>
        <PlainDropdown
          items={tgList}
          className="btn shadow-none rounded-lg border"
          placeHolder="Select Target Group"
          onItemSelect={setTg}
          dropMenuClassName="fixed-height"
        />
      </div>
      {/* TODO: NEW -Feature not implemented yet (ignore for Now)*/}

      {/* <div className="col-6 pr-0">
        <h5>{"Budget"}</h5>
        <Input
          type="number"
          placeHolder="Enter the Budget"
          inputBoxStyle="rounded-lg form-control shadow-none"
        />
      </div> */}
    </div>
  );
}

function PageFooterSection({ handleSubmit, disable }) {
  const createCampaignLoader = useSelector(
    (state) => state.campaign.createCampaignLoader
  );

  return (
    <div className="d-flex justify-content-end mt-4">
      <button
        disabled={disable || createCampaignLoader}
        onClick={handleSubmit}
        className="btn btn-primary rounded-lg"
      >
        {"Create Campaign"}
        {createCampaignLoader && (
          <Spinner className="spinner-border-sm text-light mx-1" />
        )}
      </button>
    </div>
  );
}

function AddCitiesSection({ infoByCity, setInfoByCity }) {
  const dispatch = useDispatch();

  // removing city from selected cities from Popup
  function removeSelectedCityFn(e, city) {
    if (e.target.checked) {
      setInfoByCity([...infoByCity, city]);
    } else if (!e.target.checked) {
      const updatedCityData = infoByCity.filter(
        (cityObj) => cityObj.id !== city.id
      );
      setInfoByCity(updatedCityData);
    }
  }

  return (
    <>
      <div className="d-flex py-3">
        <h5>{"Cities:"}</h5>
        <div className="px-4">
          <button
            type="button"
            className="btn btn-link shadow-none p-0"
            data-toggle="modal"
            data-target={`#${FormDataTargets.multiCitySelectionForm}`}
            onClick={() => dispatch(openMultiCitySelectionForm())}
          >
            {"+ Add Cities"}
          </button>
        </div>
      </div>

      {/* Selected Cities Section */}
      {infoByCity.length > 0 && (
        <div className="row mx-0">
          {infoByCity.map((city) => (
            <button
              key={city.id}
              className="btn border m-1 px-3 shadow-none rounded-lg"
            >
              <input
                type="checkbox"
                className="cursor-pointer"
                checked={true}
                onChange={(e) => removeSelectedCityFn(e, city)}
              />
              <label className="pl-1 mb-0">{city.label}</label>
            </button>
          ))}
        </div>
      )}
    </>
  );
}

/**
 * Page
 */
export default function CampaignPlanSetupPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  // State
  const [title, setTitle] = useState("");
  const [infoByCity, setInfoByCity] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tgId, setTgId] = useState("");

  // Selector State
  const regionsName = useSelector((state) => state.region.regionsName) || [];
  const regionsNameLoading = useSelector((state) => state.region.namesLoading);

  // targetgroup list
  const tgList = useSelector((state) => state.orgTargetGroup.tgTitles) || [];

  // for "MULTIPLE-City-Selection-form"
  const openMultiCitySelectionForm = useSelector(
    (state) => state.multiCitySelectionModal.openModal
  );
  // Pagination
  const pageNo = defaultPagination.pageNo,
    pageSize = defaultPagination.pageSize;

  // Dispatch
  useEffect(() => {
    dispatch(getRegionNames());
    dispatch(getTargetGroups(true, "", pageNo, pageSize));
  }, [dispatch]);

  // Functions
  function selectDuration(duration) {
    const date = new Date(startDate);
    setEndDate(new Date(date.setDate(date.getDate() + duration - 1)));
  }

  // Create Campaign Submit Button
  function handleSubmit() {
    dispatch(
      createCampaign({ infoByCity, title, startDate, endDate, tgId }, history)
    );
  }

  // Loading
  if (regionsNameLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  return (
    <div className="content-wrapper">
      {/* Page- header */}
      <PageHeader />

      <div className="page-content">
        <div className="row">
          <div className="col-6">
            {/* Campaign-title */}
            <CampaignTitleSection title={title} setTitle={setTitle} />

            {/* StartDate and Duration */}
            <StartDateAndDurationSection
              startDate={startDate}
              setStartDate={setStartDate}
              selectDuration={selectDuration}
            />

            <TargetGroupAndBudgetSection tgList={tgList} setTg={setTgId} />
            {/* Selected Cities Container */}
            <AddCitiesSection
              infoByCity={infoByCity}
              setInfoByCity={setInfoByCity}
            />
            <hr className="divider"></hr>

            {/* Page-Footer Section */}
            <PageFooterSection
              handleSubmit={handleSubmit}
              disable={
                !title ||
                (startDate && !endDate) ||
                infoByCity.length == 0 ||
                !tgId
              }
            />
          </div>
        </div>
      </div>

      {/* MODALS */}
      {openMultiCitySelectionForm && (
        <MultiCitySelectionForm
          regionsName={regionsName}
          onSubmit={setInfoByCity}
          citiesList={infoByCity}
        />
      )}
    </div>
  );
}
