// Constants and Utils
import { constructDateString } from "../../../../common-utils/date-utils/DateUtils";
import { DATE_FORMATS } from "../../../../constants/GeneralConstants";

// Components
import { DefaultImage } from "../../../../components/campaign-media-table-row/MediaRow";
import MapIcon from "../../../../components/map/map-icon/MapIcon";

/**
 *  Function which converts each heatMap point to respective marker
 * @param {*} dataPoints
 * @returns
 */
export function constructPoiMarkers(dataPoints) {
  if (!dataPoints) {
    return [];
  }

  return dataPoints.map((dp) => ({
    id: dp.id,
    type: dp.type,
    markerText: dp.name,
    position: [dp.center.latitude, dp.center.longitude],
  }));
}

// IMAGE
function getPopupMediaImage(media) {
  const imageUrl = media?.fileItems[0]?.thumbnailUrl || "";

  if (!imageUrl) {
    return <DefaultImage />;
  }

  return <img src={imageUrl} alt="thumbnail-image" />;
}

function constructMediaInfo(media) {
  const {
    latitude,
    longitude,
    title,
    type,
    width,
    height,
    litStatus,
    region,
    startTimestamp,
    endTimestamp,
  } = media;

  const position = [latitude, longitude];
  const dimentions = `${width}ft x ${height}ft | ${litStatus}`;

  const image = getPopupMediaImage(media);

  const duration = constructDateString(
    startTimestamp,
    endTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );

  const icon = new MapIcon({ type: "selected-media" });

  return { title, position, dimentions, type, image, region, duration, icon };
}

function constructImpressionsInfo(roadStretchOts) {
  const { ots, otsLit, genericOts, genericOtsLit, targetOts, targetOtsLit } =
    roadStretchOts;

  const estGenericOts = genericOtsLit ?? genericOts;
  const estTargetOts = targetOtsLit ?? targetOts;
  const estOts = ots ?? otsLit;
  return { genericOts: estGenericOts ?? estOts, targetOts: estTargetOts ?? 0 };
}

export function constructMediaMarker(media, roadStretchOts, roadStretch) {
  const { name: roadStretchName } = roadStretch;
  const mediaInfo = constructMediaInfo(media);
  const impressionsInfo = constructImpressionsInfo(roadStretchOts);
  return { roadStretchName, mediaInfo, impressionsInfo };
}
