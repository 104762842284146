/**
 * Default Top Header Branding for all private/public pages
 */
import React from "react";
import { Link } from "react-router-dom";

// Theme
import AppThemes from "../../theme/AppThemes";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Constants and Utils
import { constructRedirectPath } from "../../utils/redirect-utils/RediectUtil";
import { getItem } from "../../utils/localstorage";
import { LocalStorageKeys } from "../../constants/GeneralConstants";
import { useUrlKeyCheck } from "../../mavin/utils/hooks/HooksUtil";
import temporaryLogo from "../../theme/mantaray/temporary-logo.png";

// Component Definition
// ----------------------------------------------------------------------------

/**
 * Header :: Branding
 */
const NavbarBranding = () => {
  // Theme Info
  const theme = AppThemes.getTheme();
  const homeUrl = RedirectTo.homeUrl;

  // checking for  mantartay product id
  const isMantarySpecific = useUrlKeyCheck("/m/");

  // getting "User" from localStorage
  const user = JSON.parse(getItem(LocalStorageKeys.USER)) || {};
  const { orgId = "" } = user;

  const isTemporaryLogo =
    !isMantarySpecific && orgId === "64e313ae5cefce620f7de77b";

  // Temporary logo for bjp campaign for Mantaray OrgId
  if (isTemporaryLogo) {
    return (
      <Link to={constructRedirectPath(homeUrl)}>
        <img src={temporaryLogo} className="temporary-logo" />
      </Link>
    );
  }

  return (
    <div className="main-branding">
      <Link className="navbar-brand" to={constructRedirectPath(homeUrl)} />
    </div>
  );
};

export default NavbarBranding;
