import { useState } from "react";

// Constants
import {
  DefaultFilterObjects,
  MediaType as MediaTypeFilters,
} from "../../constants/GeneralConstants";

// Components
function Dimension({ label, setMaxValue, setMinValue, maxValue, minValue }) {
  return (
    <div className="form-group row align-items-center">
      <span className="col-3 col-form-label pt-0">{label}</span>

      <input
        className="form-control col-3 px-1 ml-1 rounded-lg"
        placeholder={"Min (ft.)"}
        value={minValue || ""}
        onChange={({ target }) => setMinValue(target.value)}
      />

      <span className="col-1 text-center px-0">{"-"}</span>

      <input
        className="form-control col-3 px-2 rounded-lg"
        placeholder={"Max (ft.)"}
        value={maxValue || ""}
        onChange={({ target }) => setMaxValue(target.value)}
      />
    </div>
  );
}

function Impressions({ impressionStr, setImpression }) {
  return (
    <div className="form-group row align-items-center">
      <span className="col-3 col-form-label pt-0">Impressions</span>
      <input
        className="ml-1 form-control col-7 pr-0 pl-1 rounded-lg"
        value={impressionStr || ""}
        placeholder="Enter minimum impressions"
        onChange={({ target }) => setImpression(target.value)}
      />
    </div>
  );
}

function Orientation({ orientationArr, setOrientation }) {
  const orientationInfo = [
    {
      iconClass: "fa-image ml-2",
      value: "LANDSCAPE",
      label: "Landscape",
    },
    {
      iconClass: "fa-image ml-3",
      value: "SQUARE",
      label: "Square",
    },
    {
      iconClass: "fa-image ml-3",
      value: "PORTRAIT",
      label: "Portrait",
    },
  ];

  function handleOrientation({ target }) {
    const { value } = target;

    if (target.checked) {
      return setOrientation((prev) => [...prev, value]);
    }

    const updatedMediaType = orientationArr.filter((item) => item !== value);
    setOrientation(updatedMediaType);
  }

  return (
    <div className="form-group row ">
      <span className="col-3 col-form-label pt-0">Orientation</span>
      {orientationInfo.map((info) => (
        <div className="form-check pl-0 mr-1" key={info.label}>
          <input
            className="form-check-input mx-1"
            type="checkbox"
            id={info.label}
            value={info.value}
            checked={orientationArr.includes(info.value)}
            onChange={handleOrientation}
          />
          <label className="form-check-label" htmlFor={info.label}>
            {info.label}
          </label>
        </div>
      ))}
    </div>
  );
}

function LitStatus({ litStatusArr, setLitStatus }) {
  const litStatusInfo = [
    {
      label: "Front Lit",
      value: "FRONT_LIT",
      className: "col-3",
    },
    {
      label: "Back Lit",
      value: "BACK_LIT",
      className: "col-3",
    },
    {
      label: "Digital",
      value: "DIGITAL",
      className: "col-3",
    },
    {
      label: "Not Lit",
      value: "NOT_LIT",
      className: "col-3",
    },
  ];

  function handleLitStatus({ target }) {
    const { value } = target;

    if (target.checked) {
      return setLitStatus((prev) => [...prev, value]);
    }

    const updatedMediaType = litStatusArr.filter((item) => item !== value);
    setLitStatus(updatedMediaType);
  }

  return (
    <div className="form-group row">
      <span className="col-3 col-form-label py-0">Lit status</span>

      <div className={`row ml-1`}>
        {litStatusInfo.map((info) => (
          <div key={info.value}>
            <input
              type="checkbox"
              className="mr-2 mb-2"
              value={info.value}
              id={info.value}
              checked={litStatusArr.includes(info.value)}
              onChange={handleLitStatus}
            ></input>
            <label className="mr-3" htmlFor={info.value}>
              {info.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

function MediaFilterFooter({ handleFilter, closeFilter }) {
  return (
    <div className="d-flex mr-2 py-2">
      <button className="btn btn-outline-primary ml-auto" onClick={closeFilter}>
        Cancel
      </button>
      <button
        className="btn btn-primary ml-3"
        data-dismiss="modal"
        onClick={handleFilter}
      >
        Apply filters
      </button>
    </div>
  );
}

function MediaType({ mediaTypesArr, setMediaTypes }) {
  function selectMediaType({ target }) {
    const { value } = target;

    if (target.checked) {
      return setMediaTypes((prev) => [...prev, value]);
    }

    const updatedMediaType = mediaTypesArr.filter((item) => item !== value);
    setMediaTypes(updatedMediaType);
  }

  return (
    <div className="border-right">
      <p className="font-weight-bold pb-0">Media types</p>
      {Object.entries(MediaTypeFilters).map((eachFilter) => (
        <div key={eachFilter[0]}>
          <input
            type="checkbox"
            id={eachFilter[0]}
            className="mr-2 mb-2"
            value={eachFilter[0]}
            checked={mediaTypesArr.includes(eachFilter[0])}
            onChange={selectMediaType}
          ></input>
          <label htmlFor={eachFilter[0]}>{eachFilter[1]}</label>
        </div>
      ))}
    </div>
  );
}

function MediaFilterLocal({ className, onFiltersApplied, mediaFilterObj }) {
  // DefaultValues Of All
  const {
    mediaTypes,
    litStatus,
    orientation,
    impressions,
    maxHeight,
    minHeight,
    maxWidth,
    minWidth,
  } = mediaFilterObj;

  // States
  const [mediaTypesArr, setMediaTypes] = useState(mediaTypes);

  const [litStatusArr, setLitStatus] = useState(litStatus);
  const [orientationArr, setOrientation] = useState(orientation);
  const [impressionStr, setImpression] = useState(impressions);

  const [maxHeightStr, setMaxHeight] = useState(maxHeight);
  const [minHeightStr, setMinHeight] = useState(minHeight);

  const [maxWidthStr, setMaxWidth] = useState(maxWidth);
  const [minWidthStr, setMinWidth] = useState(minWidth);

  // prevent filter close
  function preventClose(e) {
    e.stopPropagation();
  }

  const filters = {
    litStatus: litStatusArr,
    orientation: orientationArr,
    impressions: impressionStr,
    maxHeight: maxHeightStr,
    minHeight: minHeightStr,
    maxWidth: maxWidthStr,
    minWidth: minWidthStr,
    mediaTypes: mediaTypesArr,
  };

  function handleFilter() {
    onFiltersApplied(filters);
  }

  function closeFilter() {
    // this remove applied filter
    onFiltersApplied(DefaultFilterObjects.media);

    // TODO :: is there is any better way to clear the state
    setLitStatus([]);
    setOrientation([]);
    setImpression("");
    setMaxHeight("");
    setMinHeight("");
    setMaxWidth("");
    setMinWidth("");
    setMediaTypes([]);
  }

  return (
    <div className="position-relative">
      <button
        data-target={"btnGroupDrop4"}
        type="button"
        data-toggle="dropdown"
        className={`btn btn-outline-secondary ${className}`}
      >
        Filter<i className="fas fa-sliders-h ml-3"></i>
      </button>

      {/* Body */}
      <div
        style={{ width: "700px" }}
        className="dropdown-menu dropdown-menu-right"
      >
        <div className="container-fluid" onClick={preventClose}>
          {/* Content */}
          <div className="row">
            <div className="col-4">
              <MediaType
                mediaTypesArr={mediaTypesArr}
                setMediaTypes={setMediaTypes}
              />
            </div>

            <div className="col-8">
              <p className="font-weight-bold pb-0">Attributes</p>
              <LitStatus
                litStatusArr={litStatusArr}
                setLitStatus={setLitStatus}
              />
              <Orientation
                orientationArr={orientationArr}
                setOrientation={setOrientation}
              />
              <Dimension
                label={"Height"}
                maxValue={maxHeightStr}
                setMaxValue={setMaxHeight}
                minValue={minHeightStr}
                setMinValue={setMinHeight}
              />
              <Dimension
                label={"Width"}
                maxValue={maxWidthStr}
                setMaxValue={setMaxWidth}
                minValue={minWidthStr}
                setMinValue={setMinWidth}
              />
              <Impressions
                impressionStr={impressionStr}
                setImpression={setImpression}
              />
            </div>
          </div>
        </div>

        <hr className="my-2" />

        {/* Footer */}
        <MediaFilterFooter
          handleFilter={handleFilter}
          closeFilter={closeFilter}
        />
      </div>
    </div>
  );
}

export default MediaFilterLocal;
