import React from "react";

//Utils and Constants
import { formatText } from "../../../common-utils/string-utils/StringUtils";
import { constructSingleDateString } from "../../../common-utils/date-utils/DateUtils";
import { DATE_FORMATS } from "../../../constants/GeneralConstants";
import { getOtsTotal } from "../../../utils/mavinMetrics";
// Components
import { getLtsRatingAvg } from "../../../components/campaign-table-row/CampaignRowCells";

// Section Components
function CampaignPerformance({ campaignDuration, startDate }) {
  return (
    <div className=" mb-4">
      <h4 className="sec-title">Campaign Performance</h4>
      <div className="">
        <p className="mb-0">
          <em className="text-small">
            Estimated for a {campaignDuration} days duration starting{" "}
            <strong>{startDate}</strong>
          </em>
        </p>
        <p className="mt-0 mb-2">
          <em className="text-small">
            For accurate performance details please enter campaign start date
            and duration at a city level or site level.
          </em>
        </p>
      </div>
    </div>
  );
}

function MavinStats({ statDisplayTitle, statDisplayValue }) {
  return (
    <div className="col">
      <div className="card h-100">
        <div className="card-body">
          <h4 className="card-title">{statDisplayTitle}</h4>
          <div className="card-text">{statDisplayValue}</div>
        </div>
      </div>
    </div>
  );
}

/**
 * Page Section
 */
function OverviewSection({ campaign = {}, summary = {} }) {
  const { startTimestamp } = campaign;
  const { duration: campaignDuration, ltsRatingAvg } = summary;

  const startDate = constructSingleDateString(
    startTimestamp,
    DATE_FORMATS.full_month_with_date_year
  );
  const ltsAvg = getLtsRatingAvg(ltsRatingAvg);

  const { otsTotal, tgOtsTotal } = getOtsTotal(summary) || {};

  const mavinStats = {
    "No. of Sites": formatText(summary.mediaCount),
    Impressions: (
      <>
        <p>Tg : {formatText(tgOtsTotal)}</p>
        <p>Total : {formatText(otsTotal)}</p>
      </>
    ),
    "Avg LTS rating": formatText(ltsAvg),
  };

  return (
    <>
      {/* Campaign Performance */}
      <CampaignPerformance
        campaignDuration={campaignDuration}
        startDate={startDate}
      />

      {/*Stats Card */}
      <div className="row">
        {Object.keys(mavinStats).map((eachKey, index) => (
          <MavinStats
            statDisplayTitle={eachKey}
            statDisplayValue={mavinStats[eachKey]}
            key={index}
          />
        ))}
      </div>
    </>
  );
}

export default OverviewSection;
