import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Actions
import { closeMultiCitySelectionForm } from "../../../actions/campaign-planning/MultiCitySelectionFormActions";

// Constants and Utils
import {
  FormDataTargets,
  topCitiesNames,
} from "../../../constants/GeneralConstants";

// Page-Components
function SearchCitySection({
  regionsName,
  setSelectedCities,
  selectedCities,
  disabledCities,
}) {
  // Array of "All-Suggested-Cities" from Input-Search
  const [suggestedCities, setSuggestedCities] = useState([]);

  // Input Search Text
  const [searchText, setSearchText] = useState("");

  // Array of "Search-Selected" and "Listing-Selected" Cities
  const [listingAndSearchSelectedCities, setListingAndSearchSelectedCities] =
    useState([]);

  //Dispatch
  useEffect(() => {
    setListingAndSearchSelectedCities(selectedCities);
  }, [selectedCities.length]);

  // Functions
  // Search City function for Input
  function inputSearchCityFn(e) {
    const searchText = e.target.value;
    setSearchText(searchText);

    if (searchText.length <= 2) {
      setSuggestedCities([]);
      return;
    }

    const suggestion = regionsName.filter((cityObj) =>
      cityObj.label.toLowerCase().includes(searchText.toLowerCase())
    );
    setSuggestedCities(suggestion);
  }

  // function after selecting city from suggestions
  function suggestedCitySelectFn(city) {
    // To remove search text after selecting a city from suggested list
    setSearchText("");

    // checking condition if selected city from suggestion is Duplicate
    if (selectedCities.some((cityObj) => cityObj.id === city.id)) {
      setSuggestedCities([]);
      return;
    }

    // if not-duplicate then Adding City
    setSelectedCities([...selectedCities, city]);
    setListingAndSearchSelectedCities([
      ...listingAndSearchSelectedCities,
      city,
    ]);
    setSuggestedCities([]);
  }

  // remove Selected-City function
  function removeSelectedCityFn(e, city) {
    const updatedCities = listingAndSearchSelectedCities.filter(
      (cityObj) => cityObj.id !== city.id
    );
    setListingAndSearchSelectedCities([...updatedCities]);
    setSelectedCities([...updatedCities]);
  }

  return (
    <>
      <input
        type="search"
        className="form-control rounded-lg"
        value={searchText}
        placeholder={"Search Or Select City from below list"}
        onChange={inputSearchCityFn}
      />

      {/* Suggested Cities Dropdown-menu */}
      {suggestedCities.length > 0 && (
        <div className="border rounded-lg mt-1 bg-alt">
          {suggestedCities.slice(0, 5).map((city) => (
            <li
              key={city.id}
              className="dropdown-item cursor-pointer"
              onClick={() => suggestedCitySelectFn(city)}
            >
              {city.label}
            </li>
          ))}
        </div>
      )}

      {/* Selected cities from Search */}
      {listingAndSearchSelectedCities.length > 0 && (
        <div className="row mx-0 mt-1">
          {listingAndSearchSelectedCities.map((city) => (
            <button
              key={city.id}
              className="btn border m-1 px-3 shadow-none rounded-lg"
            >
              <input
                type="checkbox"
                className="cursor-pointer"
                checked={true}
                disabled={disabledCities.includes(city.id)}
                onChange={(e) => removeSelectedCityFn(e, city)}
              />
              <label className="pl-1 mb-0">{city.label}</label>
            </button>
          ))}
        </div>
      )}
    </>
  );
}

function CityListingSection({
  citiesToShowInListing,
  setSelectedCities,
  selectedCities,
  disabledCities,
}) {
  // checkedIds Array (if any selected)
  const checkedCityIds = selectedCities.map((cityObj) => cityObj.id);

  // Function
  function selectCityFromListingFn(e, city) {
    if (e.target.checked) {
      // Adding City if "Checked=true"
      setSelectedCities([...selectedCities, city]);
    } else {
      // Updating Cities by Removing City of "Checked=false"
      const updatedCheckedCities = selectedCities.filter(
        (cityObj) => cityObj.id !== city.id
      );
      setSelectedCities(updatedCheckedCities);
    }
  }

  return (
    <div className="d-flex flex-wrap">
      {citiesToShowInListing.map((city) => (
        <div key={city.id} className="p-2 col-3">
          <input
            type="checkbox"
            id={city.id}
            name={city.label}
            className="cursor-pointer"
            onChange={(e) => selectCityFromListingFn(e, city)}
            checked={checkedCityIds.includes(city.id) ? true : false}
            disabled={disabledCities.includes(city.id)}
          />
          <label className="mb-0 px-2" htmlFor={city.id}>
            {city.label}
          </label>
        </div>
      ))}
    </div>
  );
}

/**
 * Form Modal for "Multi-Cities-Selection"
 */
function MultiCitySelectionForm({
  regionsName, // all cities Names
  onSubmit,
  citiesList,
  disabledCities = [], // list of "cities" which are "already" in "Campaign"
}) {
  const dispatch = useDispatch();
  // State
  // "selectedCities" ==> array of all "Selected-Cities" from Form
  const [selectedCities, setSelectedCities] = useState([]);

  //Dispatch
  useEffect(() => {
    setSelectedCities(citiesList);
  }, [citiesList.length]);

  // Function
  // updating "infoByCity" with Selected Cities
  function submitCitySelectionFormFn() {
    onSubmit(selectedCities);
  }

  return (
    <div
      className="modal fade"
      id={FormDataTargets.multiCitySelectionForm}
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          {/* Form Header */}
          <div className="modal-header bg-alt px-5">
            <h4 className="modal-title">{"Select City"}</h4>
          </div>

          {/* Form body */}
          <div className="modal-body px-5">
            <SearchCitySection
              regionsName={regionsName}
              setSelectedCities={setSelectedCities}
              selectedCities={selectedCities}
              disabledCities={disabledCities}
            />
            <hr className="divider"></hr>
            <CityListingSection
              citiesToShowInListing={topCitiesNames} // hardcoded 40 cities from constant
              setSelectedCities={setSelectedCities}
              selectedCities={selectedCities}
              disabledCities={disabledCities}
            />
          </div>

          {/* Form Footer */}
          <div className="modal-footer px-5">
            <button
              type="button"
              className="btn btn-outline-primary btn-action mt-2 mr-3 rounded-lg"
              data-dismiss="modal"
              onClick={() => dispatch(closeMultiCitySelectionForm())}
            >
              {"Cancel"}
            </button>
            <button
              type="button"
              className="btn btn-primary btn-action mt-2 rounded-lg"
              data-dismiss="modal"
              onClick={submitCitySelectionFormFn}
            >
              {"Confirm"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultiCitySelectionForm;
