import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { openConfirmationForm } from "../../../actions/ConfirmationFormActions";

// Constants and Utils
import { FormDataTargets } from "../../../constants/GeneralConstants";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import Pagination from "../../../components/pagination/Pagination";
import {
  InfoCell,
  ImpressionsCell,
  LtsCell,
} from "../../../components/campaign-media-table-row/MediaRow";
import Spinner from "../../../components/spinner/Spinner";

// Table Header Constants
export const tableHeaders = [
  {
    title: {
      displayName: "Site Name",
      className: "col-6 align-top",
    },
    subTitle: {
      displayName: "Media type, Dimensions, Lighting, Region",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "Impressions",
      className: "text-right align-top col-2",
    },
    subTitle: {
      displayName: "Monthly",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "LTS",
      className: "text-center align-top col-2",
    },
    subTitle: {
      displayName: "Sites",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "",
      className: "col-2",
    },
  },
];

// Table Row Components
function ActionButtonCell({
  campaignId,
  campaignMediaInfo,
  dispatchFunction,
  loader,
  dataTarget = "",
}) {
  //Dispatch
  const dispatch = useDispatch();
  const isInventoryMedia = !!campaignMediaInfo?.mediaId;

  return (
    <td className="align-middle">
      <button
        className={`btn btn-outline-primary add-button shadow-none`}
        data-toggle="modal"
        data-target={dataTarget}
        onClick={() =>
          dispatch(dispatchFunction({ campaignId, campaignMediaInfo }))
        }
        disabled={loader}
      >
        {"Remove"}
        {loader && <Spinner className="ml-1 spinner-border-sm" />}
      </button>
      {isInventoryMedia && (
        <span className="text-muted font-italic px-2">{"Inventory"}</span>
      )}
    </td>
  );
}

function SelectedSitesTableRow({ media }) {
  // Campaign id
  const { id } = useParams();

  const isMediaSiteRemoved = useSelector(
    (state) => state.mapViewMediaSites.removeMediaSitesLoading[media.id]
  );

  // Redirect Url
  const campaignMediaViewPageUrl = RedirectTo.campaignMediaViewPageUrl
    .replace(":campaignId", id)
    .replace(":mediaId", media.id);

  return (
    <tr>
      <InfoCell
        mediaElementStyle={"p-2"}
        media={media}
        redirectUrl={campaignMediaViewPageUrl}
      />

      <ImpressionsCell
        impressionsElementStyle={"p-2 text-right align-middle"}
        media={media}
      />

      <LtsCell ltsElementClass={"p-2 text-center align-middle"} media={media} />

      <ActionButtonCell
        campaignId={id}
        campaignMediaInfo={media}
        dataTarget={`#${FormDataTargets.confirmationForm}`}
        dispatchFunction={openConfirmationForm}
        loader={isMediaSiteRemoved}
      />
    </tr>
  );
}

/**
 * Selected Sites Table
 */
function SelectedSitesTable({ mediaList, pagination, loadCityMedia }) {
  const { totalCount, pageNo, pageSize } = pagination;

  return (
    <div className="table-responsive">
      <table className="table table-media">
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />

        <tbody>
          {mediaList.map((media) => (
            <SelectedSitesTableRow media={media} key={media.id} />
          ))}
        </tbody>
      </table>

      <Pagination
        totalCount={totalCount}
        pageNo={pageNo}
        pageSize={pageSize}
        onClick={loadCityMedia}
      />
    </div>
  );
}

export default SelectedSitesTable;
