/* eslint-disable no-case-declarations */
import { CampaignCityReport } from "../../constants/action-constants/CampaignCityReportActionConstants";

const initialState = {
  // City-Summary ---------------------------------
  citySummary: {},
  citySummaryLoading: false,
  citySummaryError: "",
  citySummaryReport: {},
  previouscanBeApproximatedCount: 0,
  previousCannotBeApproximatedCount: 0,

  //Approx ots for city ----------------------------
  approxOtsForCityloading: false,
  isSuccess: false,

  // Pdf Report -------------------------------------
  downloadCityPdfReportLoading: false,
  cityPdfReportErr: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // City overview
    //-------------------------------------------------------------------------------------
    case CampaignCityReport.GET_CITY_OVERVIEW:
      return {
        ...state,
        citySummaryLoading: true,
      };
    case CampaignCityReport.GET_CITY_OVERVIEW_SUCCESS: {
      const data = action.data;
      const {
        canBeApproximatedCount: previouscanBeApproximatedCount,
        cannotBeApproximatedCount: previousCannotBeApproximatedCount,
      } = data.summary;
      return {
        ...state,
        citySummary: data,
        citySummaryReport: data,
        citySummaryLoading: false,
        previouscanBeApproximatedCount,
        previousCannotBeApproximatedCount,
      };
    }
    case CampaignCityReport.GET_CITY_OVERVIEW_FAILURE:
      return {
        ...state,
        citySummaryLoading: false,
        citySummaryError: action.payload,
      };

    // Approx ots for city
    //-------------------------------------------------------------------------------------
    case CampaignCityReport.APPROX_OTS_FOR_CITY:
      return {
        ...state,
        approxOtsForCityloading: true,
      };
    case CampaignCityReport.APPROX_OTS_FOR_CITY_SUCCESS:
      return {
        ...state,
        approxOtsForCityloading: false,
        isSuccess: true,
      };
    case CampaignCityReport.APPROX_OTS_FOR_CITY_FAILURE:
      return {
        ...state,
        approxOtsForCityloading: false,
        error: action.payload,
      };

    // City Summary Report Pdf
    //-------------------------------------------------------------------------------------

    case CampaignCityReport.DOWNLOAD_CITY_PDF_REPORT:
      return {
        ...state,
        downloadCityPdfReportLoading: true,
      };
    case CampaignCityReport.DOWNLOAD_CITY_PDF_REPORT_SUCCESS:
      return {
        ...state,
        downloadCityPdfReportLoading: false,
      };
    case CampaignCityReport.DOWNLOAD_CITY_PDF_REPORT_FAILURE:
      return {
        ...state,
        downloadCityPdfReportLoading: false,
        cityPdfReportErr: action.payload,
      };
    default:
      return state;
  }
};
