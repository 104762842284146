export function ContentList({ list, className, heading }) {
  return (
    <>
      <p className="lead mb-2">{heading}</p>
      <ul className={`lead ${className}`}>
        {list.map((info, i) => (
          <li key={i}>
            {info.content}
            <ul>
              {info.subList?.map((subList) => (
                <li key={subList}>{subList}</li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </>
  );
}

export function ContentHeading({ heading, className }) {
  return <h4 className={`font-weight-bold ${className}`}>{heading}:</h4>;
}

export function ContentParagraph({ paragraph, className }) {
  return <p className={`lead ${className}`}>{paragraph}</p>;
}

export function VideoAndPdfDownload() {
  return (
    <div className="row flex-column d-none">
      {/* video section */}
      <div className="video-container card bg-alt d-flex justify-content-center align-items-center">
        <i className="fa fa-play fa-2x"></i>
      </div>

      {/* download button section */}
      <button className="btn btn-outline-primary mt-2 shadow-none rounded-lg">
        Download Pdf{" "}
        <span>
          <i className="fa fa-file-pdf"></i>
        </span>
      </button>
    </div>
  );
}
