import { Link } from "react-router-dom";

// Utils
import { constructRedirectPath } from "../../utils/redirect-utils/RediectUtil";

function CampaignSidebarItems({ citySelected, cityInfo, redirectLinkObj }) {
  const { cityId, cityName, mediaCount } = cityInfo;
  return (
    <li
      key={`sb-${cityId}`}
      className={`nav-item ${citySelected?.cityId === cityId ? "active" : ""}`}
    >
      <Link
        className="nav-link"
        to={constructRedirectPath(
          redirectLinkObj?.citySummaryLink.replace(":cityId", cityId)
        )}
      >
        {cityName} ({mediaCount})
      </Link>
    </li>
  );
}

/**
 * Component SIDEBAR
 */
function CampaignSidebar(props) {
  const { campaign, citySelected, redirectLinkObj = {} } = props;
  const infoByCity = campaign.infoByCity || {};

  return (
    <aside className="sidebar bg-alt">
      <ul className="nav flex-column mt-4">
        <li className={`nav-item ${!citySelected ? "active" : ""} `}>
          <Link
            className="nav-link"
            to={constructRedirectPath(redirectLinkObj?.campaignOverviewLink)}
          >
            Overview ({campaign.mediaCount})
          </Link>
        </li>

        {/* Cities Listing Section */}
        {Object.values(infoByCity).map((cityInfo, i) => (
          <CampaignSidebarItems
            key={i}
            citySelected={citySelected}
            cityInfo={cityInfo}
            redirectLinkObj={redirectLinkObj}
          />
        ))}
      </ul>
    </aside>
  );
}

export default CampaignSidebar;
