// React Related Imports
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// Actions
import { login } from "../../actions/auth/AuthActions";

// Constants and Utils
import { constructRedirectPath } from "../../utils/redirect-utils/RediectUtil";
import AppThemes from "../../theme/AppThemes";
import mavin_logo from "../../assets/imgs/branding/logo-30px-primary.png";
import { AuthSubmitButton } from "./AuthUtils";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Css
import "./Auth.scss";

// Page Constants
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

// Page Components
function EmailInput({ setEmail }) {
  // State
  const [emailErr, setEmailErr] = useState("");

  function validateEmail(e) {
    const { value } = e.target;

    if (value.trim().length === 0) {
      setEmailErr("Email is required");
      return;
    }

    if (!validEmailRegex.test(value)) {
      setEmailErr("Email is not valid!");
      return;
    }
    setEmailErr("");
    setEmail(value);
  }

  return (
    <div className="form-group">
      <label className="input-label">Email</label>
      <input
        type="text"
        name="email"
        className="form-control"
        placeholder="Email address"
        onChange={validateEmail}
      />

      {/*Email Validation Err Display*/}
      {emailErr && <p className="text-danger pt-1 mb-0">{emailErr}</p>}
    </div>
  );
}

function PasswordInput({ setPassword }) {
  // Selector
  const loginErr = useSelector((state) => state.auth.loginError);

  function validatePassword(e) {
    const { value } = e.target;
    setPassword(value);
  }

  return (
    <div className="form-group">
      <label className="input-label">Password</label>
      <input
        type="password"
        name="password"
        className="form-control"
        placeholder="password"
        onChange={validatePassword}
      />

      {/*Login Error Display*/}
      {loginErr && <p className="text-danger pt-1 mb-0">{loginErr}</p>}
    </div>
  );
}

function CustomCopyRights() {
  return (
    <div className="text-center text-muted">
      <p className="mb-1">Powered by</p>
      <a
        className="btn-link"
        target="_blank"
        href="https://www.admavin.com/"
        rel="noreferrer"
      >
        <img src={mavin_logo} height="36" alt="AdMAVIN" />
      </a>
    </div>
  );
}

function MavinCopyRights() {
  return (
    <div className="text-center text-muted">
      <p className="mb-1">
        Copyright © AdMAVIN 2016-2022. All rights reserved.
      </p>
      <p>
        Module No. 14, Cluster 4, 1st Floor, E Block, IIT Madras Research Park,
        Kanagam Road, Taramani, Chennai - 600113.
      </p>
      <p>Version : {process.env.REACT_APP_VERSION}</p>
    </div>
  );
}

/**
 * Main Page
 */

export default function LoginPage() {
  // State
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  // selector
  const loggedInUser = useSelector((state) => state.auth.authUser);
  const loading = useSelector((state) => state.auth.loginLoading);

  //Dispatch
  const dispatch = useDispatch();

  const history = useHistory();

  // Theme
  const theme = AppThemes.getTheme();
  const isCustomTheme = theme.custom;

  // Token
  const token = loggedInUser?.token;

  useEffect(() => {
    // If User is logged in redirect to dashboard page..
    if (token) {
      history.push(constructRedirectPath(RedirectTo.homeUrl));
    }
  }, [history, token]);

  function loginUser(e) {
    e.preventDefault();
    dispatch(login(email, password));
  }

  return (
    <div className="content-wrapper h-100 bg-alt">
      <div className="page-content">
        {/* Login Card */}
        <form className="card shadow-lg auth-page-card" onSubmit={loginUser}>
          <h2 className="card-title text-center mb-5">Sign In</h2>

          {/* Email Input */}
          <EmailInput setEmail={setEmail} />

          {/* Password Input */}
          <PasswordInput setPassword={setPassword} />

          {/* Forget password Link */}
          <div className="mb-5">
            <Link
              className="float-right text-primary "
              to={RedirectTo.forgotPasswordUrl}
            >
              Forgot Password?
            </Link>
          </div>

          {/* Sign In */}
          <AuthSubmitButton label={"SIGN IN"} loading={loading} />
        </form>

        {/** Footer */}
        <footer className="main-footer mt-5 pt-5">
          {isCustomTheme ? <CustomCopyRights /> : <MavinCopyRights />}
        </footer>
      </div>
    </div>
  );
}
