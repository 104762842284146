import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

// Actions
import {
  getCampaignMediaByCity,
  updateCampaignMediaDuration,
} from "../../../../actions/campaign-media/CampaignMediaActions";
import { getCityOverview } from "../../../../actions/campaign-report/CampaignCityReportActions";
import { openConfirmationForm } from "../../../../actions/ConfirmationFormActions";

// Constants and Utils
import { defaultPagination } from "../../../../constants/UrlConstants";
import { getModifiedPopulation } from "../../../../utils/ReachAndFrequencyUtils";
import { FormDataTargets } from "../../../../constants/GeneralConstants";

// Urls
import { RedirectTo } from "../../../../urls/PageLinksURL";

// Component
import Spinner from "../../../../components/spinner/Spinner";
import PageHeader from "../../../../mavin/components/page-header/PageHeader";
import CampaignSidebar from "../../../../components/campaign-sidebar/CampaignSidebar";
import TableHeaders from "../../../../components/table/TableHeaders";
import {
  FrequencyCell,
  ImpressionsCell,
  InfoCell,
  LtsCell,
  ReachCell,
} from "../../../../components/campaign-media-table-row/MediaRow";
import { DurationCell } from "../../../../components/campaign-table-row/CampaignRowCells";
import AlertMessage from "../../../../mavin/components/alert-message/AlertMessage";
import { CampaignCityPerformance } from "../../../../mavin/components/campaign-city-performance/CampaignCityPerformance";
import { constructRedirectPath } from "../../../../utils/redirect-utils/RediectUtil";
import ConfirmationForm from "../../../../components/confirmation-form/ConfirmationForm";
import { removeMediaSite } from "../../../../actions/map-view/media-sites/MediaSitesActions";
import { SortMediaList } from "../../../../pages/map-view/media-sites/MediaSitesUtils";
import { sortMediaByImpression } from "../../../../utils/MediaUtils";

// Page Constants
const tableHeaders = (cityPopulation) => [
  {
    title: {
      displayName: "Site Name",
      className: "col-3",
    },
    subTitle: {
      displayName: "Media type, Dimensions, Lighting, Region",
      className: "",
    },
  },
  {
    title: {
      displayName: "Start and end date",
      className: "col-2 text-right",
    },
    subTitle: {
      displayName: "Duration",
      className: "text-right",
    },
  },
  {
    title: {
      displayName: "Impressions",
      className: "col-2 text-right",
    },
  },
  {
    title: {
      displayName: "Reach (%)",
      className: "col-2 text-right",
    },
    subTitle: {
      displayName: `Population: ${getModifiedPopulation(cityPopulation)}`,
      className: "sub-text",
    },
  },
  {
    title: {
      displayName: "Frequency",
      className: "col-1 text-right",
    },
  },
  {
    title: {
      displayName: "LTS",
      className: "col-1 text-center",
    },
  },
  {
    title: {
      displayName: "Action",
      className: "col-1",
    },
  },
];

// Page Component
export function MediaSelectionButton({ campaignId, campaignMediaInfo }) {
  const dispatch = useDispatch();

  function unSelectMedia() {
    dispatch(openConfirmationForm({ campaignId, campaignMediaInfo }));
  }

  return (
    <td className="col-1 align-middle">
      <button
        type="button"
        className="btn added-button shadow-none btn-outline-primary"
        onClick={unSelectMedia}
        data-toggle="modal"
        data-target={`#${FormDataTargets.confirmationForm}`}
      >
        <b>Remove</b>
      </button>
    </td>
  );
}

function PlanCityViewMediaRow({ media, cityPopulation }) {
  const dispatch = useDispatch();

  if (!media || Object.keys(media).length === 0) {
    return null;
  }

  const {
    startTimestamp,
    endTimestamp,
    duration,
    campaignId,
    id: mediaId,
    reachFrequency,
  } = media;

  // function to update date
  function onDateSelect(selectedDate) {
    dispatch(updateCampaignMediaDuration(selectedDate, media));
  }

  const dateObj = { startTimestamp, endTimestamp };

  // Redirect Url
  const campaignMediaViewPageUrl = RedirectTo.campaignMediaViewPageUrl
    .replace(":campaignId", campaignId)
    .replace(":mediaId", mediaId);

  return (
    <tr>
      <InfoCell
        mediaElementStyle={"p-2"}
        media={media}
        redirectUrl={campaignMediaViewPageUrl}
      />
      <DurationCell
        dateObj={dateObj}
        durationElementStyle={"p-2 text-right align-middle"}
        onDateSelect={onDateSelect}
        duration={duration}
      />
      <ImpressionsCell
        impressionsElementStyle={"p-2 text-right align-middle"}
        media={media}
      />
      <ReachCell
        reachElementStyle={"p-2 text-right align-middle"}
        reachFrequency={reachFrequency}
        cityPopulation={cityPopulation}
        showPopulation={false}
      />
      <FrequencyCell
        frequencyElementClass={"p-2 text-right align-middle"}
        reachFrequency={reachFrequency}
      />
      <LtsCell ltsElementClass={"p-2 text-center align-middle"} media={media} />
      <MediaSelectionButton campaignId={campaignId} campaignMediaInfo={media} />
    </tr>
  );
}

function PlanCityViewMediaTable({ mediaList, cityPopulation }) {
  return (
    <div className="table-responsive mt-3">
      <table className="table table-media">
        {/* Table header */}
        <TableHeaders
          tableHeaders={tableHeaders(cityPopulation)}
          headerClass={"thead"}
        />

        <tbody>
          {/* Table rows */}
          {mediaList.map((media) => (
            <PlanCityViewMediaRow
              media={media}
              key={media.id}
              cityPopulation={cityPopulation}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function CityViewTableHeadingAndSiteOptions({ mediaList, setSortedMedia }) {
  useEffect(() => {
    setSortedMedia(mediaList);
  }, []);

  // update campaignMediaDuration
  const campaignMediaDurationChanging = useSelector(
    (state) => state.campaignMedia.mediaDurationChanging
  );

  return (
    <div className="d-flex mt-4 align-items-center justify-content-between">
      <div>
        <h4 className="mb-0 mr-2 ">{"Site Details"}</h4>
        {campaignMediaDurationChanging && (
          <AlertMessage
            className="mb-0 py-1"
            spinnerClassName="spinner-border-sm"
            textMessage="Updating Metrics"
          />
        )}
      </div>
      <div>
        {/*Sort media dropdown */}
        <SortMediaList
          onSortingApplied={(sortKey) => {
            setSortedMedia(sortMediaByImpression(mediaList, sortKey));
          }}
          className={"px-3"}
        />
      </div>
    </div>
  );
}

/**
 * Page
 */
function CampaignPlanCityviewPage() {
  const { campaignId: id, cityId } = useParams();

  // State
  const [sortedMedia, setSortedMedia] = useState([]);

  // Selector State
  const citySummaryLoading = useSelector(
    (state) => state.campaignCityReport.citySummaryLoading
  );
  const { campaign = {}, summary: citySummary = {} } = useSelector(
    (state) => state.campaignCityReport.citySummary
  );

  const { targetGroupId = "" } = campaign;

  const { population: cityPopulation } = citySummary;

  // CityMedia and Data
  const cityMediaLoading = useSelector(
    (state) => state.campaignMedia.cityLoading
  );
  const cityMediaSummary = useSelector(
    (state) => state.campaignMedia.citySummary
  );
  const cityMediaSummaryResult = cityMediaSummary.result || {};
  const { items = [] } = cityMediaSummaryResult;

  // Confirmation Form :: [true/false and dataObject]
  const openConfirmationForm = useSelector(
    (state) => state.confirmationModal.openModal
  );

  const processDataObject = useSelector(
    (state) => state.confirmationModal.processDataObject
  );

  // Link to pass in CampaignSidebar
  const redirectLinkObj = {
    campaignOverviewLink: RedirectTo.campaignPlanOverviewPageUrl.replace(
      ":campaignId",
      id
    ),
    citySummaryLink: RedirectTo.campaignPlanCityViewPageUrl.replace(
      ":campaignId",
      id
    ),
  };

  const cityInfo = {
    cityId: cityId,
    cityName: citySummary?.cityName || cityId,
  };

  const pageNumber = defaultPagination.pageNo;
  const pageSize = defaultPagination.pageSize;

  //Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCityOverview(id, cityId));
    dispatch(getCampaignMediaByCity(id, cityId, pageNumber, pageSize));
  }, [dispatch, id, cityId, items.length]);

  // link to city-specific-Map-view
  const campaignPlanMapViewPageUrl = RedirectTo.campaignPlanMapViewPageUrl
    .replace(":campaignId", id)
    .replace(":cityId", cityId);

  const pageTitle = campaign.title + " :: " + cityInfo.cityName;
  const pageHeaderAction = (
    <Link
      className="btn btn-primary rounded-lg px-3"
      to={{
        pathname: constructRedirectPath(campaignPlanMapViewPageUrl),
        state: { targetGroupId },
      }}
    >
      Select Media on Map
    </Link>
  );

  //checks for page loading
  if (citySummaryLoading || cityMediaLoading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  // This is passed in "ConfirmAction Form" to Remove selected media-site
  function removeMediaSiteFn() {
    dispatch(removeMediaSite(id, processDataObject.campaignMediaInfo));
  }

  return (
    <>
      {/* Sidebar */}
      <CampaignSidebar
        campaign={campaign}
        citySelected={cityInfo}
        redirectLinkObj={redirectLinkObj}
      />

      {/* Main Content */}
      <div className="content-wrapper">
        {/* Page Header */}
        <PageHeader title={pageTitle} actions={pageHeaderAction} />

        {/* Page Content */}
        <div className="page-content">
          {/* Performance Card */}
          <CampaignCityPerformance citySummary={citySummary} />

          {/* CityView Table Header */}
          <CityViewTableHeadingAndSiteOptions
            mediaList={items}
            setSortedMedia={setSortedMedia}
          />

          {/* Media Table */}
          <PlanCityViewMediaTable
            mediaList={sortedMedia}
            cityPopulation={cityPopulation}
          />

          {/** Modals */}
          {openConfirmationForm && (
            <ConfirmationForm onClick={removeMediaSiteFn} />
          )}
        </div>
      </div>
    </>
  );
}

export default CampaignPlanCityviewPage;
