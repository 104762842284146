import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  selectRoadStretch,
  unselectRoadStretch,
} from "../../actions/campaign-planning/CampaignPlanningRoadStretchesActions";
import { openPoiForm } from "../../actions/campaign-planning/PoiFormActions";

// Constants and Utils
import { ProohFormDataTargets } from "../../constants/GeneralConstants";

// Components
import Spinner from "../../../components/spinner/Spinner";

// Util Components
export function LoadingRoadStretches() {
  return (
    <div className="d-flex justify-content-center p-2 bg-alt border">
      <Spinner />
      <span className="ml-2 py-1">
        Please wait, Road Stretches are Loading...
      </span>
    </div>
  );
}

export function LoadingPlanningMapView() {
  return (
    <div className="d-flex justify-content-center p-2">
      <Spinner />
    </div>
  );
}

export function StretchSelectionButton({ stretchInfo }) {
  const stretchId = stretchInfo.id;
  const stretchSelected = useSelector(
    (state) => state.planningRoadStretches.selectedStretches[stretchId]
  );

  function unSelectStretch() {
    dispatch(unselectRoadStretch(stretchSelected));
  }

  function selectStretch() {
    dispatch(selectRoadStretch(stretchInfo));
  }

  const dispatch = useDispatch();
  if (stretchSelected) {
    return (
      <div className="col-2 p-2 text-right">
        <button
          type="button"
          className="btn added-button shadow-none btn-outline-success mt-1"
          onClick={unSelectStretch}
        >
          <i className="fa fa-check"></i> <b>Selected</b>
        </button>
      </div>
    );
  }
  return (
    <div className="p-2 col-2 text-right">
      <button
        type="button"
        className="btn add-button shadow-none btn-outline-primary mt-1"
        onClick={selectStretch}
      >
        <b>Select</b>
      </button>
    </div>
  );
}

export function AddCaptiveAreaSection() {
  const dispatch = useDispatch();

  // for add-poi/captive-area popup modal
  function openPoiFormFunction() {
    dispatch(openPoiForm());
  }
  return (
    <button
      type="button"
      className="btn btn-link nav-link shadow-none p-0 ml-2"
      data-toggle="modal"
      data-target={`#${ProohFormDataTargets.addCaptiveAreaForm}`}
      onClick={openPoiFormFunction}
    >
      + Add POI/Captive Area
    </button>
  );
}
