import { ActionTypes } from "../../../constants/ActionConstants";
import { RoadSegment } from "../../../constants/action-constants/RoadSegmentActionConstants";

// These below actions are for "open-poi/captive-area" form
export const openPoiForm = () => ({
  type: ActionTypes.PoiForm.OPEN_POI_AND_CAPTIVE_AREA_MODAL,
});

export const closePoiForm = () => ({
  type: ActionTypes.PoiForm.CLOSE_POI_AND_CAPTIVE_AREA_MODAL,
});

export const getClosestRoadSegments = (locationStr) => ({
  type: ActionTypes.PoiForm.GET_CLOSEST_ROAD_SG,
  payload: { locationStr },
});

export const getOtsOfLatLongs = (latLongArr) => ({
  type: ActionTypes.SellerExcelOverview.GET_OTS_OF_LAT_LONGS,
  payload: { latLongArr },
});

export const getExactLatLong = (locationStr) => ({
  type: ActionTypes.ExactLatLong.GET_EXACT_LAT_LONG,
  payload: { locationStr },
});

export const removeRoadSegment = () => ({
  type: RoadSegment.REMOVE_CLOSEST_ROAD_SG,
});
