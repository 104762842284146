import React from "react";

// Other Page Sections
import ProtectedRoute from "../ProtectedRoute";
import { constructRouterPath } from "../App";

// Constants and Utils
import { OrgType } from "../constants/OrgTypeConstants";
import { getItem } from "../utils/localstorage";
import { LocalStorageKeys } from "../constants/GeneralConstants";

// AAO pages
import CampaignPlanningPage from "./pages/campaign-planning/CampaignPlanningPage";
import CampaignSchedulePage from "./pages/campaign-schedule/CampaignSchedulePage";
import CampaignReviewPage from "./pages/campaign-review/CampaignReviewPage";
import CampaignReviewOverviewPage from "./pages/campaign-review-overview/CampaignReviewOverviewPage";
import CampaignConfirmationPage from "./pages/campaign-confirmation/CampaignConfirmationPage";
import HomePage from "./pages/home/HomePage";
import BuyerHomePage from "./pages/home/BuyerHomePage";
import MediaSelectionPage from "./pages/media-selection/MediaSelectionPage";
import CampaignDraftOverviewPage from "./pages/campaign-draft-overview/CampaignDraftOverviewPage";
import CampaignDraftPage from "./pages/campaign-draft/CampaignDraftPage";
import CampaignLiveOverviewPage from "./pages/campaign-live-overview/CampaignLiveOverviewPage";
import CampaignConfirmOverviewPage from "./pages/campaign-confirm-overview/CampaignConfirmOverviewPage";
import CampaignScheduleOverviewPage from "./pages/campaign-schedule-overview/CampaignScheduleOverviewPage";
import CampaignLivePage from "./pages/campaign-live/CampaignLivePage";
import CampaignCompletePage from "./pages/campaign-complete/CampaignCompletePage";

//seller page
import SellerHomePage from "./pages/home/SellerHomePage";
import SellerEarningsPage from "./pages/seller-earnings/SellerEarningsPage";
import SellerSiteEarningsPage from "./pages/seller-site-earnings/SellerSiteEarningsPage";

// seller pitching pages
import SellerSingleSiteReportPage from "./pages/seller-single-site-report/SellerSingleSiteReportPage";

// Error page
import ErrorPage from "../pages/error-pages/ErrorPage";

// CSS
import "./theme/ProohStyle.scss";

function getHomePage() {
  const organizationType = getItem(LocalStorageKeys.ORG_TYPE);
  if (organizationType === OrgType.MEDIA_AGENCY) {
    return <HomePage />;
  }

  if (organizationType === OrgType.MEDIA_OWNER) {
    return <SellerHomePage />;
  }

  if (organizationType === OrgType.BRAND_OWNER) {
    return <BuyerHomePage />;
  }

  return null;
}

function ProohApp() {
  return (
    <>
      {/* Dashboard Pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/home", true, true)}
        component={getHomePage}
      />

      {/* Campaign Overview Pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:campaignId/review", true, true)}
        component={CampaignReviewOverviewPage}
        sidebar={true}
      />

      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:campaignId/live", true, true)}
        component={CampaignLiveOverviewPage}
        sidebar={true}
      />

      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:campaignId/draft", true, true)}
        component={CampaignDraftOverviewPage}
        sidebar={true}
      />

      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:campaignId/confirm", true, true)}
        component={CampaignConfirmOverviewPage}
        sidebar={true}
      />

      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:campaignId/schedule", true, true)}
        component={CampaignScheduleOverviewPage}
        sidebar={true}
      />

      {/* City Pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/planning", true, true)}
        component={CampaignPlanningPage}
      />

      <ProtectedRoute
        exact
        path={constructRouterPath("/campaign/:campaignId/planning", true, true)}
        component={CampaignPlanningPage}
      />

      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/campaign/:campaignId/city/:cityId/media-selection",
          true,
          true
        )}
        component={MediaSelectionPage}
      />

      <ProtectedRoute
        path={constructRouterPath(
          "/campaign/:campaignId/city/:cityId/draft",
          true,
          true
        )}
        component={CampaignDraftPage}
        sidebar={true}
      />

      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/campaign/:campaignId/city/:cityId/review",
          true,
          true
        )}
        component={CampaignReviewPage}
        sidebar={true}
      />

      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/campaign/:campaignId/city/:cityId/confirm",
          true,
          true
        )}
        component={CampaignConfirmationPage}
        sidebar={true}
      />

      <ProtectedRoute
        path={constructRouterPath(
          "/campaign/:campaignId/city/:cityId/schedule",
          true,
          true
        )}
        component={CampaignSchedulePage}
        sidebar={true}
      />

      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/campaign/:campaignId/city/:cityId/live",
          true,
          true
        )}
        component={CampaignLivePage}
        sidebar={true}
      />

      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/campaign/:campaignId/city/:cityId/complete",
          true,
          true
        )}
        component={CampaignCompletePage}
        sidebar={true}
      />

      {/* Seller Pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath("/seller/earnings", true, true)}
        component={SellerEarningsPage}
      />

      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/seller/earnings/media/:mediaId",
          true,
          true
        )}
        component={SellerSiteEarningsPage}
      />

      {/* seller pitching pages */}
      <ProtectedRoute
        exact
        path={constructRouterPath(
          "/seller/pitch/single-site-report",
          true,
          true
        )}
        component={SellerSingleSiteReportPage}
        sidebar={false}
      />

      <ProtectedRoute exact component={ErrorPage} />
    </>
  );
}

export default ProohApp;
