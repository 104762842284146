import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// APIs
import {
  downloadMonitorMediaLogPdfReportFn,
  getMonitoringLogByMedia,
} from "../../apis/MonitoringLogAPI";

// Constants and Utils..
import { Monitoring } from "../../constants/action-constants/MonitoringActionConstants";
import {
  saveFile,
  saveImageFile,
} from "../../common-utils/file-utils/FileUtils";
import {
  DownloadImagesNameConstants,
  ImageTypes,
  ReportConstants,
} from "../../constants/GeneralConstants";
import { getErrorMessage } from "../../utils/util";
import { downloadImage } from "../../utils/api";

export function* getMonitoringMediaLog(action) {
  try {
    const { campaignId, mediaId, pageNumber, pageSizeCount } = action.payload;

    // making GET request for all-log-summary
    const responseMediaLog = yield getMonitoringLogByMedia(
      campaignId,
      mediaId,
      pageNumber,
      pageSizeCount
    );

    yield put({
      type: Monitoring.GET_MONITORING_MEDIA_LOG_SUCCESS,
      payload: { responseMediaLog },
    });
  } catch (error) {
    yield put({
      type: Monitoring.GET_MONITORING_MEDIA_LOG_FAILURE,
      payload: error,
    });
  }
}

export function* downloadMonitorMediaLogPdfReport(action) {
  try {
    const { campaignId, mediaId } = action.payload;
    const data = yield downloadMonitorMediaLogPdfReportFn(campaignId, mediaId);
    saveFile(ReportConstants.MONITORING_MEDIA_LOG_REPORT, data);
    yield put({
      type: Monitoring.DOWNLOAD_MONITORING_MEDIA_LOG_PDF_REPORT_SUCCESS,
    });
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put({
      type: Monitoring.DOWNLOAD_MONITORING_MEDIA_LOG_PDF_REPORT_FAILURE,
      payload: err,
    });
    toast.error(errMsg);
  }
}
export function* downloadMonitoringMediaLogImage(action) {
  try {
    const { imageUrl } = action.payload;

    const response = yield downloadImage(imageUrl);

    saveImageFile(
      DownloadImagesNameConstants.LOG_IMAGE,
      response,
      ImageTypes.JPEG
    );
    //dispatching action
    yield put({
      type: Monitoring.DOWNLOAD_MONITORING_MEDIA_LOG_IMAGE_SUCCESS,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: Monitoring.DOWNLOAD_MONITORING_MEDIA_LOG_IMAGE_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(Monitoring.GET_MONITORING_MEDIA_LOG, getMonitoringMediaLog),
    takeLatest(
      Monitoring.DOWNLOAD_MONITORING_MEDIA_LOG_PDF_REPORT,
      downloadMonitorMediaLogPdfReport
    ),
    takeLatest(
      Monitoring.DOWNLOAD_MONITORING_MEDIA_LOG_IMAGE,
      downloadMonitoringMediaLogImage
    ),
  ]);
}
