import { Link } from "react-router-dom";

// Utils
import { constructRedirectPath } from "./redirect-utils/RediectUtil";
import {
  constructSingleDateString,
  getTimestamp,
} from "../common-utils/date-utils/DateUtils";
import { DATE_FORMATS } from "../constants/GeneralConstants";

// Images
import billboardIcon from "../assets/imgs/icons/icon_unipole.png";
import closeIcon from "../assets/imgs/icons/close.png";

export function ManageMonitoringCell({
  isMonitoringEnabled,
  className = "",
  redirectUrl,
  target = "",
}) {
  if (!isMonitoringEnabled) {
    return <td className={`text-muted ${className}`}>Monitoring Not Active</td>;
  }

  return (
    <td className={className}>
      <Link to={constructRedirectPath(redirectUrl)} target={target}>
        {"Manage monitoring"}
      </Link>
    </td>
  );
}

export function MonitoringStatus({ isMonitoringEnabled }) {
  if (!isMonitoringEnabled) {
    return <p className="mb-0 text-muted">Monitoring Not active</p>;
  }

  return (
    <p className="text-success font-weight-bold mb-0 ">
      <span>
        <i className="fa fa-circle mr-1"></i>
      </span>
      Monitoring
    </p>
  );
}

/**
 * @param {*} mediaLogDays  array of date strings
 * @returns array of days before current day
 */
export function getElapsedDays(mediaLogDays = []) {
  // current dtae timestamp starting from midnight
  const currentTimestamp = new Date().setHours(0, 0, 0, 0);

  const elapsedDays = mediaLogDays.filter((eachDay) => {
    const eachDayTimestamp = getTimestamp(
      eachDay,
      DATE_FORMATS.date_month_year
    );

    return currentTimestamp > eachDayTimestamp;
  });
  return elapsedDays;
}

/**
 *function to calculate required media analytics
 * @param {*} updatedMediaLogsDayWise is a object
 * @returns media analytics Object
 */
export function constructMediaLogAnalytics(updatedMediaLogsDayWise = {}) {
  const mediaLogDays = Object.keys(updatedMediaLogsDayWise);
  const duration = mediaLogDays.length;
  //  job Done Count
  const jobDoneCount = mediaLogDays.filter(
    (key) => updatedMediaLogsDayWise[key].items.length > 0
  ).length;

  // days before present day
  const elapsedDays = getElapsedDays(mediaLogDays);
  // Jobs  Skipped   ToDate
  const totalSkippedJobsToDate = elapsedDays.filter(
    (key) => updatedMediaLogsDayWise[key].items.length === 0
  ).length;

  // Jobs  Skipped percentage  ToDate
  const defaultedPercentage =
    elapsedDays.length !== 0
      ? (totalSkippedJobsToDate / elapsedDays.length) * 100
      : 0;
  const mediaAnalytics = {
    duration,
    jobDoneCount,
    elapsedDays,
    totalSkippedJobsToDate,
    defaultedPercentage,
  };
  return mediaAnalytics;
}

/**
 *
 * @param {*} startTimestamp ->timestamp in ms
 * @param {*} endTimestamp -->timestamp in ms
 * @param {*} frequencyDays --> number of days like 2,3
 * @param {*} existingMediaLogs -->mediaLogs Object
 * @returns mediaLogsDayWise object which contains all daywise logs till campaignduration
 */
export function updateMediaLogs(
  startTimestamp,
  endTimestamp,
  frequencyDays,
  existingMediaLogs = {}
) {
  // default Image Url
  const defaultImageUrl = billboardIcon;

  // job not done url
  const missingImageUrl = closeIcon;

  // Converting frequencyDays to milliseconds
  const interval = frequencyDays * 24 * 60 * 60 * 1000;
  let currentTimestamp = startTimestamp;
  const mediaLogsDayWise = {};

  while (currentTimestamp <= endTimestamp) {
    const dayKey = constructSingleDateString(
      currentTimestamp,
      DATE_FORMATS.date_month_year
    );

    if (
      existingMediaLogs[dayKey] &&
      existingMediaLogs[dayKey].items.length > 0
    ) {
      mediaLogsDayWise[dayKey] = existingMediaLogs[dayKey];
    } else {
      mediaLogsDayWise[dayKey] = {
        items: [],
        latestImageUrl: { url: defaultImageUrl, timestamp: currentTimestamp },
      };
    }
    currentTimestamp += interval;
  }

  const keys = Object.keys(mediaLogsDayWise);

  const elapsedDays = getElapsedDays(keys);

  if (elapsedDays.length > 0) {
    elapsedDays.forEach((day) => {
      mediaLogsDayWise[day].items.length === 0 &&
        (mediaLogsDayWise[day].latestImageUrl.url = missingImageUrl);
    });
  }
  return mediaLogsDayWise;
}

/**
 *
 * @param {*} mediaLogs -->array containing all the media logs
 * @returns -> object which contains all the medialogs day wise
 */
export function groupItemsByDay(mediaLogs = []) {
  if (mediaLogs.length === 0) {
    return {};
  }

  const groupedItems = {};
  mediaLogs.forEach((mediaLog) => {
    const date = constructSingleDateString(
      mediaLog.deviceTimestamp,
      DATE_FORMATS.date_month_year
    );
    if (!groupedItems[date]) {
      groupedItems[date] = {
        items: [],
        latestImageUrl: null,
      };
    }

    if (mediaLog.images && mediaLog.images.length > 0) {
      const latestImage = mediaLog.images[mediaLog.images.length - 1];
      if (
        !groupedItems[date].latestImageUrl ||
        mediaLog.deviceTimestamp > groupedItems[date].latestImageUrl.timestamp
      ) {
        groupedItems[date].latestImageUrl = {
          url: latestImage.taggedUrl,
          timestamp: mediaLog.deviceTimestamp,
        };
      }
    }

    // Add the item to the grouped items
    groupedItems[date].items.push(mediaLog);
  });
  return groupedItems;
}
