//Utils
import { VideoAndPdfDownload } from "./HelpPageUtils";

//Component
import HelpSidebar from "./HelpSidebar";
import PageHeader from "../../mavin/components/page-header/PageHeader";

//Css
import "./HelpPage.scss";

const videoDetailsConstants = [
  {
    downloadUrl: "",
    videoUrl: "",
  },
  {
    downloadUrl: "",
    videoUrl: "",
  },
  {
    downloadUrl: "",
    videoUrl: "",
  },
  {
    downloadUrl: "",
    videoUrl: "",
  },
];

//initially we will land on this page
function HelpPage() {
  const pageTitle = "Tutorial Videos";
  return (
    <>
      <HelpSidebar activeIdx={0} />
      <div className="content-wrapper">
        <PageHeader title={pageTitle} shadow={true} />
        <div className="page-content d-flex justify-content-between">
          {videoDetailsConstants.map((info, i) => (
            <VideoAndPdfDownload key={i} />
          ))}
        </div>
      </div>
    </>
  );
}

export default HelpPage;
