//Components
import Carousel from "../../../components/carousel/Carousel";
import Spinner from "../../../components/spinner/Spinner";
import { CustomButton } from "../button/Button";

// Constants
const UPLOAD_IMAGES = "Upload Images";
const FOOTER_TEXT =
  "Selected sites with monthly impressions (2019) | Target group: All";

// components
function UploadImage({ onUploadClick }) {
  return (
    <>
      <i className="far fa-images fa-10x text-primary opacity-25"></i>
      <p className="mb-5">
        <strong>No site image available</strong>
      </p>
      <CustomButton
        displayContent={UPLOAD_IMAGES}
        onClickFunction={onUploadClick}
      />
    </>
  );
}

function ImageFooter({ onUploadClick, isButtonVisible }) {
  return (
    <div className="d-flex justify-content-between align-items-center p-3">
      {isButtonVisible && (
        <CustomButton
          displayContent={UPLOAD_IMAGES}
          onClickFunction={onUploadClick}
        />
      )}
      <p className="m-0">{FOOTER_TEXT}</p>
    </div>
  );
}

function MediaImageSection({ urls, selectAndUpload, isLoading }) {
  if (isLoading) {
    return <Spinner />;
  }
  if (urls.length) {
    return <Carousel urls={urls} />;
  }
  return <UploadImage onUploadClick={selectAndUpload} />;
}

// Component
export default function ImageUpload({ fileItems, isLoading, selectAndUpload }) {
  const urls =
    fileItems && fileItems.length
      ? fileItems.map((fileItem) => fileItem.originalUrl)
      : [];

  return (
    <>
      <div className="image-container">
        {/* Media image selection */}
        <MediaImageSection
          urls={urls}
          selectAndUpload={selectAndUpload}
          isLoading={isLoading}
        />
      </div>
      <ImageFooter
        onUploadClick={selectAndUpload}
        isButtonVisible={urls.length > 0}
      />
    </>
  );
}
