import React from "react";
import { useDispatch } from "react-redux";

// Actions
import { updateCampaignMediaDuration } from "../../../actions/campaign-media/CampaignMediaActions";

// Constants and Utils
import { getModifiedPopulation } from "../../../utils/ReachAndFrequencyUtils";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

//Components
import {
  InfoCell,
  ImpressionsCell,
  LtsCell,
  FrequencyCell,
  ReachCell,
} from "../../../components/campaign-media-table-row/MediaRow";
import { DurationCell } from "../../../components/campaign-table-row/CampaignRowCells";
import Hamburger from "../../../components/hamburger-menu/Hamburger";
import TableHeaders from "../../../components/table/TableHeaders";
import Pagination from "../../../components/pagination/Pagination";

// Page Constants

const otsHeaderInfo = [
  {
    displayText: "Tg Impressions",
  },
  {
    displayText: "Total Impressions",
  },
];

const reachHeaderInfo = [
  {
    displayText: "Tg Reach (%)",
  },
  {
    displayText: "Total Reach (%)",
  },
];

const citySummaryTableHeaders = (cityPopulation) => [
  {
    title: {
      displayName: "Site Name",
      className: "col-4",
    },
    subTitle: {
      displayName: "Media type, Dimensions, Lighting, Region",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "Start and end date",
      className: "col-2 text-right",
    },
    subTitle: {
      displayName: "Duration",
      className: "sub-text",
    },
  },

  {
    element: <OtsAndReachHeader headerInfo={otsHeaderInfo} />,
  },
  {
    element: (
      <OtsAndReachHeader
        headerInfo={reachHeaderInfo}
        subText={`Population: ${getModifiedPopulation(cityPopulation)}`}
      />
    ),
  },

  {
    title: {
      displayName: "Frequency",
      className: "col-1 text-right",
    },
  },

  {
    title: {
      displayName: "LTS",
      className: "col-1 text-right",
    },
  },

  {
    title: {
      displayName: "",
      className: "col-1",
    },
  },
];

function OtsAndReachHeader({ headerInfo, subText }) {
  return (
    <th className="text-right">
      {headerInfo.map((eachHeaderInfo, i) => (
        <div key={i}>
          <p className="mb-2 mt-0">{eachHeaderInfo.displayText}</p>
        </div>
      ))}

      <p className="sub-text mb-0">{subText}</p>
    </th>
  );
}

function MediaRowForCitySummary({ media = {}, cityPopulation }) {
  const dispatch = useDispatch();

  const { endTimestamp, startTimestamp, campaignId, id: mediaId } = media;

  // function to update date
  function onDateSelect(selectedDate) {
    dispatch(updateCampaignMediaDuration(selectedDate, media));
  }

  const dateObj = { startTimestamp, endTimestamp };

  // Redirect Url
  const campaignMediaViewPageUrl = RedirectTo.campaignMediaViewPageUrl
    .replace(":campaignId", campaignId)
    .replace(":mediaId", mediaId);

  return (
    <tr>
      <InfoCell
        mediaElementStyle={"p-2"}
        media={media}
        redirectUrl={campaignMediaViewPageUrl}
      />

      {endTimestamp && startTimestamp ? (
        <DurationCell
          durationElementStyle={"py-2 pr-0 text-right align-middle"}
          onDateSelect={onDateSelect}
          duration={media.duration}
          dateObj={dateObj}
        />
      ) : (
        <td className="p-2 text-right align-middle">--</td>
      )}

      <ImpressionsCell
        impressionsElementStyle={"p-2 text-right align-middle"}
        media={media}
      />

      <ReachCell
        reachElementStyle={"p-2 text-right align-middle"}
        reachFrequency={media.reachFrequency}
        cityPopulation={cityPopulation}
        showPopulation={false}
      />
      <FrequencyCell
        frequencyElementClass={"p-2 text-right align-middle"}
        reachFrequency={media.reachFrequency}
      />

      <LtsCell ltsElementClass={"p-2 text-right align-middle"} media={media} />

      <td className="p-2 text-right align-middle">
        <Hamburger>
          <button className="dropdown-item">Download report</button>
          <button className="dropdown-item">Share link</button>
        </Hamburger>
      </td>
    </tr>
  );
}

function CampaignCityViewMediaTable({
  mediaList,
  pagination,
  loadCityMedia,
  cityPopulation,
}) {
  const { totalCount, pageNo, pageSize } = pagination;

  return (
    <div className="table-responsive mt-4">
      <table className="table table-media">
        {/* Table header */}
        <TableHeaders
          tableHeaders={citySummaryTableHeaders(cityPopulation)}
          headerClass={"thead"}
        />

        <tbody>
          {/* Table rows */}
          {mediaList.map((media) => (
            <MediaRowForCitySummary
              media={media}
              key={media.id}
              cityPopulation={cityPopulation}
            />
          ))}
        </tbody>
      </table>

      {/* Table Pagination */}
      <Pagination
        totalCount={totalCount}
        pageNo={pageNo}
        pageSize={pageSize}
        onClick={loadCityMedia}
      />
    </div>
  );
}

export default CampaignCityViewMediaTable;
