import React from "react";
import { Marker, Polyline, Popup, Tooltip } from "react-leaflet";
import { useSelector } from "react-redux";

// Utils
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";
import { StretchSelectionButton } from "./PlanningUtils";

// Components
import LLMap from "../../../components/map/leaflet-map/LLMap";
import MapIcon from "../../../components/map/map-icon/MapIcon";
import MediaMarkers from "../../../components/map/markers/MediaMarkers";
import Spinner from "../../../components/spinner/Spinner";
import TrafficFlowArrow from "../../../components/map/traffic-flow-arrow/TrafficFlowArrow";
import TargetGroupHeatmap from "../../../components/map/target-group-heatmap/TargetGroupHeatmap";
import PoiMarkers from "../../../components/map/markers/PoiMarkers";

// Section Constants
export const selected = { color: "green", weight: 5 };
export const unSelected = { color: "blue", weight: 5 };

// Section Component
function StretchTooltipMessages({
  specificImpressions,
  genericImpressions,
  estImpressions,
}) {
  // Selected TgId
  const selectedTgId =
    useSelector((state) => state.orgTargetGroup.tgInfo.targetGroup?.id) || "";

  //showing "EstImpressions"
  if (!selectedTgId) {
    return (
      <span>
        {"Total Imp: "}
        <b>{estImpressions ? toLocaleString(estImpressions) : "--"}</b>
      </span>
    );
  }

  // Showing Tg "Specific" and "Generic" impressions (if present)
  return (
    <>
      <span>
        {"Specific Imp: "}
        <b>
          {specificImpressions ? toLocaleString(specificImpressions) : "--"}
        </b>
      </span>
      <span className="d-block">
        {"Generic Imp: "}
        <b>{genericImpressions ? toLocaleString(genericImpressions) : "--"}</b>
      </span>
    </>
  );
}

// Page Components
function StretchLine({ eachStretch, duration }) {
  const {
    otsAvg,
    otsLitAvg,
    genericOts,
    genericOtsLit,
    targetOts,
    targetOtsLit,
    trace,
  } = eachStretch;
  // Without selecting TG
  const estImpressions = toStringWithRoundUp(duration * (otsLitAvg ?? otsAvg));

  // With Selecting TG
  const genericImpressions = toStringWithRoundUp(
    duration * (genericOtsLit ?? genericOts)
  );
  const specificImpressions = toStringWithRoundUp(
    duration * (targetOtsLit ?? targetOts)
  );

  // Selected Road-Stretch
  const stretchSelected = useSelector(
    (state) => state.planningRoadStretches.selectedStretches[eachStretch.id]
  );
  const pathOptions = stretchSelected ? selected : unSelected;

  return (
    <Polyline pathOptions={pathOptions} positions={trace}>
      <Tooltip permanent={true} direction="top" interactive={true}>
        <StretchTooltipMessages
          specificImpressions={specificImpressions}
          genericImpressions={genericImpressions}
          estImpressions={estImpressions}
        />
        <StretchSelectionButton stretchInfo={eachStretch} />
      </Tooltip>
    </Polyline>
  );
}

// for road-segment
function SegmentLine({ eachSegment }) {
  const { otsLitAvg, otsAvg } = eachSegment;
  const estImpressions = otsLitAvg ? otsLitAvg : otsAvg;
  const segmentId = eachSegment.id;
  const segmentPoints = eachSegment.trace || [];
  const segmentSelected = useSelector(
    (state) => state.planningRoadSegments.selectedSegments[segmentId]
  );

  const color = segmentSelected ? selected : unSelected;

  const segmentMediasInfo =
    useSelector(
      (state) => state.planningRoadSegments.roadSegmentMediaInfo[segmentId]
    ) || [];

  const marker = {
    id: eachSegment.id,
    markerText: [
      `Impressions: ${toLocaleString(estImpressions)}`,
      `Title: ${eachSegment.name}`,
    ],
  };

  return (
    <>
      <Polyline pathOptions={color} positions={segmentPoints}>
        <Popup>
          {marker.markerText.map((string, i) => (
            <b className="d-block" key={i}>
              {string}
            </b>
          ))}
        </Popup>
      </Polyline>
      {segmentMediasInfo.map((media) => (
        <MediaMarkers key={media.mediaId} media={media} />
      ))}
    </>
  );
}

// for added-poi-markers
function PoiMarker({ eachPoi, i }) {
  const exactLatLong = Object.values(eachPoi.trace);
  const poiSelected = useSelector(
    (state) => state.planningPoi.selectedPoi[exactLatLong]
  );
  const marker = {
    markerText: [
      `Latitude: ${exactLatLong[0]}`,
      `Longitude: ${exactLatLong[1]}`,
    ],
  };
  const selectedType = new MapIcon({ type: "selected-poi" });
  const unSelectedType = new MapIcon({ type: "un-selected-poi" });
  const icon = poiSelected ? selectedType : unSelectedType;

  return (
    <Marker key={i} position={exactLatLong} icon={icon}>
      <Popup>
        {marker.markerText.map((string, i) => (
          <b className="d-block" key={i}>
            {string}
          </b>
        ))}
      </Popup>
    </Marker>
  );
}

function SelectedPoiMarker({ poiInfo }) {
  const { center, name, type } = poiInfo;
  const coordinates = [center.latitude, center.longitude];
  // map-point-marker
  const icon = new MapIcon({ type });
  return (
    <Marker position={coordinates} icon={icon}>
      <Popup>
        <b className="d-block">{name}</b>
      </Popup>
    </Marker>
  );
}

/**
 * Planning MapView
 */
function PlanningMapView({ center, roadStretchData, segmentData, poiData }) {
  const regionsDataLoading = useSelector(
    (state) => state.campaignPlanning.regionLoading
  );

  //selected poi to show in map view
  const activePOI = useSelector((state) => state.c_poiNames.activePOI);

  // all poiTypeLayer points combined array ==> TG HeatMap Data
  const allPoiPointsArr = useSelector((state) => state.geoData.allPoiPointsArr);
  const selectedTgId =
    useSelector((state) => state.orgTargetGroup.tgInfo?.targetGroup?.id) || "";

  // Campaign Plan Duration
  const duration = useSelector(
    (state) => state.campaignPlanning.durationInDays
  );

  if (regionsDataLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner className="mt-2" />
      </div>
    );
  }

  if (!center) {
    return null;
  }
  return (
    <LLMap center={[center.latitude, center.longitude]} zoom={11}>
      {roadStretchData.map((eachStretch) => (
        <>
          <StretchLine
            key={eachStretch.id}
            eachStretch={eachStretch}
            duration={duration}
          />
          <TrafficFlowArrow trace={eachStretch.trace} />
        </>
      ))}
      {Object.values(segmentData).map((eachSegment) => (
        <SegmentLine key={eachSegment.id} eachSegment={eachSegment} />
      ))}
      {Object.values(poiData).map((eachPoi, i) => (
        <PoiMarker key={i} eachPoi={eachPoi} />
      ))}
      {activePOI.map((poiInfo) => (
        <SelectedPoiMarker key={poiInfo.id} poiInfo={poiInfo} />
      ))}
      {allPoiPointsArr.length > 0 && (
        <TargetGroupHeatmap
          hmkey={selectedTgId}
          allPoiPointsArr={allPoiPointsArr}
        />
      )}
      <PoiMarkers />
    </LLMap>
  );
}

export default PlanningMapView;
