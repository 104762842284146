import { useSelector } from "react-redux";

// Constants and Utils
import { CPM, FIXED_PRICE } from "../../../constants/PriceMode";
import { calculatePriceFactor } from "../../utils/PriceFactorUtil";
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";
import { toStringWithRoundUp } from "../../../common-utils/number-utils/NumberUtils";

// Components
import { MediaMetaData } from "../../../components/campaign-media-table-row/MediaRow";
import BootstrapDateRangePicker from "../../../components/bootstrap-date-range-picker/BootstrapDateRangePicker";

// Section Component Cells
export function MediaTag({ media, tag }) {
  const mediaId = media.mediaId;
  const isMediaSelected = useSelector(
    (state) => state.mediaSelection.selectedMedia[mediaId]
  );

  return (
    <div
      className={`media-tag mr-2 text-center ${
        isMediaSelected ? "bg-success" : "bg-danger"
      }`}
    >
      <b className="text-white">{tag}</b>
    </div>
  );
}

export function MediaImage({ media }) {
  let imageUrl;
  if (media.fileItems && media.fileItems.length) {
    imageUrl = media.fileItems[0].thumbnailUrl;
  }

  if (!imageUrl) {
    return (
      <i className="far fa-images fa-4x media__default-image text-primary"></i>
    );
  }

  return <img className="w-100" src={imageUrl} alt="thumbnail-image" />;
}

export function MediaInfo({ media }) {
  return (
    <div className="d-flex align-items-center">
      <div className="pl-2 pr-0 my-3 col">
        <h6 className="text-primary text-truncate-2" title={media.title}>
          {media.title}
        </h6>
        <MediaMetaData media={media} />
      </div>
    </div>
  );
}

export function AvailableDateAndOtsCell({
  duration,
  setDuration,
  isMediaSelected,
  initialPriceDetails,
}) {
  //to get previous date
  // TODO: Revist this logic
  // const previousDate = new Date(campaign.startDate);
  // previousDate.setDate(previousDate.getDate() - 1);

  // DateRangePicker Style
  const styleObject = {
    border: false,
    isPickerDisabled: isMediaSelected,
    calenderIcon: false,
  };

  function onApplyDates(selectedDate) {
    // .toDate() will retrieve the dateObject from "moment"
    const startDate = selectedDate.startDate.toDate();
    const endDate = selectedDate.endDate.toDate();
    setDuration({ startDate, endDate });
  }

  return (
    <div className="my-3">
      <b className="mb-0">Available Dates</b>
      <div>
        <BootstrapDateRangePicker
          initialStartDate={duration.startDate}
          initialEndDate={duration.endDate}
          onApplyDates={onApplyDates}
          minDate={new Date()}
          styleObject={styleObject}
        />

        {/* Impressions */}
        <span className="d-block">
          Imp: <b> {initialPriceDetails.totalOts}</b>
        </span>
      </div>
    </div>
  );
}

export function PricingModeCell({
  media,
  campaignMedia,
  isMediaSelected,
  priceMode,
  setPriceMode,
}) {
  let checked = priceMode;
  if (isMediaSelected) {
    checked = campaignMedia.priceMode;
  }

  if (media.isCpmEnabled) {
    return (
      <div>
        <p className="mb-0 pr-2">Cpm Pricing is Enabled</p>
      </div>
    );
  }
  return (
    <div className="my-3 col-3 px-0">
      <b className="mb-1">Pricing</b>
      <div>
        <input
          type="radio"
          className="mr-2"
          id={`cpm-${media.mediaId}`}
          name={media.mediaId}
          value={CPM}
          checked={checked === CPM}
          onClick={(e) => setPriceMode(e.target.value)}
          disabled={isMediaSelected}
        />
        <label htmlFor={`cpm-${media.mediaId}`} className="mb-0">
          CPM
        </label>
      </div>
      <div>
        <input
          type="radio"
          id={`fixed-${media.mediaId}`}
          name={media.mediaId}
          className="mr-2"
          value={FIXED_PRICE}
          checked={checked === FIXED_PRICE}
          onClick={(e) => setPriceMode(e.target.value)}
          disabled={isMediaSelected}
        />
        <label htmlFor={`fixed-${media.mediaId}`} className="mb-0">
          FIXED
        </label>
      </div>
    </div>
  );
}

export function BuyerPriceCell({
  buyerPrice,
  setBuyerPrice,
  campaignMedia,
  mop,
}) {
  const buyerPriceFromCampaignMedia =
    campaignMedia && campaignMedia.pricing.price
      ? toLocaleString(campaignMedia.pricing.price)
      : "";

  // Is CampaignMediaExists..
  const isMediaSelected = campaignMedia;
  return (
    <div className="my-4">
      <p className="mb-0">
        {"MOP: "}
        <b>{`Rs:${toLocaleString(mop)}`}</b>
      </p>
      <div className="input-group-sm col-8 px-0">
        <label className="mb-0">Buyer Price</label>
        <input
          type="text"
          onChange={(e) => setBuyerPrice(e.target.value)}
          value={isMediaSelected ? buyerPriceFromCampaignMedia : buyerPrice}
          className="form-control shadow-none"
          disabled={isMediaSelected}
        />
      </div>
    </div>
  );
}

function SuggestedPrice({ media, suggestedPrice }) {
  const priceFactor = calculatePriceFactor(media.ltsSummary).toFixed(2);
  let priceFactorIndicator = "";
  if (priceFactor > 1) {
    priceFactorIndicator = "fa fa-arrow-up text-success pl-2";
  } else if (priceFactor < 1) {
    priceFactorIndicator = "fa fa-arrow-down text-danger pl-2";
  }

  if (!suggestedPrice) {
    return <span>&nbsp;</span>;
  }

  return (
    <p className="mb-0">
      Sugg: <b>Rs. {toStringWithRoundUp(suggestedPrice)}</b>
      <span>
        <i className={priceFactorIndicator} title={priceFactor}></i>
      </span>
    </p>
  );
}

function SellerPrice({ sellerPrice, setSellerPrice, campaignMedia }) {
  // Is CampaignMedia Exists..
  const isMediaSelected = campaignMedia;

  const sellerPriceFromMedia =
    campaignMedia && campaignMedia.sellerPrice
      ? toLocaleString(campaignMedia.sellerPrice)
      : "";
  return (
    <div className="input-group-sm col-7 px-0">
      <label className="mb-0">Seller Earning</label>
      <input
        type="text"
        onChange={(e) => setSellerPrice(e.target.value)}
        value={isMediaSelected ? sellerPriceFromMedia : sellerPrice}
        className="form-control shadow-none"
        disabled={isMediaSelected}
      />
    </div>
  );
}

export function MarginValueCell({ sellerPrice, buyerPrice, campaignMedia }) {
  let marginValue = "";
  if (campaignMedia) {
    const buyerPriceFromCampaignMedia = campaignMedia.pricing.price;
    const sellerPriceFromCampaignMedia = campaignMedia.sellerPrice;
    marginValue = buyerPriceFromCampaignMedia - sellerPriceFromCampaignMedia;
  }
  if (sellerPrice && buyerPrice) {
    marginValue = new Number(buyerPrice) - new Number(sellerPrice);
  }
  return (
    <p className="my-4">
      Margin: <b>Rs. {marginValue ? toLocaleString(marginValue) : "NA"}</b>
    </p>
  );
}

export function SellerEarningCell({
  sellerPrice,
  setSellerPrice,
  campaignMedia,
  media,
  suggestedPrice,
}) {
  return (
    <div className="my-4">
      {/* Suggested Price Details*/}
      <SuggestedPrice media={media} suggestedPrice={suggestedPrice} />

      {/* Seller Earning :: Payment to Seller */}
      <SellerPrice
        sellerPrice={sellerPrice}
        setSellerPrice={setSellerPrice}
        campaignMedia={campaignMedia}
      />
    </div>
  );
}

export function AdditionalButtonState({
  isMediaSelected,
  selectMedia,
  unSelectMedia,
  selectUnSelectMediaLoading,
}) {
  if (isMediaSelected) {
    return (
      <button
        type="button"
        className="btn added-button shadow-none btn-outline-success my-4"
        onClick={unSelectMedia}
      >
        <i className="fa fa-check"></i> <b>Added</b>
      </button>
    );
  }

  return (
    <button
      type="button"
      className="btn add-button shadow-none btn-outline-primary my-4"
      onClick={selectMedia}
      disabled={selectUnSelectMediaLoading}
    >
      <b>Add</b>
    </button>
  );
}
