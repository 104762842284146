import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// Actions
import { resetTgGroup } from "../../../actions/org/OrgTargetGroupActions";
import {
  getCampaignPlan,
  resetCampaignPlanDetails,
} from "../../../actions/campaign-plan/CampaignPlanActions";
import {
  getBasicCampaignDetails,
  restCampaignDetails,
} from "../../../actions/campaign/CampaignBasicInfoActions";
import { resetRoadStretches } from "../../../actions/org/OrgRoadStretchActions";
import { resetSellerMediaByStretchIds } from "../../../actions/seller/SellerMediaActions";
import { resetMediaSelection } from "../../actions/media-selection/MediaSelectionActions";

// Utils and constants
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";

// Components
import CityViewCards from "../components/CityViewCards";
import { EstimatedPriceBreakDown } from "../components/EstimatedPriceBreakDown";

// Page Sections
import { HeaderSection } from "./HeaderSection";
import RoadAndMediaSection from "./RoadAndMediaSection";
import CreateMediaForm from "./CreateMediaForm";

/**
 * Page
 */
function MediaSelectionPage() {
  const dispatch = useDispatch();
  const { campaignId, cityId } = useParams();

  // Get campaignPlan
  const cityCampaignPlan =
    useSelector((state) => state.campaignPlan.campaignPlan) || {};

  const { targetGroupId } = cityCampaignPlan;
  // tg-name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  //open media modal
  const openCreateMediaModal = useSelector(
    (state) => state.createMediaModal.openModal
  );

  // CampaignMedia
  const campaignMedia = useSelector((state) =>
    Object.values(state.mediaSelection.mediaIdToCampaignMedia)
  );

  useEffect(() => {
    // Reset Actions
    dispatch(resetRoadStretches());
    dispatch(resetSellerMediaByStretchIds());
    dispatch(resetRoadStretches());
    dispatch(resetCampaignPlanDetails());
    dispatch(restCampaignDetails());
    dispatch(resetTgGroup());
    dispatch(resetMediaSelection());

    dispatch(getCampaignPlan(campaignId, cityId));
    dispatch(getBasicCampaignDetails(campaignId));
  }, [dispatch, campaignId, cityId]);

  useGetTgInfo(targetGroupId);

  return (
    <div className="content-wrapper media-selection">
      {/* Header Section */}
      <HeaderSection campaign={campaign} cityId={cityId} />

      <div className="page-content">
        {/* city view Cards */}
        <CityViewCards
          tgName={tgName}
          campaign={campaign}
          cityPlan={cityCampaignPlan}
          campaignMedia={campaignMedia}
        />

        {/* Estimated Price Breakdown Table */}
        <EstimatedPriceBreakDown
          tgName={tgName}
          campaign={campaign}
          campaignPlan={cityCampaignPlan}
          submissionImpact={true}
        />

        {/* Stretch And Media Selection Table */}
        <RoadAndMediaSection
          campaignId={campaignId}
          campaignPlan={cityCampaignPlan}
        />
      </div>

      {/* MODAL */}
      {openCreateMediaModal && (
        <CreateMediaForm campaignId={campaignId} cityId={cityId} />
      )}
    </div>
  );
}

export default MediaSelectionPage;
