import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// Actions
import { getCampaignPlan } from "../../../actions/campaign-plan/CampaignPlanActions";
import { getRoadStretchesOfOrg } from "../../../actions/org/OrgRoadStretchActions";
import { getBasicCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";

// Utils
import { constructRedirectPath } from "../../../utils/redirect-utils/RediectUtil";
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";

// Components
import MediaDetailsTable from "../components/MediaDetailsTable";
import MapView from "../components/map-view/MapView";
import CityViewCards from "../components/CityViewCards";
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import { EstimatedPriceBreakDown } from "../components/EstimatedPriceBreakDown";
import { CampaignDetails } from "../components/HeaderNav";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

/**
 * Page
 */
function CampaignSchedulePage() {
  const { campaignId, cityId } = useParams();

  // Get campaignPlan
  const campaignPlan = useSelector((state) => state.campaignPlan.campaignPlan);
  const { targetGroupId } = campaignPlan;

  // tg-name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  // Campaign Media
  const campaignMedia = useSelector(
    (state) => state.campaignMedia.campaignMedia
  );

  // City road stretches
  const roadStretchMap = useSelector(
    (state) => state.orgRoadStretch.orgRoadStretches
  );

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCampaignPlan(campaignId, cityId));
    dispatch(getBasicCampaignDetails(campaignId));
    dispatch(getRoadStretchesOfOrg(cityId, "", 1, 1000));
  }, [dispatch, campaignId, cityId]);

  useGetTgInfo(targetGroupId);

  const redirectLinkObj = {
    campaignOverviewLink: constructRedirectPath(
      `/campaign/${campaignId}/schedule`
    ),
    citySummaryLink: constructRedirectPath(
      `/campaign/${campaignId}/city/:cityId/schedule`
    ),
  };

  const citySelected = { cityId };

  const pageTitle = <CampaignDetails campaign={campaign} />;

  return (
    <>
      {/* SideBar */}
      <CampaignSidebar
        campaign={campaign}
        citySelected={citySelected}
        redirectLinkObj={redirectLinkObj}
      />

      <div className="content-wrapper">
        {/* Page Header */}
        <PageHeader title={pageTitle} shadow={true} />

        <div className="page-content">
          {/* city view Cards */}
          <CityViewCards
            tgName={tgName}
            campaign={campaign}
            cityPlan={campaignPlan}
            campaignMedia={campaignMedia}
          />

          {/* Estimated Price Breakdown Table */}
          <EstimatedPriceBreakDown
            tgName={tgName}
            campaign={campaign}
            campaignPlan={campaignPlan}
            submissionImpact={false}
          />

          {/* Map view */}
          <MapView
            campaignPlan={campaignPlan}
            roadStretchMap={roadStretchMap}
          />

          {/* Media Sites Table */}
          <MediaDetailsTable
            campaignId={campaignId}
            campaign={campaign}
            campaignPlan={campaignPlan}
            roadStretchMap={roadStretchMap}
          />
        </div>
      </div>
    </>
  );
}

export default CampaignSchedulePage;
